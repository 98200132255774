import React, { useState, useEffect } from 'react';
import { InputText, DropdownList } from '../../../../components';
import { Colors } from '../../../../utils';
import { connect } from 'react-redux';
import { getAnoBailleurs, saveGenerateurEtat } from '../../actions';
import { openNotificationModal, closeNotificationModal } from '../../../../components/actions';

const GenerateurDetat =(props)=> {

    useEffect(() => {
        props.dispatch(getAnoBailleurs());
        return ()=> props.dispatch(closeNotificationModal()) 
    }, []);

    const [intituleprojet, setIntituleprojet] = useState('');
    const [sourcefinancement, setSourcefinancement] = useState('');
    const [imputationbudgetaire, setImputationbudgetaire] = useState('');
    const [saisinecipm, setSaisinecipm] = useState('');
    const [propositionattributioncipm, setPropositionattributioncipm] = useState('');
    const [anobailleur, setAnobailleur] = useState('');
    const [titulaire, setTitulaire] = useState('');
    const [montantttc, setMontantttc] = useState('');
    const [signaturemarche, setsignaturemarche] = useState('');
    const [osdemarrage, setOsdemarrage] = useState('');
    const [nombreavenantconclus, setNombreavenantconclus] = useState('');
    const [pourcentageavenantrapport, setPourcentageavenantrapport] = useState('');
    const [niveauexecution, setNiveauexecution] = useState('');
    const [pvreception, setPvreception] = useState('');
    const [etatavancementdossier, setEtatavancementdossier] = useState('');
            

    const handleInputChange = e => {
        switch (e.target.id) {
            case 'intituleprojet':
                setIntituleprojet(e.target.value)
                break;
            case 'sourcefinancement':
                setSourcefinancement(e.target.value)
                break;
            case 'imputationbudgetaire':
                setImputationbudgetaire(e.target.value)
                break;
            case 'saisinecipm':
                setSaisinecipm(e.target.value)
                break;
            case 'propositionattributioncipm':
                setPropositionattributioncipm(e.target.value)
                break;
            case 'anobailleur':
                setAnobailleur(e.target.value)
                break;
            case 'titulaire':
                setTitulaire(e.target.value)
                break;
            case 'montantttc':
                setMontantttc(e.target.value)
                break;
            case 'signaturemarche':
                setsignaturemarche(e.target.value)
                break;
            case 'osdemarrage':
                setOsdemarrage(e.target.value)
                break;
            case 'nombreavenantconclus':
                setNombreavenantconclus(e.target.value)
                break;
            case 'pourcentageavenantrapport':
                setPourcentageavenantrapport(e.target.value)
                break;
            case 'niveauexecution':
                setNiveauexecution(e.target.value)
                break;
            case 'pvreception':
                setPvreception(e.target.value)
                break;
            case 'etatavancementdossier':
                setEtatavancementdossier(e.target.value)
                break;

            default:
                break;
        }

    }

    const handleSubmit = e => {

        e.preventDefault();
        if (intituleprojet !== '' && sourcefinancement !== '' &&
        imputationbudgetaire !== '' && saisinecipm !== '' &&
        propositionattributioncipm !== '' && anobailleur !== '' &&
        titulaire !== '' && montantttc !== '' &&
        signaturemarche !== '' && osdemarrage !== '' &&
        nombreavenantconclus !== '' && pourcentageavenantrapport !== '' &&
        niveauexecution !== '' && pvreception !== '' &&
        etatavancementdossier !== '') {

            let generateurEtat = {
                intituleprojet, sourcefinancement, imputationbudgetaire,
                saisinecipm, propositionattributioncipm,
                anobailleur, titulaire, montantttc,
                signaturemarche, osdemarrage, nombreavenantconclus,
                pourcentageavenantrapport, niveauexecution, pvreception,
                etatavancementdossier, exercice:props.componentState.exerciceYear
            }
        
             props.dispatch(saveGenerateurEtat(generateurEtat))
        } else {
            props.dispatch(openNotificationModal('Veuillez remplir tout les champs !', 'exclamation-triangle',Colors.firebrick))           
        }

    }
    
        return (

            <div class="row mt">
            <div class="col-lg-12">

                <div class="content-panel" style={{backgroundColor:'#f2f2f2'}}>
                    <div class="panel-body">
                        <form onSubmit={handleSubmit}>
                            <div class="form-row">
                                <InputText title={'Intitulé du projet'} id="intituleprojet" onChange={handleInputChange} value={intituleprojet} />
                                <InputText title={'Source de financement'} id="sourcefinancement" onChange={handleInputChange} value={sourcefinancement} />                                        
                            </div>

                            <div class="form-row">
                                <InputText title={'Imputation budgétaire'} id="imputationbudgetaire" onChange={handleInputChange} value={imputationbudgetaire} />
                                <InputText title={'Saisine de la CIPM'} id="saisinecipm" onChange={handleInputChange} value={saisinecipm} />                                        
                            </div>

                            <div class="form-row">
                                <InputText title={"Proposition d'attribution CIPM"} id="propositionattributioncipm" onChange={handleInputChange} value={propositionattributioncipm} />
                                <DropdownList title={'ANO Bailleurs'} id="anobailleur" data={props.passationState.anobailleurs} onChange={handleInputChange} value={anobailleur} />
                            </div>

                            <div class="form-row">                                
                                <InputText title={'Titulaire'} id="titulaire" onChange={handleInputChange} value={titulaire} />
                                <InputText title={'Montant TTC en FCFA'} id="montantttc" type={'number'} onChange={handleInputChange} value={montantttc} />
                            </div>

                            <div class="form-row">                                
                                <InputText title={'Signature du marché'} id="signaturemarche" onChange={handleInputChange} value={signaturemarche} />                                
                            </div>

                            <div class="form-row">                                
                                <InputText title={'OS de démarrage'} id="osdemarrage" onChange={handleInputChange} value={osdemarrage} />
                                <InputText title={"Nombre d'avenants conclus"} id="nombreavenantconclus" onChange={handleInputChange} value={nombreavenantconclus} />
                            </div>

                            <div class="form-row">                                
                                <InputText title={"Pourcentage de l'avenant par rapport au marché de base"} id="pourcentageavenantrapport" onChange={handleInputChange} value={pourcentageavenantrapport} />
                                <InputText title={"Niveau d'exécution (%)"} id="niveauexecution" onChange={handleInputChange} value={niveauexecution} />
                            </div>

                            <div class="form-row">                                
                                <InputText title={"PV de réception"} id="pvreception" onChange={handleInputChange} value={pvreception} />
                                <InputText title={"Etat d'avancement du dossier"} id="etatavancementdossier" onChange={handleInputChange} value={etatavancementdossier} />
                            </div>
                                                                                  
                            <button type="submit" class="btn form-control" style={{ backgroundColor: Colors.primary, color: Colors.white }}>Enregistrer</button>
                        </form>
                    </div>
                </div>

            </div>
        </div>

        );
    
}

const mapDispatchToProps = (dispatch) => {
    return {
      dispatch: (action) => { dispatch(action) }
    }
  }

const mapStateToProps = state => ({
    passationState: state.passationState,    
    componentState: state.componentState
  });

export default connect(mapStateToProps,mapDispatchToProps)(GenerateurDetat)