import React from 'react';
import { NavLink } from 'react-router-dom';
import { Colors } from '../utils';

const TopButton = ({ route, title = 'Title', icon = 'arrow-left' }) => {

    return (
        <NavLink className="btn"
            style={{ backgroundColor: Colors.darkslategray, color: Colors.white }}
            to={`${route}`}><i class={`fa fa-${icon}`}></i> {title}</NavLink>
    );

}

export default TopButton;