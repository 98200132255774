import React, { useEffect } from 'react';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import { GroupedButton, TableHead, EmptyList, SearchBar, GroupedButtonForm, Loader } from '../../../../components';
import { closePreviewModal } from '../../../../components/actions';
import { AvenantModal, AddAvenantModal, RecoursModal, AddRecoursModal } from '../../modals';
import { Colors } from '../../../../utils';

const SuiviProcessusPassation = (props) => {

    useEffect(() => {
        props.dispatch(actions.getSuiviProcessusPassation(props.componentState.exerciceYear));
        return () => {
            props.dispatch(closePreviewModal())
            props.dispatch(actions.closeAvenantModal())
            props.dispatch(actions.closeRecoursModal())
        }
    }, []);


    return (

        <div>

            {props.componentState.loader ? <Loader /> :

                <div class="adv-table" style={{ paddingTop: 15 }}>

                    {props.passationState.suiviProcessusPassation.length !== 0 ?

                        <div>

                            <SearchBar />

                            <div style={{ overflowX: 'auto' }}>

                                <table cellpadding="0" cellspacing="0" border="0" class="display table table-striped table-hover" id="hidden-table-info">

                                    <TableHead data={['Nº', 'Intitulé du projet', 'Source de financement', 'Imputation budgétaire',
                                        'Saisine prévisionnelle (CIPM)', 'Saisine (CIPM)', 'Examen du dossier', 'Validation du dossier',
                                        'ANO Bailleur', 'Publication JDM', 'Additif', 'Ouvertures des offres (A & T)', "Validation du rapport d'évaluation",
                                        'ANO Bailleurs', 'Ouvertures des offres financières', 'Infructueux', "Proposition d'attribution CIPM",
                                        'ANO Bailleurs', "Publication décision et communiqué d'attribution", 'Notification attribution',
                                        'Titulaire', 'Montant TTC en FCFA', 'Souscription du projet de marché', 'Saisine CIPM pour examen projet de marché',
                                        'Examen projet de marché', 'Validation', 'ANO Bailleurs', 'Signature du marché', 'Annulé', 'Avenant',
                                        'Recours', '', '']} />

                                    <tbody>
                                        {props.passationState.suiviProcessusPassation.map((e, index) => (
                                            <tr key={e.id}>
                                                <td>{index + 1}</td>
                                                <td><GroupedButton pdf={e.intituleprojet} slice={true} name={e.intituleprojettitle} /></td>
                                                <td>{e.sourcefinancement}</td>
                                                <td><GroupedButton pdf={e.imputationbudgetaire} slice={true} name={e.imputationbudgetairetitle} /></td>
                                                <td>{e.saisineprevisionellecipm}</td>
                                                <td><GroupedButton pdf={e.saisinecipm} name={e.saisinecipmtitle} /></td>
                                                <td>{e.examendossier}</td>
                                                <td><GroupedButton pdf={e.validationdossier} name={e.validationdossiertitle} /></td>
                                                <td><GroupedButton pdf={e.anobailleur1} slice={true} name={e.anobailleur1title} /></td>
                                                <td><GroupedButton pdf={e.publicationjdm} slice={true} name={e.publicationjdmtitle} /></td>
                                        <td>{e.additif === null ? <p style={{color:Colors.firebrick}}>indisponible</p> : <GroupedButton pdf={e.additif} slice={true} name={e.additiftitle} />}</td>
                                                <td><GroupedButton pdf={e.ouverturesoffresadministratifs} name={e.ouverturesoffresadministratifst} /></td>
                                                <td><GroupedButton pdf={e.validationrapportevaluation} slice={true} name={e.validationrapportevaluationtitl} /></td>
                                                <td><GroupedButton pdf={e.anobailleur2} slice={true} name={e.anobailleur2title} /></td>
                                                <td><GroupedButton pdf={e.ouverturesoffresfinanciere} name={e.ouverturesoffresfinancieretitle} /></td>
                                                <td><GroupedButton pdf={e.infructueux} slice={true} name={e.infructueuxtitle} /></td>
                                                <td><GroupedButton pdf={e.propositionsattributionscipm} name={e.propositionsattributionscipmtit} /></td>
                                                <td><GroupedButton pdf={e.anobailleur3} slice={true} name={e.anobailleur3title} /></td>
                                                <td><GroupedButton pdf={e.publicationdecisioncommuniquer} slice={true} name={e.publicationdecisioncommuniquert} /></td>
                                                <td><GroupedButton pdf={e.notificationattribution} slice={true} name={e.notificationattributiontitle} /></td>
                                                <td>{e.titulaire}</td>
                                                <td>{e.montantttccfa}</td>
                                                <td>{e.souscriptionprojetmarche}</td>
                                                <td><GroupedButton pdf={e.saisinecipmexamenprojet} name={e.saisinecipmexamenprojettitle} /></td>
                                                <td>{e.examenprojetmarche}</td>
                                                <td><GroupedButton pdf={e.validation} name={e.validationtitle} /></td>
                                                <td><GroupedButton pdf={e.anobailleur4} slice={true} name={e.anobailleur4title} /></td>
                                                <td>{e.signaturemarche}</td>
                                                <td><button className="btn btn-primary btn-sm" >Oui</button> </td>
                                                <td><GroupedButtonForm showList={actions.getAvenant(e.id)} new={actions.openAddAvenantModal(e.id)} /></td>
                                                <td><GroupedButtonForm showList={actions.getRecours(e.id)} new={actions.openAddRecoursModal(e.id)} /></td>
                                                <td><button className="btn btn-primary btn-sm" ><i class="fa fa-edit" ></i></button></td>
                                                <td><button className="btn btn-danger btn-sm" ><i class="fa fa-trash" ></i></button></td>

                                            </tr>
                                        ))}

                                    </tbody>

                                </table>
                                <AddAvenantModal />
                                <AvenantModal />
                                <AddRecoursModal />
                                <RecoursModal />
                            </div>
                        </div>
                        : <EmptyList />}
                </div>
            }
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    passationState: state.passationState,
    componentState: state.componentState
});

export default connect(mapStateToProps, mapDispatchToProps)(SuiviProcessusPassation)