import axios from 'axios';
import SERVER from '../../../utils/Server';

export const getPpm = (category,exercice) => {

  return axios.get(SERVER.ADDRESS + 'exercice/ppm/',{
    params: {
      category,
      exercice
    }
  }).then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}

export const savePpm = (ppm) => {

  return axios.post(SERVER.ADDRESS + 'exercice/ppm/register', ppm).then((response) => {
    return response
  }).catch((response) => {
    return response
  });
}

export const getConsultationLancees = (exercice) => {

  return axios.get(SERVER.ADDRESS + 'exercice/consultationLancees/'+exercice).then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}

export const saveConsultationLancee = (consultationLancee) => {

  return axios.post(SERVER.ADDRESS + 'exercice/consultationLancees/register', consultationLancee).then((response) => {
    return response
  }).catch((response) => {
    return response
  });
}

export const getSuiviProcessusPassation = (exercice) => {

  return axios.get(SERVER.ADDRESS + 'exercice/suiviprocessuspassation/'+exercice).then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}

export const saveSuiviProcessusPassation = (suiviProcessusPassation) => {

  return axios.post(SERVER.ADDRESS + 'exercice/suiviprocessuspassation/register', suiviProcessusPassation).then((response) => {
    return response
  }).catch((response) => {
    return response
  });
}

export const getAnoBailleurs = () => {

  return axios.get(SERVER.ADDRESS + 'exercice/anobailleurs').then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}

export const saveGenerateurEtat = (generateurEtat) => {

  return axios.post(SERVER.ADDRESS + 'exercice/generateuretat/register', generateurEtat).then((response) => {
    return response
  }).catch((response) => {
    return response
  });
}

export const getGenerateurEtat = (exercice) => {

  return axios.get(SERVER.ADDRESS + 'exercice/generateuretat/'+exercice).then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}

export const saveNationale = (nationale) => {

  return axios.post(SERVER.ADDRESS + 'exercice/nationales/register', nationale).then((response) => {
    return response
  }).catch((response) => {
    return response
  });
}

export const getNationales = () => {

  return axios.get(SERVER.ADDRESS + 'exercice/nationales').then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}

export const saveProcedureBailleur = (procedureBailleur) => {

  return axios.post(SERVER.ADDRESS + 'exercice/proceduresbailleurs/register', procedureBailleur).then((response) => {
    return response
  }).catch((response) => {
    return response
  });
}

export const getProceduresBailleurs = () => {

  return axios.get(SERVER.ADDRESS + 'exercice/proceduresbailleurs').then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}

export const saveAvenant = (avenant) => {

  return axios.post(SERVER.ADDRESS + 'exercice/avenantsuivipassation/register', avenant).then((response) => {
    return response
  }).catch((response) => {
    return response
  });
}

export const getAvenant = (suivipassationid) => {

  return axios.get(SERVER.ADDRESS + 'exercice/avenantsuivipassation/'+suivipassationid).then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}

export const saveRecours = (recours) => {

  return axios.post(SERVER.ADDRESS + 'exercice/recours/register', recours).then((response) => {
    return response
  }).catch((response) => {
    return response
  });
}

export const getRecours = (suivipassationid) => {

  return axios.get(SERVER.ADDRESS + 'exercice/recours/'+suivipassationid).then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}

export const deletePpm = (id) => {

  return axios.delete(SERVER.ADDRESS + 'exercice/ppm/'+id
  ).then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}

export const deleteConsultationLancee = (id) => {

  return axios.delete(SERVER.ADDRESS + 'exercice/consultationLancees/'+id
  ).then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}

export const deleteSuiviProcessusPassation = (id) => {

  return axios.delete(SERVER.ADDRESS + 'exercice/suiviprocessuspassation/'+id
  ).then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}

export const deleteGenerateurEtat = (id) => {

  return axios.delete(SERVER.ADDRESS + 'exercice/generateuretat/'+id
  ).then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}