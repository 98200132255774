import React from 'react';
import FontAwesome from 'react-fontawesome';

const Loader = ({save}) => {

    return (

        <div style={{ textAlign: 'center', padding: 20 }}>
            <FontAwesome
                name="spinner"
                size="5x"
                spin
            />
            {save ? <h1>Sauvegarde en cours</h1> : <h1>Traitement en cours</h1> }
            <h3>Veuillez patienter</h3>
        </div>

    );
}

export default Loader;