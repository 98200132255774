import React, { useState } from 'react';
import { saveAvenantExecution } from '../actions';
import { connect } from 'react-redux';
import { Colors, otherUtils } from '../../../utils';
import { InputText, PeriodSelector } from '../../../components';
import { openNotificationModal } from '../../../components/actions';


const AddAvenant = (props) => {

    const [referenceavenant, setReferenceavenant] = useState('');
    const [objet, setObjet] = useState('');
    const [titulaire, setTitulaire] = useState('');
    const [montantavenant, setMontantavenant] = useState('');
    const [notificationavenant, setNotificationavenant] = useState([]);
    const [notificationavenantTitle, setNotificationavenanttitle] = useState('');
    const [osexecution, setOsexecution] = useState([]);
    const [osexecutionTitle, setOsexecutiontitle] = useState('');
    const [delaisexecution, setDelaisexecution] = useState('');
    const [delaisexecutiontype, setDelaisexecutiontype] = useState('Jours');
    const [avenantenregistre, setAvenantenregistre] = useState([]);
    const [avenantenregistreTitle, setAvenantenregistretitle] = useState('');
    const [executionid, setExecutionid] = useState(0);

    const handleSelectChange = e => {
        switch (e.target.id) {
            case 'delaisexecutiontype':
                setDelaisexecutiontype(e.target.value)
                break;
        }
    }

    const handleInputChange = e => {

        switch (e.target.id) {
            case 'referenceavenant':
                {
                    setReferenceavenant(e.target.value)
                    setExecutionid(props.executionState.executionid)
                }
                break;

            case 'objet':
                setObjet(e.target.value)
                break;

            case 'titulaire':
                setTitulaire(e.target.value)
                break;

            case 'montantavenant':
                setMontantavenant(e.target.value)
                break;

            case 'delaisexecution':
                setDelaisexecution(e.target.value)
                break;

            default:
                break;
        }

    }

    const getNotificationavenant = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setNotificationavenant(files)
            setNotificationavenanttitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getOsexecution = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setOsexecution(files)
            setOsexecutiontitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getAvenantenregistre = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setAvenantenregistre(files)
            setAvenantenregistretitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }


    const handleSubmit = e => {

        e.preventDefault();
        if (referenceavenant !== '' && objet !== '' &&
            titulaire !== '' && montantavenant !== '' &&
            notificationavenant !== [] && notificationavenantTitle !== '' &&
            osexecution !== [] && osexecutionTitle !== '' &&
            delaisexecution !== '' && avenantenregistre !== [] &&
            avenantenregistreTitle !== '') {

            let avenant = {
                referenceavenant, objet, titulaire, montantavenant,
                notificationavenant: notificationavenant.base64, notificationavenantTitle,
                osexecution: osexecution.base64, osexecutionTitle, delaisexecution, delaisexecutiontype,
                avenantenregistre: avenantenregistre.base64, avenantenregistreTitle, executionid

            }

            props.dispatch(saveAvenantExecution(avenant))

        } else {
            props.dispatch(openNotificationModal('Veuillez remplir tout les champs !', 'exclamation-triangle', Colors.firebrick, false))
        }

    }

    return (
        <form onSubmit={handleSubmit}>
            <div class="form-row">

                <InputText title={"Référence de l'avenant"} id="referenceavenant" onChange={handleInputChange} value={referenceavenant} />
                <InputText title={'Objet'} id="objet" onChange={handleInputChange} value={objet} />
            </div>

            <div class="form-row">
                <InputText title={"Titulaire"} id="titulaire" onChange={handleInputChange} value={titulaire} />
                <InputText title={"Montant de l'avenant"} id="montantavenant" onChange={handleInputChange} value={montantavenant} />
            </div>

            <div class="form-row">
                <InputText title={"Notification de l'avenant"} type={'file'} onDone={getNotificationavenant.bind(this)} />
                <InputText title={"OS d'exécution"} type={'file'} onDone={getOsexecution.bind(this)} />
            </div>

            <div class="form-row">
                <PeriodSelector title={"Délais d'exécution"} id="delaisexecution" selectOnChange={handleSelectChange} onChange={handleInputChange} value={delaisexecution} selectId='delaisexecutiontype' selectValue={delaisexecutiontype} />
                <InputText title={'Avenant enregistré'} type={'file'} onDone={getAvenantenregistre.bind(this)} />
            </div>

            <button type="submit" class="btn form-control" style={{ backgroundColor: Colors.primary, color: Colors.white }}>Enregistrer</button>
        </form>

    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    executionState: state.executionState,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAvenant)