import React, { useEffect } from 'react';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import { TableHead, EmptyList, SearchBar } from '../../../../components';
import { Colors } from '../../../../utils';
import { getExecution } from '../../../execution/actions';


const GenerateurDetat = (props) => {

    const months = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre']

    useEffect(() => {
        // props.dispatch(actions.getGenerateurEtat(props.componentState.exerciceYear));
        props.dispatch(actions.getConsultationLancees(props.componentState.exerciceYear));
        props.dispatch(actions.getSuiviProcessusPassation(props.componentState.exerciceYear));
        props.dispatch(getExecution());
    }, []);
    console.log(props.passationState.consultationLancees)
    return (

        <div class="adv-table" style={{ paddingTop: 15,  }}>

            <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', marginBottom: 15 }}>
                <h4>Veuillez choisir le mois de reference</h4>
                <select className='btn' style={{ width: 200, backgroundColor: Colors.primary, color: Colors.white }}>
                    {months.map(e => {
                        return <option>{e}</option>
                    })}

                </select>
            </div>

            {/* {props.passationState.generateurEtats.length !== 0 ? */}

                <SearchBar />
                
            <div style={{ overflowX: 'auto' }}>

                <table cellpadding="0" cellspacing="0" border="0" class="display table table-striped table-hover" id="hidden-table-info">
                    <thead >
                        <tr >
                            <td style={{ padding: 0 }}>
                                <TableHead data={['Intitulé du projet', 'Source de financement', 'Imputation budgetaire', 'ANO bailleurs'
                                ]} />
                                {props.passationState.consultationLancees.map((e, index) => (
                                    <tr key={e.id}>
                                        <td>{e.intituleprojet}</td>
                                        <td>{e.sourcefinancement}</td>
                                        <td>{e.imputationbudgetaire}</td>
                                        <td>{e.anobailleur}</td>
                                    </tr>
                                ))}

                            </td>

                            <td style={{ padding: 0 }}>
                                <TableHead data={['Saisine (cipm)', "Proposition d'attribution (CIPM)", 'Titulaire', 'Montant TTC (FCFA)', 'Signature du marché'
                                ]} />
                                {props.passationState.suiviProcessusPassation.map((e, index) => (
                                    <tr key={e.id}>
                                        <td>{e.saisinecipmtitle}</td>
                                        <td>{String(e.propositionsattributionscipmtit).slice(0, -4)}</td>
                                        <td>{e.titulaire}</td>
                                        <td>{e.montantttccfa}</td>
                                        <td>{e.signaturemarche}</td>
                                    </tr>
                                ))}
                            </td>

                            <td style={{ padding: 0 }}>
                                <TableHead data={['OS de démarrage', "PV de réception",
                                ]} />
                                {props.executionState.executions.map((e, index) => (
                                    <tr key={e.id}>
                                        <td>{e.osdemarragetitle}</td>
                                        <td>{String(e.pvreceptionprovisoiretitle).slice(0, -4)}</td>
                                    </tr>
                                ))}
                            </td>

                            <td style={{ padding: 0 }}>
                                <TableHead data={["Nombre d'avenants conclus", "Niveau d'exécution",
                                    "% de l'avenant (Marché de base)", "Etat d'avancement du dossier"]} />

                            </td>
                        </tr>
                    </thead>
                </table>


            </div>
            {/* : <EmptyList />} */}
        </div>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    passationState: state.passationState,
    executionState: state.executionState,
    componentState: state.componentState
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateurDetat)