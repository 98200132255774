module.exports = {
    SAVE_PPM: 'SAVE_PPM',
    GET_PPM: 'GET_PPM',
    GET_PPM_SUCCESS: 'GET_PPM_SUCCESS',
    SAVE_CONSULTATION_LANCEE: 'SAVE_CONSULTATION_LANCEE',
    GET_CONSULTATION_LANCEES: 'GET_CONSULTATION_LANCEES',
    GET_CONSULTATION_LANCEES_SUCCESS: 'GET_CONSULTATION_LANCEES_SUCCESS',
    SAVE_SUIVI_PROCESSUS_PASSATION: 'SAVE_SUIVI_PROCESSUS_PASSATION',
    GET_SUIVI_PROCESSUS_PASSATION: 'GET_SUIVI_PROCESSUS_PASSATION',
    GET_SUIVI_PROCESSUS_PASSATION_SUCCESS: 'GET_SUIVI_PROCESSUS_PASSATION_SUCCESS',
    GET_ANO_BAILLEURS: 'GET_ANO_BAILLEURS',
    GET_ANO_BAILLEURS_SUCCESS: 'GET_ANO_BAILLEURS_SUCCESS',
    SAVE_GENERATEUR_ETAT: 'SAVE_GENERATEUR_ETAT',
    GET_GENERATEUR_ETAT: 'GET_GENERATEUR_ETAT',
    GET_GENERATEUR_ETAT_SUCCESS: 'GET_GENERATEUR_ETAT_SUCCESS',
    OPEN_AVENANT_MODAL: 'OPEN_AVENANT_MODAL',
    CLOSE_AVENANT_MODAL: 'CLOSE_AVENANT_MODAL',
    OPEN_ADD_AVENANT_MODAL: 'OPEN_ADD_AVENANT_MODAL',
    CLOSE_ADD_AVENANT_MODAL: 'CLOSE_ADD_AVENANT_MODAL',
    SAVE_NATIONALE: 'SAVE_NATIONALE',
    GET_NATIONALES: 'GET_NATIONALES',
    GET_NATIONALES_SUCCESS: 'GET_NATIONALES_SUCCESS',
    SAVE_PROCEDURE_BAILLEUR: 'SAVE_PROCEDURE_BAILLEUR',
    GET_PROCEDURE_BAILLEUR: 'GET_PROCEDURE_BAILLEUR',
    GET_PROCEDURE_BAILLEUR_SUCCESS: 'GET_PROCEDURE_BAILLEUR_SUCCESS',
    DELETE_PPM: 'DELETE_PPM',
    DELETE_CONSULTATION_LANCEE: 'DELETE_CONSULTATION_LANCEE',
    DELETE_SUIVI_PROCESSUS_PASSATION: 'DELETE_SUIVI_PROCESSUS_PASSATION',
    DELETE_GENERATEUR_ETAT: 'DELETE_GENERATEUR_ETAT',
    SAVE_AVENANT: 'SAVE_AVENANT',
    GET_AVENANT: 'GET_AVENANT',
    GET_AVENANT_SUCCESS: 'GET_AVENANT_SUCCESS',
    DELETE_AVENANT: 'DELETE_AVENANT',
    SAVE_RECOURS: 'SAVE_RECOURS',
    GET_RECOURS: 'GET_RECOURS',
    CLOSE_RECOURS_MODAL: 'CLOSE_RECOURS_MODAL',
    GET_RECOURS_SUCCESS: 'GET_RECOURS_SUCCESS',
    DELETE_RECOURS: 'DELETE_RECOURS',
    OPEN_ADD_RECOURS_MODAL: 'OPEN_ADD_RECOURS_MODAL',
    CLOSE_ADD_RECOURS_MODAL: 'CLOSE_ADD_RECOURS_MODAL',
}