import React, { useEffect, useState } from 'react';
import { saveAvenant, openAddAvenantModal, closeAddAvenantModal } from '../../actions';
import { connect } from 'react-redux';
import { Colors, otherUtils } from '../../../../utils';
import { InputText } from '../../../../components';
import { openNotificationModal } from '../../../../components/actions';


const AddAvenant = (props) => {

    useEffect(() => {

    }, []);

    const [designationavenant, setDesignationavenant] = useState('');
    const [objet, setObjet] = useState('');
    const [titulaire, setTitulaire] = useState('');
    const [montant, setMontant] = useState('');
    const [notejustificative, setNotejustificative] = useState([]);
    const [notejustificativeTitle, setNotejustificativetitle] = useState('');
    const [anobailleurs, setAnobailleurs] = useState([]);
    const [anobailleursTitle, setAnobailleurstitle] = useState('');
    const [souscription, setSouscription] = useState('');
    const [saisinecipm, setSaisinecipm] = useState([]);
    const [saisinecipmTitle, setSaisinecipmtitle] = useState('');
    const [examenprojetavenant, setExamenprojetavenant] = useState('');
    const [validation, setValidation] = useState([]);
    const [validationTitle, setValidationtitle] = useState('');
    const [signature, setSignature] = useState('');
    const [suivipassationid, setSuivipassationid] = useState(0);

    const handleInputChange = e => {

        switch (e.target.id) {
            case 'designationavenant':
                setDesignationavenant(e.target.value)
                setSuivipassationid(props.passationState.suivipassationid)
                break;

            case 'objet':
                setObjet(e.target.value)
                break;

            case 'titulaire':
                setTitulaire(e.target.value)
                break;

            case 'montant':
                setMontant(e.target.value)
                break;

            case 'souscription':
                setSouscription(e.target.value)
                break;

            case 'examenprojetavenant':
                setExamenprojetavenant(e.target.value)
                break;

            case 'signature':
                setSignature(e.target.value)
                break;

            default:
                break;
        }

    }

    const getNotejustificative = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setNotejustificative(files)
            setNotejustificativetitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getAnobailleurs = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setAnobailleurs(files)
            setAnobailleurstitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getSaisinecipm = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setSaisinecipm(files)
            setSaisinecipmtitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getValidation = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setValidation(files)
            setValidationtitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const handleSubmit = e => {

        e.preventDefault();
        if (designationavenant !== '' && objet !== '' &&
            titulaire !== '' && montant !== '' &&
            notejustificative !== [] && notejustificativeTitle !== '' &&
            anobailleurs !== [] && anobailleursTitle !== '' &&
            souscription !== '' && saisinecipm !== [] &&
            saisinecipmTitle !== '' && examenprojetavenant !== '' &&
            validation !== [] && validationTitle !== '' &&
            signature !== '') {

            let avenant = {
                designationavenant, objet, titulaire, montant,
                notejustificative: notejustificative.base64, notejustificativeTitle,
                anobailleurs: anobailleurs.base64, anobailleursTitle, souscription,
                saisinecipm: saisinecipm.base64, saisinecipmTitle, examenprojetavenant,
                validation: validation.base64, validationTitle,
                signature, suivipassationid

            }

            props.dispatch(saveAvenant(avenant))

        } else {
            props.dispatch(openNotificationModal('Veuillez remplir tout les champs !', 'exclamation-triangle', Colors.firebrick, false))
        }

    }

    return (
        <form onSubmit={handleSubmit}>

            <div class="form-row">
                <InputText title={"Désignation de l'avenant"} id="designationavenant" onChange={handleInputChange} value={designationavenant} />
                <InputText title={'Objet'} id="objet" onChange={handleInputChange} value={objet} />
            </div>

            <div class="form-row">
                <InputText title={"Titulaire"} id="titulaire" onChange={handleInputChange} value={titulaire} />
                <InputText title={'Montant'} id="montant" type={'number'} onChange={handleInputChange} value={montant} />
            </div>

            <div class="form-row">
                <InputText title={"Note justificatif"} type={'file'} onDone={getNotejustificative.bind(this)} />
                <InputText title={'ANO bailleurs'} type={'file'} onDone={getAnobailleurs.bind(this)} />
            </div>

            <div class="form-row">
                <InputText title={"Souscription"} type={'date'} id="souscription" onChange={handleInputChange} value={souscription} />
                <InputText title={'Saisine CIPM'} type={'file'} onDone={getSaisinecipm.bind(this)} />
            </div>

            <div class="form-row">
                <InputText title={"Examen projet d'avenant"} type={'date'} id="examenprojetavenant" onChange={handleInputChange} value={examenprojetavenant} />
                <InputText title={'Validation'} type={'file'} onDone={getValidation.bind(this)} />
            </div>

            <div class="form-row">
                <InputText title={"Signature"} type={'date'} id="signature" onChange={handleInputChange} value={signature} />
            </div>

            <button type="submit" class="btn form-control" style={{ backgroundColor: Colors.primary, color: Colors.white }}>Enregistrer</button>
        </form>

    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    passationState: state.passationState,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAvenant)