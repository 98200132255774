import constants from '../constants';

const initialState = {
    ppms: [],
    consultationLancees: [],
    suiviProcessusPassation: [],
    anobailleurs: [],
    generateurEtats: [],
    avenantModal: false,
    recoursModal: false,
    addAvenantModal: false,
    addRecoursModal: false,
    nationales: [],
    proceduresBailleurs: [],
    avenants: [],
    suivipassationid: 0,
    recours: []
}

const reducer = (state = initialState, action) => {

    let nextState

    switch (action.type) {

        case constants.GET_PPM_SUCCESS:

            nextState = {
                ...state,
                ppms: action.ppms
            }

            return nextState || state;

        case constants.GET_CONSULTATION_LANCEES_SUCCESS:

            nextState = {
                ...state,
                consultationLancees: action.consultationLancees
            }

            return nextState || state;

        case constants.GET_SUIVI_PROCESSUS_PASSATION_SUCCESS:

            nextState = {
                ...state,
                suiviProcessusPassation: action.suiviProcessusPassation
            }

            return nextState || state;

        case constants.GET_ANO_BAILLEURS_SUCCESS:

            nextState = {
                ...state,
                anobailleurs: action.anobailleurs
            }

            return nextState || state;

        case constants.GET_GENERATEUR_ETAT_SUCCESS:

            nextState = {
                ...state,
                generateurEtats: action.generateurEtats
            }

            return nextState || state;

        case constants.OPEN_AVENANT_MODAL:

            nextState = {
                ...state,
                avenantModal: true,
                addAvenantModal: false
            }

            return nextState || state;

        case constants.CLOSE_AVENANT_MODAL:

            nextState = {
                ...state,
                avenantModal: false
            }

            return nextState || state;

        case constants.OPEN_ADD_AVENANT_MODAL:

            nextState = {
                ...state,
                suivipassationid: action.suivipassationid,
                addAvenantModal: true,
                avenantModal: false,
                addRecoursModal: false,
                recoursModal: false,
            }

            return nextState || state;

        case constants.CLOSE_ADD_AVENANT_MODAL:

            nextState = {
                ...state,
                addAvenantModal: false
            }

            return nextState || state;

        case constants.GET_NATIONALES_SUCCESS:

            nextState = {
                ...state,
                nationales: action.nationales
            }

            return nextState || state;

        case constants.GET_PROCEDURE_BAILLEUR_SUCCESS:

            nextState = {
                ...state,
                proceduresBailleurs: action.proceduresBailleurs
            }

            return nextState || state;

        case constants.GET_AVENANT_SUCCESS:

            nextState = {
                ...state,
                avenants: action.avenants,
                avenantModal: true,
                addAvenantModal: false,
                addRecoursModal: false,
                recoursModal: false,
            }

            return nextState || state;

        case constants.GET_RECOURS_SUCCESS:

            nextState = {
                ...state,
                recours: action.recours,
                recoursModal: true,
                avenantModal: false,
                addAvenantModal: false,
                addRecoursModal: false
            }

            return nextState || state;

        case constants.OPEN_ADD_RECOURS_MODAL:

            nextState = {
                ...state,
                suivipassationid: action.suivipassationid,
                addRecoursModal: true,
                recoursModal: false,
                addAvenantModal: false,
                avenantModal: false
            }

            return nextState || state;

        case constants.CLOSE_ADD_RECOURS_MODAL:

            nextState = {
                ...state,
                addRecoursModal: false
            }

            return nextState || state;

        case constants.CLOSE_RECOURS_MODAL:

            nextState = {
                ...state,
                recoursModal: false
            }

            return nextState || state;

        default:
            return state;

    }
}

export default reducer;