import React,{useState} from 'react';
import { connect } from 'react-redux';
import { Colors } from '../../../utils';
import { saveRecours } from '../actions';
import { Button, FileBase64 } from '../../../components';
import { openPreviewModal, openNotificationModal } from '../../../components/actions';
import AddRecours from '../forms/exercice/AddRecours';


const AddRecoursModal = (props) => {

    
    switch (props.passationState.addRecoursModal) {
        case true:
            document.getElementById('openAddRecoursModalBtn').click()
            break;

        default:
            break;
    }

    return (
        <div>
            <button id="openAddRecoursModalBtn" data-toggle="modal" data-target="#addRecoursModal" hidden></button>

            <div class="modal fade" id="addRecoursModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h2 class="modal-title" id="exampleModalLabel">Enregistrement d'un Recours <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <h5 style={{ color: Colors.white }}>X</h5>
                            </button></h2>

                        </div>

                        <div class="modal-body" style={{textAlign:'center'}}>
                        <AddRecours />
                        </div>

                        <div class="modal-footer">
                            <button type="button" id='closeModalBtn' class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    passationState: state.passationState,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddRecoursModal)