import { takeLatest } from 'redux-saga/effects';
import * as workers from './passationWorker';
import constants from '../constants';

export function* watchGetPpm() {
    yield takeLatest(constants.GET_PPM, workers.getPpmWorker);
}

export function* watchSavePpm() {
    yield takeLatest(constants.SAVE_PPM, workers.savePpmWorker);
}

export function* watchGetConsultationLancees() {
    yield takeLatest(constants.GET_CONSULTATION_LANCEES, workers.getConsultationLanceesWorker);
}

export function* watchSaveConsultationLancee() {
    yield takeLatest(constants.SAVE_CONSULTATION_LANCEE, workers.saveConsultationLanceeWorker);
}

export function* watchGetSuiviProcessusPassation() {
    yield takeLatest(constants.GET_SUIVI_PROCESSUS_PASSATION, workers.getSuiviProcessusPassationWorker);
}

export function* watchSaveSuiviProcessusPassation() {
    yield takeLatest(constants.SAVE_SUIVI_PROCESSUS_PASSATION, workers.saveSuiviProcessusPassationWorker);
}

export function* watchGetAnoBailleurs() {
    yield takeLatest(constants.GET_ANO_BAILLEURS, workers.getAnoBailleursWorker);
}

export function* watchSaveGenerateurEtat() {
    yield takeLatest(constants.SAVE_GENERATEUR_ETAT, workers.saveGenerateurEtatWorker);
}

export function* watchGetGenerateurEtat() {
    yield takeLatest(constants.GET_GENERATEUR_ETAT, workers.getGenerateurEtatWorker);
}

export function* watchSaveNationale() {
    yield takeLatest(constants.SAVE_NATIONALE, workers.saveNationaleWorker);
}

export function* watchGetNationales() {
    yield takeLatest(constants.GET_NATIONALES, workers.getNationaleWorker);
}

export function* watchSaveProcedureBailleur() {
    yield takeLatest(constants.SAVE_PROCEDURE_BAILLEUR, workers.saveProcedureBailleurWorker);
}

export function* watchGetProcedureBailleur() {
    yield takeLatest(constants.GET_PROCEDURE_BAILLEUR, workers.getProcedureBailleurWorker);
}

export function* watchSaveAvenantsuivipassation() {
    yield takeLatest(constants.SAVE_AVENANT, workers.saveAvenantsuivipassationWorker);
}

export function* watchGetAvenantsuivipassation() {
    yield takeLatest(constants.GET_AVENANT, workers.getAvenantsuivipassationWorker);
}

export function* watchSaveRecours() {
    yield takeLatest(constants.SAVE_RECOURS, workers.saveRecoursWorker);
}

export function* watchGetRecours() {
    yield takeLatest(constants.GET_RECOURS, workers.getRecoursWorker);
}

export function* watchDeletePpm() {
    yield takeLatest(constants.DELETE_PPM, workers.deletePpmWorker);
}

export function* watchDeleteConsultationLancee() {
    yield takeLatest(constants.DELETE_CONSULTATION_LANCEE, workers.deleteConsultationLanceeWorker);
}

export function* watchDeleteSuiviProcessusPassation() {
    yield takeLatest(constants.DELETE_SUIVI_PROCESSUS_PASSATION, workers.deleteSuiviProcessusPassationWorker);
}

export function* watchDeleteGenerateurEtat() {
    yield takeLatest(constants.DELETE_GENERATEUR_ETAT, workers.deleteGenerateurEtatWorker);
}