import React from 'react';
import { Colors } from '../utils';

const TableHead = ({ data }) => {

    return (
        <thead style={{ backgroundColor: Colors.primary, color: Colors.white }}>
            <tr>
                {data.map(e => (
                    <th>{e}</th>))}
            </tr>
        </thead>
    );

}

export default TableHead;