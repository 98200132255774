import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import Nationales from './Nationales';
import ProceduresBailleurs from './ProceduresBailleurs';
import { Colors } from '../../../../utils';
import {TopButton} from '../../../../components';


export default class ReglesProcedures extends Component {

    render() {

        const { path } = this.props.match;

        return (

            <section id="main-content">
                <section class="wrapper site-min-height">                
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <h3><i className='fa fa-plus'></i> Ajouter une Règle &amp; Procédure</h3>
                        <h3><TopButton route='/home/passation/reglesProcedures/details' title='Liste des Règles &amp; Procédures' icon='list' /> </h3>
                    </div>
                    <div class="row mt">
                        <div class="col-lg-12">

                            <div class="content-panel">
                                <div class="panel-body">
                                    <Router>
            
                                        <div>
                                            <ul class="nav nav-tabs" role="tablist" style={{ backgroundColor: Colors.primary }}>
                                                <li role="presentation" class="active"><NavLink to={`${path}`}
                                                    activeStyle={{ background: Colors.white, color: Colors.primary, borderBottom: '2px solid #4ecdc4' }}>Nationales</NavLink></li>
                                                <li role="presentation"><NavLink to={`${path}/proceduresBailleurs`} style={{ color: Colors.white }}
                                                    activeStyle={{ background: Colors.white, color: Colors.primary, borderBottom: '2px solid #4ecdc4' }}>Procédures Bailleurs</NavLink></li>
                                            </ul>
                                            <div class="tab-content">
                                                <Switch>
                                                    <Route path={`${path}`} exact component={Nationales} />
                                                    <Route path={`${path}/proceduresBailleurs`} exact component={ProceduresBailleurs} />
                                                </Switch>
                                            </div>
                                        </div>

                                    </Router>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </section>

        );
    }
}