import React from 'react';
import { connect } from 'react-redux';
import { Colors } from '../utils';
import { PDFReader } from 'reactjs-pdf-reader';
import {Document,pdfjs} from 'react-pdf'
var atob = require('atob');

const PdfViewer = (props) => {
    
     //var bin = atob(props.componentState.pdf);

    switch (props.componentState.previewModal) {
        case true:
            document.getElementById('previewModal').click()
            break;

        default:
            break;
    }

    return (
        <div>
            <button id="previewModal" data-toggle="modal" data-target="#modal" hidden></button>

            <div class="modal fade" id="modal" tabindex="-1" role="dialog" aria-labelledby="previewModal" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h5 class="modal-title" id="exampleModalLabel"><i class="fas fa-print"></i> Aperçu <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <h5 style={{ color: Colors.white }}>X</h5>
                            </button></h5>

                        </div>
                        <div class="modal-body">
                                           
                           {/* {props.componentState.pdf !== '' ? <PDFReader document={{base64:`${props.componentState.pdf}`}} /> : null } */}
                           {props.componentState.pdf !== '' ? <iframe src={props.componentState.pdf} title='Aperçu' type="application/pdf"
                                style={{ width: '100%', height: '500px' }} frameborder="0">Error</iframe> : null }
                           
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    componentState: state.componentState,
});

export default connect(mapStateToProps, mapDispatchToProps)(PdfViewer)