import axios from 'axios';
import SERVER from '../../../utils/Server';

export const getAudit = () => {

  return axios.get(SERVER.ADDRESS + 'exercice/audit'
  ).then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}

export const saveAudit = (audit) => {

  return axios.post(SERVER.ADDRESS + 'exercice/audit/register', audit).then((response) => {
    return response
  }).catch((response) => {
    return response
  });
}

export const deleteAudit = (id) => {

  return axios.delete(SERVER.ADDRESS + 'exercice/audit/'+id
  ).then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}