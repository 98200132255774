import constants from '../constants';

const initialState = {
    previewModal: false,
    pdf: '',
    exerciceYear: 2020,
    notificationModal: false,
    confirmationModal: false,
    saveModal: false,
    message: '',
    icon: '',
    color: '',
    deleteAction: null,
    reload: false,
    tableModal: false,
    component: null,
    loader: false
}

const reducer = (state = initialState, action) => {

    let nextState

    switch (action.type) {

        case constants.OPEN_PREVIEW_MODAL:

            nextState = {
                ...state,
                previewModal: true,
                notificationModal: false,
                confirmationModal: false,
                pdf: action.pdf
            }

            return nextState || state;

        case constants.CLOSE_PREVIEW_MODAL:

            nextState = {
                ...state,
                previewModal: false,
            }

            return nextState || state;

        case constants.OPEN_NOTIFICATION_MODAL:

            nextState = {
                ...state,
                notificationModal: true,
                message: action.message,
                icon: action.icon,
                color: action.color,
                reload: action.reload,
                previewModal: false,
                confirmationModal: false,
            }

            return nextState || state;

        case constants.CLOSE_NOTIFICATION_MODAL:

            nextState = {
                ...state,
                notificationModal: false,

            }

            return nextState || state;

        case constants.SET_EXERCICE_YEAR:

            nextState = {
                ...state,
                exerciceYear: action.year
            }

            return nextState || state;

        case constants.OPEN_CONFIRMATION_MODAL:

            nextState = {
                ...state,
                confirmationModal: true,
                message: action.message,
                icon: action.icon,
                color: action.color,
                deleteAction: action.deleteAction,
                previewModal: false,
                notificationModal: false,
            }

            return nextState || state;

        case constants.CLOSE_CONFIRMATION_MODAL:

            nextState = {
                ...state,
                confirmationModal: false,
                message: '',
                icon: '',
                color: '',
                deleteAction: null
            }

            return nextState || state;

        case constants.SHOW_LOADER:

            nextState = {
                ...state,
                loader: true
            }

            return nextState || state;

        case constants.HIDE_LOADER:

            nextState = {
                ...state,
                loader: false
            }

            return nextState || state;

        case constants.OPEN_SAVE_MODAL:

            nextState = {
                ...state,
                saveModal: true,
                notificationModal: false,
                previewModal: false,
                confirmationModal: false,
            }

            return nextState || state;

        case constants.CLOSE_SAVE_MODAL:

            nextState = {
                ...state,
                saveModal: false,
            }

            return nextState || state;

        default:
            return state;

    }
}

export default reducer;