import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Colors } from '../../../utils';
import * as actions from '../actions';
import { TopButton, TableHead, EmptyList, GroupedButton, SearchBar, GroupedButtonForm, Loader } from '../../../components';
import { closePreviewModal } from '../../../components/actions';
import { AddAvenantModal } from '../../passation/modals';
import AddResilieModal from '../modals/AddResilieModal';
import AddAvenantExecutionModal from '../modals/AddAvenantExecutionModal';
import { AvenantModal, ResilieModal } from '../modals';

const Details = (props) => {

    const { path } = props.match;

    useEffect(() => {
        props.dispatch(actions.getExecution());
        return () => {
            props.dispatch(closePreviewModal());
            props.dispatch(actions.closeAvenantModal());
            props.dispatch(actions.closeResilieModal());
        }
    }, []);

    let year = (new Date()).getFullYear();

    let exerciceYears = Array.from(new Array(3), (val, index) => year - index);
   
    return (

        <section id="main-content">
            <section class="wrapper site-min-height">
               
                <div style={{ width: '100%', display:'flex', justifyContent:'space-between' }}>                
                <h3><i className='fa fa-list'></i> Liste des Exécutions</h3>
                <h3><TopButton route='/home/execution/add' title='Ajouter une Exécution' icon='plus' /> </h3>                              
                </div>
                <div class="row mt">
                    <div class="col-lg-12">

                        <div class="content-panel">
                            <div class="panel-body">

                                <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                                    <h4>Veuillez choisir l'exercice</h4>
                                    <select className='btn' style={{ width: 200, backgroundColor: Colors.primary, color: Colors.white }}>
                                        {
                                            exerciceYears.map((year, index) => {
                                                return <option key={index} value={year}>{year}</option>
                                            })
                                        }
                                    </select>
                                </div>  

                                {props.componentState.loader ? <Loader /> : 

                                <div class="adv-table" style={{ paddingTop: 15 }}>

                                    {props.executionState.executions.length !== 0 ?

                                        <div>

                                            <SearchBar />

                                            <div style={{ overflowX: 'auto' }}>

                                            <table cellpadding="0" cellspacing="0" border="0" class="display table table-striped table-hover" id="hidden-table-info">

                                                <TableHead data={['Nº', 'Référence du contrat', 'Attributaire', 'Montant', 'Notification du contrat',
                                                    'OS de démarrage', "Délais d'exécution", 'Cautionnement définitif', 'Assurance', 'Retenue de garantie',
                                                    'Contrat enregistré', "Rapport d'exécution", 'Factures / décomptes', 'Résilié', 'Avenant',
                                                    'PV de réception provisoire', 'PV réception définitive', 'Pénalités de retard', '', '']} />

                                                <tbody>
                                                    {props.executionState.executions.map((e, index) => (
                                                        <tr key={e.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{e.referencecontrat}</td>
                                                            <td>{e.attributaire}</td>
                                                            <td>{e.montant}</td>
                                                            <td><GroupedButton pdf={e.notificationcontrat} name={e.notificationcontrattitle} /></td>
                                                            <td><GroupedButton pdf={e.osdemarrage} name={e.osdemarragetitle} /></td>
                                                            <td>{`${e.delaisexecution} ${e.delaisexecutiontype}`}</td>
                                                            <td><GroupedButton pdf={e.cautionnementdefinitif} slice={true} name={e.cautionnementdefinitiftitle} /></td>
                                                            <td><GroupedButton pdf={e.assurance} slice={true} name={e.assurancetitle} /></td>
                                                            <td><GroupedButton pdf={e.retenuegarantie} slice={true} name={e.retenuegarantietitle} /></td>
                                                            <td><GroupedButton pdf={e.contratenregistre} name={e.contratenregistretitle} /></td>
                                                            <td><GroupedButton pdf={e.rapportexecution} slice={true} name={e.rapportexecutiontitle} /></td>
                                                            <td><GroupedButton pdf={e.facturesdecomptes} name={e.facturesdecomptestitle} /></td>
                                                            <td><GroupedButtonForm showList={actions.getResilie(e.id)} new={actions.openAddResilieModal(e.id)} /></td>
                                                            <td><GroupedButtonForm showList={actions.getAvenantExecution(e.id)} new={actions.openAddAvenantModal(e.id)} /></td>
                                                            <td><GroupedButton pdf={e.pvreceptionprovisoire} slice={true} name={e.pvreceptionprovisoiretitle} /></td>
                                                            <td><GroupedButton pdf={e.pvreceptiondefinitive} slice={true} name={e.pvreceptiondefinitivetitle} /></td>
                                                            <td>{e.penalitesretard}</td>
                                                            <td><button className="btn btn-primary btn-sm" ><i class="fa fa-edit" ></i></button></td>
                                                            <td><button className="btn btn-danger btn-sm" ><i class="fa fa-trash" ></i></button></td>

                                                        </tr>
                                                    ))}

                                                </tbody>

                                            </table>
                                                       
                                             <AddAvenantExecutionModal />
                                            <AddResilieModal />
                                             <AvenantModal />
                                             <ResilieModal />
                                             
                                        </div>
                                        </div>
                                        : <EmptyList />}
                                </div> }

                            </div>
                        </div>


                    </div>
                </div>
            </section>

        </section>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    executionState: state.executionState,
    componentState: state.componentState
});

export default connect(mapStateToProps, mapDispatchToProps)(Details)