import React from 'react';
import { Colors } from '../utils';
import { connect } from 'react-redux';

const GroupedButtonForm = (props) => {

    const styles = {
        download_btn: {
            backgroundColor: Colors.steelblue,
            color: Colors.white,
            padding: '4px 10px',
            fontSize: 14,
            textAlign: 'center',
            whiteSpace: 'nowrap',
            border: '1px solid transparent',
            borderLeftColor: Colors.lavender
        },
        sub_menu: {
            cursor: 'pointer'
        }
    }

    return (
        <div class="btn-group">
             <button type="button" disabled style={styles.download_btn} >Oui</button> 
           
            <button type="button" style={styles.download_btn} data-toggle="dropdown">
                <span class="caret"></span>
                <span class="sr-only">Toggle Dropdown</span>
            </button>
            <ul class="dropdown-menu" role="menu">
                <li><a style={styles.sub_menu} onClick={() => props.dispatch(props.new)}>
                    <i class="fa fa-plus"></i> Nouveau</a></li>
                <li class="divider"></li>
                <li><a style={styles.sub_menu} onClick={() => props.dispatch(props.showList)}>
                    <i class="fa fa-list"></i> Liste</a></li>
            </ul>
        </div>
    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    componentState: state.componentState,
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupedButtonForm)