import React from 'react';
import SidebarButton from './SidebarButton';
import SidebarDropdownButton from './SidebarDropdownButton';

const Sidebar =()=> {
    
        return(
			<aside>
			<div id="sidebar" class="nav-collapse ">
			  
			  <ul class="sidebar-menu" id="nav-accordion">
				<p class="centered"><a href="profile.html"><img src="/img/dan.jpg" alt='Profile' class="img-circle" width="80" height="80" /></a></p>
				<h5 class="centered">Cybex.Ai</h5>
			
				<SidebarButton title={'Tableau de bord'} route={'home'} icon={'th-large'} />
				
				<SidebarDropdownButton heading={'Passation'} headingIcon={'exchange-alt'}
				title1={'Exercice'} title2={'Règles & Procedures'} route1={'home/passation/exercice'}
				route2={'home/passation/reglesProcedures'} />			
				
				<SidebarButton title={'Exécution'} route={'home/execution'} icon={'code-branch'} />
				<SidebarButton title={'Audit'} route={'home/audit'} icon={'file'} />
				<SidebarButton title={'Sanction'} route={'home/sanction/contratsResilies'} icon={'exclamation-triangle'} />
				<SidebarButton title={'Rapport de Performance'} route={'home/rapportPerformance'} icon={'chart-line'} />
				<SidebarButton title={'Utilisateurs'} route={'home/utilisateurs'} icon={'users'} />
				<SidebarButton title={'Privilèges'} route={'home/privileges'} icon={'universal-access'} />
				<SidebarButton title={'Paramètres'} route={'home/parametres'} icon={'cog'} />
				<SidebarButton title={'Déconnexion'} route={'signin'} icon={'power-off'} />
			
			  </ul>
			  
			</div>
		  </aside>
        )    
}

export default Sidebar;