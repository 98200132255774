import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { Colors } from '../utils';
import { closeNotificationModal } from './actions';

const ConfirmationModal = (props) => {

    // useEffect(() => {

    //     return () => {
    //         props.dispatch(closeNotificationModal());
    //     }
    // }, []);

    const styles = {
        yes: {
            backgroundColor: Colors.firebrick,
            marginTop: 20,
            color: Colors.white
        },
        no: {
            backgroundColor: '#c2c2c2',
            marginTop: 20,
            color: Colors.white,
            marginRight: 20
        }
    }

    switch (props.componentState.confirmationModal) {
        case true:
            document.getElementById('confirmationModal').click()
            break;

        default:
            break;
    }

    return (
        <div>
            <button id="confirmationModal" data-toggle="modal" data-target="#confModal" hidden></button>
        
            <div class="modal fade" id="confModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">

                        <div class="modal-body centered">

                            <i class={`fa fa-${props.componentState.icon} fa-9x`} style={{ color: props.componentState.color }}></i>
                            <h1 style={{ color: props.componentState.color }}>{props.componentState.message}</h1>
                            <h3>Nb: Cette operation est irreversible</h3>

                            <button type="button" class="btn" id='closeConfirmationModal' data-dismiss="modal" style={styles.no}>NON</button>
                            <button type="button" class="btn" style={styles.yes}
                                onClick={() => props.dispatch(props.componentState.deleteAction)}>OUI</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    componentState: state.componentState,
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal)
