import React, { useEffect } from 'react';
import { Table } from '../../../components';
import * as actions from '../actions';
import { connect } from 'react-redux';
import AddModal from '../modals/AddModal';


const Utilisateurs = (props) => {

    const openAddModal = () => {
        props.dispatch(actions.openAddModal());
    }

    useEffect(() => {
       // props.dispatch(actions.getUsers());
    }, []);

    return (
        
        <section id="main-content">
            <section class="wrapper site-min-height">
                <h3><i class="fa fa-users"></i> Utilisateurs</h3>
                <div class="row mt">
                    <div class="col-lg-12">

                        <div class="content-panel" style={{backgroundColor:'#f2f2f2'}}>
                            <div class="panel-body">
                            <h4>Cette page est indisponible pour l'instant</h4>
                            {/* <button className="btn btn-primary" style={{ marginRight: 10 }} onClick={openAddModal} > <i class="fa fa-plus" ></i> Ajouter</button>
                                <Table
                                    thead={['Nº', 'Nom', 'Prénom', 'Poste',
                                        'Departement', "Date d'enregistrement", '']}
                                    tbody={props.utilisateurState.users}
                                    td={['name', 'surname', 'postname',
                                        'departmentname']} /> */}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <AddModal />
        </section>

    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    utilisateurState: state.utilisateurState,
});

export default connect(mapStateToProps, mapDispatchToProps)(Utilisateurs)