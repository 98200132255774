import React from 'react';
import { connect } from 'react-redux';

const NotificationModal = (props) => {

    switch (props.componentState.notificationModal) {
        case true:
            document.getElementById('notificationModal').click()
            break;

        default:
            break;
    }

    const closeModal = () => {        
        
        document.getElementById('closeNotificationModal').click()
        
        switch (props.componentState.reload) {
            case true:
                window.location.reload(false)
                break;
        
            default:
                break;
        }
        
    }
    
    return (
        <div>
            <button id="notificationModal" data-toggle="modal" data-target="#nModal" hidden></button>            

            <div class="modal fade" id="nModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">

                        <div class="modal-body centered">

                            <i class={`fa fa-${props.componentState.icon} fa-9x`} style={{ color: props.componentState.color }}></i>
                            <h1 style={{ color: props.componentState.color }}>{props.componentState.message}</h1>
                            <button id="closeNotificationModal" data-dismiss="modal" hidden />
                            <button class="btn" style={{ backgroundColor: '#c2c2c2', marginTop: 20 }} onClick={() => closeModal()} >FERMER</button>                            
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    componentState: state.componentState,
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal)