import React, { useEffect } from 'react';
import '../../../App.css';
import {
  LineChart, Line, XAxis, YAxis,
  CartesianGrid, Tooltip, Legend, AreaChart, Area,
  BarChart, Bar, ReferenceLine
} from 'recharts';
import { Colors } from '../../../utils';

const Dashboard = () => {

  useEffect(() => {
    let script = document.createElement('script');
    script.src = '/lib/common-scripts.js';
    document.body.appendChild(script);
  }, []);

  const data = [
    {
      name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
    },
    {
      name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
    },
    {
      name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
    },
    {
      name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
    },
    {
      name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
    },
    {
      name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
    },
    {
      name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
    },
  ];


  return (

    <section id="main-content">
      <section class="wrapper">
        <h1 style={{ textAlign: 'center' }}>Tableau de bord</h1>
        <div class="row" style={{ backgroundColor: Colors.darkslategray, margin: 5 }}>
          
          <div class="col-lg-2 main-chart" style={{ textAlign: 'center', borderRightColor: '#ebebeb', borderRightStyle: 'solid' }}>
            <div><p style={{ textAlign: 'center', fontSize: 14, borderBottomColor: '#ebebeb', borderBottomStyle: 'solid', color: '#ebebeb' }}>Date de validation du PPM </p> </div>
            <p style={{ fontSize: 14, color: '#ebebeb' }}>12/05/2020</p>
          </div>
          <div class="col-lg-2 main-chart" style={{ textAlign: 'center', borderRightColor: '#ebebeb', borderRightStyle: 'solid' }}>
            <div><p style={{ textAlign: 'center', fontSize: 14, borderBottomColor: '#ebebeb', borderBottomStyle: 'solid', color: '#ebebeb' }}>Nombre de marchés prévus dans le PPM </p> </div>
            <p style={{ fontSize: 14, color: '#ebebeb' }}>12</p>
          </div>
          <div class="col-lg-2 main-chart" style={{ textAlign: 'center', borderRightColor: '#ebebeb', borderRightStyle: 'solid' }}>
            <div><p style={{ textAlign: 'center', fontSize: 14, borderBottomColor: '#ebebeb', borderBottomStyle: 'solid', color: '#ebebeb' }}>Nombre de consultations lancées </p> </div>
            <p style={{ fontSize: 14, color: '#ebebeb' }}>20</p>
          </div>
          <div class="col-lg-2 main-chart" style={{ textAlign: 'center', borderRightColor: '#ebebeb', borderRightStyle: 'solid' }}>
            <div><p style={{ textAlign: 'center', fontSize: 14, borderBottomColor: '#ebebeb', borderBottomStyle: 'solid', color: '#ebebeb' }}>Nombre de contrats signés </p> </div>
            <p style={{ fontSize: 14, color: '#ebebeb' }}>120</p>
          </div>
          <div class="col-lg-2 main-chart" style={{ textAlign: 'center', borderRightColor: '#ebebeb', borderRightStyle: 'solid' }}>
            <div><p style={{ textAlign: 'center', fontSize: 14, borderBottomColor: '#ebebeb', borderBottomStyle: 'solid', color: '#ebebeb' }}>Nombre de contrats exécutés </p> </div>
            <p style={{ fontSize: 14, color: '#ebebeb' }}>05</p>
          </div>
          <div class="col-lg-2 main-chart" style={{ textAlign: 'center' }}>
            <div><p style={{ textAlign: 'center', fontSize: 14, borderBottomColor: '#ebebeb', borderBottomStyle: 'solid', color: '#ebebeb' }}>Rapport d'activité <h1></h1> </p> </div>
            <p style={{ fontSize: 14, color: '#ebebeb' }}>202</p>
          </div>

        </div>
        <div class="row">
          <div class="col-lg-6 main-chart">
            <div style={{ borderWidth: 2, borderColor: Colors.darkslategray, borderStyle: 'solid' }}>
              <div style={{ backgroundColor: Colors.primary, height: 40 }}><p style={{ textAlign: 'center', color: Colors.white, fontSize: 25 }}>Title 1</p> </div>
              <LineChart
                width={500}
                height={400}
                data={data}
                margin={{
                  top: 15, bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </LineChart>

            </div>
          </div>
          <div class="col-lg-6 main-chart">
          <div style={{ borderWidth: 2, borderColor: Colors.darkslategray, borderStyle: 'solid' }}>
              <div style={{ backgroundColor: Colors.primary, height: 40 }}><p style={{ textAlign: 'center', color: Colors.white, fontSize: 25 }}>Title 1</p> </div>
            <AreaChart
              width={500}
              height={400}
              data={data}
              margin={{
                top: 15, bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
            </AreaChart>
            </div>
            </div>
          
        </div>
        <div class="row">
          <div class="col-lg-6 main-chart">
          <div style={{ borderWidth: 2, borderColor: Colors.darkslategray, borderStyle: 'solid' }}>
              <div style={{ backgroundColor: Colors.primary, height: 40 }}><p style={{ textAlign: 'center', color: Colors.white, fontSize: 25 }}>Title 1</p> </div>
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 15, bottom: 5,
              }}
              barSize={20}
            >
              <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
              <YAxis />
              <Tooltip />
              <Legend />
              <CartesianGrid strokeDasharray="3 3" />
              <Bar dataKey="pv" fill="#8884d8" background={{ fill: '#eee' }} />
            </BarChart>
            </div>
          </div>

          <div class="col-lg-6 main-chart">
          <div style={{ borderWidth: 2, borderColor: Colors.darkslategray, borderStyle: 'solid' }}>
              <div style={{ backgroundColor: Colors.primary, height: 40 }}><p style={{ textAlign: 'center', color: Colors.white, fontSize: 25 }}>Title 1</p> </div>
            <BarChart
              width={500}
              height={300}
              data={data}
              stackOffset="sign"
              margin={{
                top: 15, bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <ReferenceLine y={0} stroke="#000" />
              <Bar dataKey="pv" fill="#8884d8" stackId="stack" />
              <Bar dataKey="uv" fill="#82ca9d" stackId="stack" />
            </BarChart>
          </div>
        </div>
      </div>


        {/* <div class="row">
                <div class="col-lg-12 main-chart">
                
                  <div class="border-head">
                    <h3>USER VISITS</h3>
                  </div>
                  <div class="custom-bar-chart">
                    <ul class="y-axis">
                      <li><span>10.000</span></li>
                      <li><span>8.000</span></li>
                      <li><span>6.000</span></li>
                      <li><span>4.000</span></li>
                      <li><span>2.000</span></li>
                      <li><span>0</span></li>
                    </ul>
                    <div class="bar">
                      <div class="title">JAN</div>
                      <div class="value tooltips" data-original-title="8.500" data-toggle="tooltip" data-placement="top">85%</div>
                    </div>
                    <div class="bar ">
                      <div class="title">FEB</div>
                      <div class="value tooltips" data-original-title="5.000" data-toggle="tooltip" data-placement="top">50%</div>
                    </div>
                    <div class="bar ">
                      <div class="title">MAR</div>
                      <div class="value tooltips" data-original-title="6.000" data-toggle="tooltip" data-placement="top">60%</div>
                    </div>
                    <div class="bar ">
                      <div class="title">APR</div>
                      <div class="value tooltips" data-original-title="4.500" data-toggle="tooltip" data-placement="top">45%</div>
                    </div>
                    <div class="bar">
                      <div class="title">MAY</div>
                      <div class="value tooltips" data-original-title="3.200" data-toggle="tooltip" data-placement="top">32%</div>
                    </div>
                    <div class="bar ">
                      <div class="title">JUN</div>
                      <div class="value tooltips" data-original-title="6.200" data-toggle="tooltip" data-placement="top">62%</div>
                    </div>
                    <div class="bar">
                      <div class="title">JUL</div>
                      <div class="value tooltips" data-original-title="7.500" data-toggle="tooltip" data-placement="top">75%</div>
                    </div>
                  </div>
                 
                  <div class="row mt">
                 
                    <div class="col-md-4 col-sm-4 mb">
                      <div class="grey-panel pn donut-chart">
                        <div class="grey-header">
                          <h5>SERVER LOAD</h5>
                        </div>
                        <canvas id="serverstatus01" height="120" width="120"></canvas>
 
                        <div class="row">
                          <div class="col-sm-6 col-xs-6 goleft">
                            <p>Usage<br/>Increase:</p>
                          </div>
                          <div class="col-sm-6 col-xs-6">
                            <h2>21%</h2>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                    
                    <div class="col-md-4 col-sm-4 mb">
                      <div class="darkblue-panel pn">
                        <div class="darkblue-header">
                          <h5>DROPBOX STATICS</h5>
                        </div>
                        <canvas id="serverstatus02" height="120" width="120"></canvas>

                        <p>April 17, 2014</p>
                        <footer>
                          <div class="pull-left">
                            <h5><i class="fa fa-hdd-o"></i> 17 GB</h5>
                          </div>
                          <div class="pull-right">
                            <h5>60% Used</h5>
                          </div>
                        </footer>
                      </div>
                      
                    </div>
                    
                    <div class="col-md-4 col-sm-4 mb">
                    
                      <div class="green-panel pn">
                        <div class="green-header">
                          <h5>REVENUE</h5>
                        </div>
                        <div class="chart mt">
                          <div class="sparkline" data-type="line" data-resize="true" data-height="75" data-width="90%" data-line-width="1" data-line-color="#fff" data-spot-color="#fff" data-fill-color="" data-highlight-line-color="#fff" data-spot-radius="4" data-data="[200,135,667,333,526,996,564,123,890,464,655]"></div>
                        </div>
                        <p class="mt"><b>$ 17,980</b><br/>Month Income</p>
                      </div>
                    </div>
                    
                  </div>
                                                                                    
                </div>                               
              
              </div> */}

      </section>
    </section>

  );
}

export default Dashboard;