import React,{Component} from 'react';


export default class Parametres extends Component {

    render() {

        return( 
            <section id="main-content">
            <section class="wrapper site-min-height">
              <h3><i class="fa fa-cog"></i> Parametres</h3>
              <div class="row mt">
                <div class="col-lg-12">
                          
                  <div class="content-panel" style={{backgroundColor:'#f2f2f2'}}>
                    <div class="panel-body">
                      <h4>Cette page est indisponible pour l'instant</h4>
                     
                    </div>
                  </div>                  
                  
                </div>
              </div>
            </section>
            
          </section>
    
        );
    }
}