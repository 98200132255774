import constants from '../constants';

const initialState = {
    audits: [],
}

const reducer = (state = initialState, action) => {

    let nextState

    switch (action.type) {

        case constants.GET_AUDIT_SUCCESS:

            nextState = {
                ...state,
                audits: action.audits
            }

            return nextState || state;
        
        default:
            return state;

    }
}

export default reducer;