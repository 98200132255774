import constants from '../constants';

export function getDepartements() {
    return {
        type: constants.GET_DEPARTEMENTS        
    }
}

export function getDepartementsSuccess(departements) {
    return {
        type: constants.GET_DEPARTEMENTS_SUCCESS,
        departements
    }
}

export function getPosts() {
    return {
        type: constants.GET_POSTS        
    }
}

export function getPostsSuccess(posts) {
    return {
        type: constants.GET_POSTS_SUCCESS,
        posts
    }
}

export function getUsers() {
    return {
        type: constants.GET_USERS        
    }
}

export function getUsersSuccess(users) {
    return {
        type: constants.GET_USERS_SUCCESS,
        users
    }
}

export function saveUser(user) {
    return {
        type: constants.SAVE_USER,
        user
    }
}

export function deleteUser(userid) {
    return {
        type: constants.DELETE_USER,
        userid
    }
}

export function openAddModal() {
    return {
        type: constants.OPEN_ADD_MODAL        
    }
}

export function closeAddModal() {
    return {
        type: constants.CLOSE_ADD_MODAL        
    }
}