import React, { Component } from 'react';
import { Colors } from '../../../../../utils';

export default class PpmMisaJour extends Component {

    render() {
        return (
           
            <div class="adv-table" style={{ paddingTop: 15 }}>

            <h4>Cette page est indisponible pour l'instant</h4>
            </div>

        );
    }
}