import React from 'react';
import { Colors } from '../utils';
import { connect } from 'react-redux';

const SearchBar = (props) => {

    const styles = {
        ctn: {
            marginBottom: 15, 
        },
        input: {
            borderColor: Colors.primary,
            borderWidth: 2,
            width: 250,
            marginRight: 10,
            textAlign: 'left',
            cursor: 'auto',
        },
        btn: {
            backgroundColor: Colors.primary, 
            color: Colors.white
        }
    }

    return (
        <div style={styles.ctn}>
            <input className='btn' style={styles.input} placeholder='Recherche...' />
            <button className='btn' style={styles.btn}>Recherche</button>
        </div>
    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    componentState: state.componentState,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)