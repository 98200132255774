import React from 'react';
import { connect } from 'react-redux';
import { Colors } from '../../../utils';
import Avenant from '../view/Avenant';


const AvenantModal = (props) => {

    switch (props.executionState.avenantModal) {
        case true: 
            document.getElementById('openAvenantExecutionModalBtn').click()
            break;

        default:
            break;
    }

    return (
        <div>
            <button id="openAvenantExecutionModalBtn" data-toggle="modal" data-target="#avenantExecutionModal" hidden></button>

            <div class="modal fade" id="avenantExecutionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h2 class="modal-title" id="exampleModalLabel">Avenant <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <h5 style={{ color: Colors.white }}>X</h5>
                            </button></h2>

                        </div>

                        <div class="modal-body">
                             <Avenant /> 
                        </div>

                        <div class="modal-footer">
                            <button type="button" id='closeModalBtn' class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    executionState: state.executionState,
});

export default connect(mapStateToProps, mapDispatchToProps)(AvenantModal)