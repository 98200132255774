module.exports = {
    SAVE_EXECUTION: 'SAVE_EXECUTION',
    GET_EXECUTION: 'GET_EXECUTION',
    GET_EXECUTION_SUCCESS: 'GET_EXECUTION_SUCCESS', 
    OPEN_ADD_RESILIE_MODAL: 'OPEN_ADD_RESILIE_MODAL',
    CLOSE_ADD_RESILIE_MODAL: 'CLOSE_ADD_RESILIE_MODAL',
    OPEN_RESILIE_MODAL: 'OPEN_RESILIE_MODAL',
    CLOSE_RESILIE_MODAL: 'CLOSE_RESILIE_MODAL',
    OPEN_ADD_AVENANT_MODAL: 'OPEN_ADD_AVENANT_MODAL',
    CLOSE_ADD_AVENANT_MODAL: 'CLOSE_ADD_AVENANT_MODAL',
    OPEN_AVENANT_MODAL: 'OPEN_AVENANT_MODAL',
    CLOSE_AVENANT_MODAL: 'CLOSE_AVENANT_MODAL',
    SAVE_AVENANT_EXECUTION: 'SAVE_AVENANT_EXECUTION',
    GET_AVENANT_EXECUTION: 'GET_AVENANT_EXECUTION',
    GET_AVENANT_EXECUTION_SUCCESS: 'GET_AVENANT_EXECUTION_SUCCESS',
    DELETE_AVENANT_EXECUTION: 'DELETE_AVENANT_EXECUTION',
    SAVE_RESILIE: 'SAVE_RESILIE',
    GET_RESILIE: 'GET_RESILIE',
    GET_RESILIE_SUCCESS: 'GET_RESILIE_SUCCESS',
    DELETE_RESILIE: 'DELETE_RESILIE',
}