import React, {Component} from 'react';

export default class NavBar extends Component{

    render(){
        return(
            <header class="header black-bg">
      <div class="sidebar-toggle-box">
        <div class="fa fa-bars tooltips" data-placement="right" data-original-title="Toggle Navigation"></div>
      </div>
      
      <a href="index.html" class="logo"><img src="/img/logo.png" width="80" height="40" /></a>
      
      <div class="nav notify-row" id="top_menu">
        
        <ul class="nav top-menu">
          
          <li class="dropdown">
            <a data-toggle="dropdown" class="dropdown-toggle" href="index.html#">
              <i class="fa fa-bell"></i>
              <span class="badge bg-theme">4</span>
              </a>
            <ul class="dropdown-menu extended tasks-bar">
              <div class="notify-arrow notify-arrow-green"></div>
              <li> 
                <p class="green">Notifications</p>
              </li>
              <li>
                <a href="#">
                  Notification 1
                </a>
                <a href="#">
                  Notification 2
                </a>
                <a href="#">
                  Notification 3
                </a>
              </li>
                                       
              <li class="external">
                <a href="#">Voir toutes les notifications</a>
              </li>
            </ul>
          </li>
                  
        </ul>
        
      </div>
      {/* <div class="top-menu">
        <ul class="nav pull-right top-menu">
          <li><a class="logout" href="login.html">Logout</a></li>
        </ul>
      </div> */}
    </header>
        )
    }
}