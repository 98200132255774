import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Colors, otherUtils } from '../../../../utils';
import { saveRecours } from '../../actions';
import { Button, FileBase64 } from '../../../../components';
import { openPreviewModal, openNotificationModal } from '../../../../components/actions';


const AddRecours = (props) => {

    const [recours, setRecours] = useState([]);
    const [recoursTitle, setRecoursTitle] = useState('');
    const [suivipassationid, setSuivipassationid] = useState(0);
    const [selected, setSelected] = useState(false);


    const getRecours = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setRecours(files)
            setRecoursTitle(files.name)
            setSelected(true)
            setSuivipassationid(props.passationState.suivipassationid)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    let recour = {
        recours: recours.base64, recoursTitle, suivipassationid
    }

    const save = () => {

        if (recours !== []) {
            props.dispatch(saveRecours(recour))
            setSelected(false)
        } else {
            props.dispatch(openNotificationModal('Veuillez remplir tout les champs !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const change = () => {
        setRecours([]);
        setRecoursTitle('')
        setSelected(false)
    }

    const renderSelected = () => {
        return (
            <div>
                <img src="/img/pdf.png" width="100" alt='Pdf' />
                <h5>{recoursTitle}</h5>
                <div>
                    <Button title={'Changer'} icon={'exchange-alt'} action={() => change()} />
                    <Button title={'Aperçu'} icon={'eye'} action={() => props.dispatch(openPreviewModal(recours.base64))} />
                    <Button title={'Enregistrer'} icon={'save'} action={() => save()} />
                </div>
            </div>
        )
    }

    const renderSelect = () => {
        return (
            <div>
                <img src="/img/add_pdf.png" width="100" alt='Add Pdf' />

                <h5><button className="btn" style={{ backgroundColor: Colors.primary, color: Colors.white }}>
                    <FileBase64
                        onDone={getRecours.bind(this)} /> </button></h5>
            </div>
        )
    }

    return (
        <>

            {selected ? renderSelected() : renderSelect()}

        </>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    passationState: state.passationState,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddRecours)