export const Colors = {
  primary: '#4ecdc4',
  white: '#fff',
  lightslategray: '#959595',
  seagreen: '#217e4d',
  darkslategray: '#22242a',
  lavender: '#eaeaea',
  firebrick: '#cb3328',
  steelblue: '#337ab7'
};
