module.exports = {
    GET_DEPARTEMENTS:'GET_DEPARTEMENTS',
    GET_DEPARTEMENTS_SUCCESS:'GET_DEPARTEMENTS_SUCCESS',
    GET_POSTS: 'GET_POSTS',
    GET_POSTS_SUCCESS: 'GET_POSTS_SUCCESS',
    GET_USERS: 'GET_USERS',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    SAVE_USER: 'SAVE_USER',
    DELETE_USER: 'DELETE_USER',
    OPEN_ADD_MODAL: 'OPEN_ADD_MODAL',
    CLOSE_ADD_MODAL: 'CLOSE_ADD_MODAL',
}