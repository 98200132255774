export const styles = {
    body: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      fontFamily: 'sans-serif',
      justifyContent: 'center',
      background: `linear-gradient(
    90deg,
    rgb(21, 202, 179) 0%,
    rgb(18, 169, 150) 100%
  )`
    },
    loginbox: {
      position: 'relative',
      width: '350px',
      background: 'white',
      padding: '20px 30px',
      borderRadius: '20px'
    },
    login_lima: {
      position: 'absolute',
      top: '-40px',
      height: '150px'
    },
    login_shadow: {
      position: 'absolute',
      bottom: '70px',
      width: '360px'
    },
    illustration_1: {
      position: 'absolute',
      left: '100px',
      bottom: '80px'
    },
    illustration_2: {
      position: 'absolute',
      left: '350px',
      bottom: '150px'
    },
    illustration_3: {
      position: 'absolute',
      right: '350px',
      bottom: '150px',
    },
    illustration_4: {
      position: 'absolute',
      right: '100px',
      bottom: '80px'
    },
    header: {
      marginBottom: '20px',
      color: '#15cab3',
      fontFamily: "Century Gothic, sans-serif"
    },
    header_img: {
      height: '100px'    
    },
    button: {
      width: '100%',
      height: '40px',
      border: 'none',
      borderRadius: '40px',
      outline: 'none',
      color: 'white',
      fontSize: '16px',
      cursor: 'pointer',
      fontFamily: "Century Gothic,sans-serif",
      transition: 'all 0.4s',
      background: '#15cab3',
      paddingLeft: '15px',
    },
    input: {
      height: '100%',
      width: '100%',
      border: 'none',
      borderRadius: '40px',
      background: '#ebebeb',
      paddingLeft: '15px',
      padding: '15px',
      outline: 'none',
      fontSize: '16px',
      transition: '0.4s',
      marginTop: '20px'
    },
    remember_me: {
      textAlign: 'left',
      marginLeft: '20px',
      margin: '20px',
      fontSize: '14px',
      fontFamily: "Century Gothic,sans-serif", 
    },
    
  }