import React, { useState } from 'react';
import { saveResilie } from '../actions';
import { connect } from 'react-redux';
import { Colors, otherUtils } from '../../../utils';
import { InputText } from '../../../components';
import { openNotificationModal } from '../../../components/actions';


const AddResilie = (props) => {

    const [referencecontrat, setReferencecontrat] = useState('');
    const [titulaire, setTitulaire] = useState('');
    const [montant, setMontant] = useState('');
    const [signature, setSignature] = useState('');
    const [osdemarrage, setOsdemarrage] = useState('');
    const [misdemeure, setMisdemeure] = useState([]);
    const [misdemeureTitle, setMisdemeuretitle] = useState('');
    const [constatcarence, setConstatcarence] = useState([]);
    const [constatcarenceTitle, setConstatcarencetitle] = useState('');
    const [resiliation, setResiliation] = useState([]);
    const [resiliationTitle, setResiliationtitle] = useState('');
    const [motifresiliation, setMotifresiliation] = useState('');
    const [executionid, setExecutionid] = useState(0);

    const handleInputChange = e => {

        switch (e.target.id) {
            case 'referencecontrat':
                {
                    setReferencecontrat(e.target.value)
                    setExecutionid(props.executionState.executionid)
                }
                break;

            case 'titulaire':
                setTitulaire(e.target.value)
                break;

            case 'montant':
                setMontant(e.target.value)
                break;

            case 'signature':
                setSignature(e.target.value)
                break;

            case 'osdemarrage':
                setOsdemarrage(e.target.value)
                break;

            case 'motifresiliation':
                setMotifresiliation(e.target.value)
                break;

            default:
                break;
        }

    }

    const getMisdemeure = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setMisdemeure(files)
            setMisdemeuretitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getConstatcarence = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setConstatcarence(files)
            setConstatcarencetitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getResiliation = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setResiliation(files)
            setResiliationtitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const handleSubmit = e => {

        e.preventDefault();
        if (referencecontrat !== '' && titulaire !== '' &&
            montant !== '' && signature !== '' &&
            osdemarrage !== '', misdemeure !== [] &&
            misdemeureTitle !== '' &&
            constatcarence !== [] && constatcarenceTitle !== '' &&
            resiliation !== [] &&
            resiliationTitle !== '' && motifresiliation !== ''
        ) {

            let resilie = {
                referencecontrat, titulaire, montant, signature, osdemarrage,
                misdemeure: misdemeure.base64, misdemeureTitle,
                constatcarence: constatcarence.base64, constatcarenceTitle,
                resiliation: resiliation.base64, resiliationTitle, motifresiliation,
                executionid

            }

            props.dispatch(saveResilie(resilie))

        } else {
            props.dispatch(openNotificationModal('Veuillez remplir tout les champs !', 'exclamation-triangle', Colors.firebrick, false))
        }

    }

    return (
        <form onSubmit={handleSubmit}>

            <div class="form-row">
                <InputText title={"Référence du contrat"} id="referencecontrat" onChange={handleInputChange} value={referencecontrat} />
                <InputText title={'Titulaire'} id="titulaire" onChange={handleInputChange} value={titulaire} />
            </div>

            <div class="form-row">
                <InputText title={'Montant'} id="montant" onChange={handleInputChange} value={montant} />
                <InputText title={"Signature"} type={'date'} id="signature" onChange={handleInputChange} value={signature} />
            </div>

            <div class="form-row">
                <InputText title={"OS de démarrage"} id="osdemarrage" onChange={handleInputChange} value={osdemarrage} />
                <InputText title={'Mise en demeure'} type={'file'} onDone={getMisdemeure.bind(this)} />
            </div>

            <div class="form-row">
                <InputText title={"Constat de carence"} type={'file'} onDone={getConstatcarence.bind(this)} />
                <InputText title={'Résiliation'} type={'file'} onDone={getResiliation.bind(this)} />
            </div>

            <div class="form-row">
                <InputText title={"Motif de résiliation"} id="motifresiliation" onChange={handleInputChange} value={motifresiliation} />
            </div>

            <button type="submit" class="btn form-control" style={{ backgroundColor: Colors.primary, color: Colors.white }}>Enregistrer</button>
        </form>

    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    executionState: state.executionState,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddResilie)