import React, { useState, useEffect } from 'react';
import { getDepartements, getPosts, closeAddModal, saveUser } from '../actions';
import { connect } from 'react-redux';


const AddModal = (props) => {

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [post, setPost] = useState('');
    const [department, setDepartment] = useState('');
    

    useEffect(() => {               
        // props.dispatch(getDepartements());
        // props.dispatch(getPosts());        
    }, []);

    switch (props.utilisateurState.addModal) {
        case true:
            //document.getElementById('openModalBtn').click()
            break;
    
        default:
            break;
    }

    // const closeModal=()=> {
    //     document.getElementById('closeModalBtn').click();
    //     props.dispatch(closeAddModal());
    // }

    const handleInputChange = e => {
        switch (e.target.id) {
            case 'name':
                setName(e.target.value)
                break;
            case 'surname':
                setSurname(e.target.value)
                break;
            case 'post':
                setPost(e.target.value)
                break;
            case 'department':
                setDepartment(e.target.value)
                break;

            default:
                break;
        }

    }

    const handleSubmit = e => {

        e.preventDefault();
        if (name != '' && surname != '' &&
            post != '' && department != '') {

            let user = {
                name,
                surname,
                post,
                department
            }

            props.dispatch(saveUser(user))
        } else {
            alert('Veuillez remplir tout les champs!')
        }

    }

    return (
<div>
<button  id="openModalBtn" data-toggle="modal" data-target="#exampleModal"></button>
 
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header" style={{ alignItems: 'center', textAlign: 'center', backgroundColor: '#31A3FB' }}>
                        <h2 class="modal-title" id="exampleModalLabel" style={{ color: '#fff' }}>Ajouter un Utilisateur</h2>
                        {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button> */}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div class="modal-body">
                            <div class="form-group">
                                <label for="name" class="col-form-label">Nom:</label>
                                <input type="text" class="form-control" id="name" onChange={handleInputChange} value={name} />
                            </div>
                            <div class="form-group">
                                <label for="surname" class="col-form-label">Prenom:</label>
                                <input type="text" class="form-control" id="surname" onChange={handleInputChange} value={surname} />
                            </div>
                            <div class="form-group">
                                <label for="post" class="col-form-label">Poste:</label>
                                <select class="form-control" id="post" onChange={handleInputChange} value={post}>
                                    {props.utilisateurState.posts.map(e => (
                                        <option key={e.id} value={e.id}>
                                            {e.name}
                                        </option>
                                    ))}

                                </select>
                            </div>
                            <div class="form-group">
                                <label for="department" class="col-form-label">Departement:</label>
                                <select class="form-control" id="department" onChange={handleInputChange} value={department}>
                                    {props.utilisateurState.departements.map(e => (
                                        <option key={e.id} value={e.id}>
                                            {e.name}
                                        </option>
                                    ))}

                                </select>

                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" id='closeModalBtn' class="btn btn-secondary" data-dismiss="modal">Close</button>                            
                            {/* <button type="button" class="btn btn-secondary" onClick={closeModal}>Close</button>                             */}
                            <button type="submit" class="btn btn-primary">Sauvegarder</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
        </div>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    utilisateurState: state.utilisateurState,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddModal)