import { fork, all } from 'redux-saga/effects';
// import { utilisateurWatchers } from '../screens/utilisateurs';
import { passationWatchers } from '../screens/passation';
import { executionWatchers } from '../screens/execution';
import { auditWatchers } from '../screens/audit';

export default function* rootSaga () {

    yield all( [
        // ...Object.values( utilisateurWatchers ),
         ...Object.values( passationWatchers ),
         ...Object.values( executionWatchers ),
         ...Object.values( auditWatchers ),
    ].map( fork ) );

}