import React from 'react';
import { connect } from 'react-redux';
import { GroupedButton, TableHead, EmptyList } from '../../../components';

const Resilie = (props) => {

    return (

        <div class="adv-table" style={{ paddingTop: 15, overflowX: 'auto' }}>

            {props.executionState.resilies.length !== 0 ?

                <table cellpadding="0" cellspacing="0" border="0" class="display table table-striped table-hover" id="hidden-table-info">

                    <TableHead data={['Nº', "Référence du contrat", 'Titulaire', "Montant",
                        "Signature", "OS de démarrage", "Mise en demeure", 'Constat de carence',
                        'Résiliation', 'Motif de résiliation', '', '']} />
                    <tbody>
                        {props.executionState.resilies.map((e, index) => (
                            <tr key={e.id}>
                                <td>{index + 1}</td>
                                <td>{e.referencecontrat}</td>
                                <td>{e.titulaire}</td>
                                <td>{e.montant}</td>
                                <td>{e.signature}</td>
                                <td>{e.osdemarrage}</td>
                                <td><GroupedButton pdf={e.misdemeure} slice={true} name={e.misdemeuretitle} /></td>
                                <td><GroupedButton pdf={e.constatcarence} slice={true} name={e.constatcarencetitle} /></td>
                                <td><GroupedButton pdf={e.resiliation} slice={true} name={e.resiliationtitle} /></td>
                                <td>{e.motifresiliation}</td>
                                <td><button className="btn btn-primary btn-sm" ><i class="fa fa-edit" ></i></button></td>
                                <td><button className="btn btn-danger btn-sm" ><i class="fa fa-trash" ></i></button></td>

                            </tr>
                        ))}
                    </tbody>

                </table> : <EmptyList />}

        </div>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    executionState: state.executionState,
    componentState: state.componentState
});

export default connect(mapStateToProps, mapDispatchToProps)(Resilie)