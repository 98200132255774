import React from 'react';
import {NavLink} from 'react-router-dom';
import { Colors } from '../utils';

const MenuButton = ({title, route, btn_title, icon}) => {
        
    const styles = {
        button: {
            backgroundColor:Colors.white
        }
    }

    return (
        <div className="col-md-6 col-sm-6 mb">

        <div className="green-panel pn">
            <div className="green-header">
            <h5>{title}</h5>
            </div>
            <div className="chart mt">
                <div className="sparkline" data-type="line" data-resize="true" data-height="75" data-width="90%" data-line-width="1" data-line-color="#fff" data-spot-color="#fff" data-fill-color="" data-highlight-line-color="#fff" data-spot-radius="4" data-data="[200,135,667,333,526,996,564,123,890,464,655]"></div>
            </div>

            <h3><i className={`fa fa-${icon || 'cog'} fa-2x`}></i></h3>
                       
            <NavLink style={styles.button} to={route} className="btn"> {btn_title} </NavLink>
        </div>
    </div>
        
    );

}

export default MenuButton;