import React from 'react';

const EmptyList = () => {

    return (
        
        <div style={{ textAlign: 'center' }}>
            <i class={'far fa-sticky-note fa-7x'}></i>
            <h1>Cette liste est vide...</h1>
        </div>

    );
}

export default EmptyList;