module.exports = {    
    OPEN_PREVIEW_MODAL: 'OPEN_PREVIEW_MODAL',
    CLOSE_PREVIEW_MODAL: 'CLOSE_PREVIEW_MODAL',
    OPEN_NOTIFICATION_MODAL: 'OPEN_NOTIFICATION_MODAL',
    CLOSE_NOTIFICATION_MODAL: 'CLOSE_NOTIFICATION_MODAL',
    OPEN_CONFIRMATION_MODAL: 'OPEN_CONFIRMATION_MODAL',
    CLOSE_CONFIRMATION_MODAL: 'CLOSE_CONFIRMATION_MODAL',
    SET_EXERCICE_YEAR: 'SET_EXERCICE_YEAR',
    SHOW_LOADER: 'SHOW_LOADER',
    HIDE_LOADER: 'HIDE_LOADER',
    OPEN_SAVE_MODAL: 'OPEN_SAVE_MODAL',
    CLOSE_SAVE_MODAL: 'CLOSE_SAVE_MODAL',
    
}