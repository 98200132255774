import React, { useState, useEffect } from 'react';
import { InputText, DropdownList, PeriodSelector } from '../../../../components';
import { Colors } from '../../../../utils';
import { connect } from 'react-redux';
import { saveConsultationLancee } from '../../actions';
import { openNotificationModal, closeNotificationModal, closeSaveModal } from '../../../../components/actions';

const ConsultationsLancees = (props) => {

    useEffect(() => {        
        
        return () => {
            props.dispatch(closeNotificationModal())
            props.dispatch(closeSaveModal())
        }
    }, []);

    
    const [intituleprojet, setIntituleprojet] = useState('');
    const [typeappeloffre, setTypeappeloffre] = useState('');
    const [localisationprojet, setLocalisationprojet] = useState('');
    const [montantprevisionel, setMontantprevisionel] = useState('');
    const [sourcefinancement, setSourcefinancement] = useState('');
    const [imputationbudgetaire, setImputationbudgetaire] = useState('');
    const [validationdossiercipm, setValidationdossiercipm] = useState('');
    const [anobailleur, setAnobailleur] = useState('');
    const [oublicationjdm, setOublicationjdm] = useState('');
    const [remiseoffres, setRemiseoffres] = useState('');
    const [overturesplis, setOverturesplis] = useState('');
    const [additif, setAdditif] = useState('');
    const [delaisexecution, setDelaisexecution] = useState('');
    const [delaisexecutiontype, setDelaisexecutiontype] = useState('Jours');

    const handleSelectChange = e => {
        switch (e.target.id) {
            case 'delaisexecutiontype':
                setDelaisexecutiontype(e.target.value)
                break;
        }
    }

    const handleInputChange = e => {
        switch (e.target.id) {
            case 'intituleprojet':
                setIntituleprojet(e.target.value)
                break;
            case 'typeappeloffre':
                setTypeappeloffre(e.target.value)
                break;
            case 'localisationprojet':
                setLocalisationprojet(e.target.value)
                break;
            case 'montantprevisionel':
                setMontantprevisionel(e.target.value)
                break;
            case 'sourcefinancement':
                setSourcefinancement(e.target.value)
                break;
            case 'imputationbudgetaire':
                setImputationbudgetaire(e.target.value)
                break;
            case 'validationdossiercipm':
                setValidationdossiercipm(e.target.value)
                break;
            case 'anobailleur':
                setAnobailleur(e.target.value)
                break;
            case 'oublicationjdm':
                setOublicationjdm(e.target.value)
                break;
            case 'remiseoffres':
                setRemiseoffres(e.target.value)
                break;
            case 'overturesplis':
                setOverturesplis(e.target.value)
                break;
            case 'additif':
                setAdditif(e.target.value)
                break;
            case 'delaisexecution':
                setDelaisexecution(e.target.value)
                break;

            default:
                break;
        }

    }

   
    const handleSubmit = e => {

        e.preventDefault();
        if (intituleprojet !== '' && typeappeloffre !== '' &&
            localisationprojet !== '' && montantprevisionel !== '' &&
            sourcefinancement !== '' && imputationbudgetaire !== '' &&
            validationdossiercipm !== '' && anobailleur !== '' &&
            oublicationjdm !== '' && remiseoffres !== '' &&
            overturesplis !== '' && additif !== '' &&
            delaisexecution !== '') {

            let consultationsLancee = {
                intituleprojet, typeappeloffre, localisationprojet,
                montantprevisionel, sourcefinancement,
                imputationbudgetaire, validationdossiercipm,
                anobailleur, oublicationjdm, remiseoffres,
                overturesplis, additif, delaisexecution, delaisexecutiontype,
                exercice: props.componentState.exerciceYear
            }

            props.dispatch(saveConsultationLancee(consultationsLancee))
           
            
        } else {
            props.dispatch(openNotificationModal('Veuillez remplir tout les champs !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    return (

        <div class="row mt">
            <div class="col-lg-12">

                <div class="content-panel" style={{ backgroundColor: '#f2f2f2' }}>
                    <div class="panel-body">
                        <form onSubmit={handleSubmit}>
                            <div class="form-row">
                                <InputText title={'Intitule du projet'} id="intituleprojet" onChange={handleInputChange} value={intituleprojet} />
                            </div>

                            <div class="form-row">
                                <InputText title={"Type d'appel d'offres"} id="typeappeloffre" onChange={handleInputChange} value={typeappeloffre} />
                                <InputText title={'Localisation du projet'} id="localisationprojet" onChange={handleInputChange} value={localisationprojet} />
                            </div>

                            <div class="form-row">
                                <InputText title={'Montant prévisionnel'} id="montantprevisionel" type={'number'} onChange={handleInputChange} value={montantprevisionel} />
                                <InputText title={'Source de financement'} id="sourcefinancement" onChange={handleInputChange} value={sourcefinancement} />
                            </div>

                            <div class="form-row">
                                <InputText title={'Imputation budgétaire'} id="imputationbudgetaire" onChange={handleInputChange} value={imputationbudgetaire} />
                                <InputText title={'Validation du dossier par la CIPM'} type={'date'} id="validationdossiercipm" onChange={handleInputChange} value={validationdossiercipm} />
                            </div>

                            <div class="form-row">
                                {/* <DropdownList title={'ANO Bailleur'} id="anobailleur" data={props.passationState.anobailleurs} onChange={handleInputChange} value={anobailleur} />                                 */}
                                <InputText title={'ANO Bailleur'} id="anobailleur" type={'date'} onChange={handleInputChange} value={anobailleur} />
                                <InputText title={'Publication JDM'} id="oublicationjdm" type={'date'} onChange={handleInputChange} value={oublicationjdm} />
                            </div>

                            <div class="form-row">
                                <InputText title={'Remise des offres'} id="remiseoffres" type={'date'} onChange={handleInputChange} value={remiseoffres} />
                                <InputText title={'Ouverture des plis'} id="overturesplis" type={'date'} onChange={handleInputChange} value={overturesplis} />
                            </div>

                            <div class="form-row">
                                <InputText title={"Additif"} id="additif" type={'date'} onChange={handleInputChange} value={additif} />
                                <PeriodSelector title={"Délais d'exécution"} id="delaisexecution" selectOnChange={handleSelectChange} onChange={handleInputChange} value={delaisexecution} selectId='delaisexecutiontype' selectValue={delaisexecutiontype} />                                
                            </div>

                            <button type="submit" class="btn form-control" style={{ backgroundColor: Colors.primary, color: Colors.white }}>Enregistrer</button>
                        </form>
                    </div>
                </div>

            </div>
        </div>

    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    passationState: state.passationState,
    componentState: state.componentState
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationsLancees)