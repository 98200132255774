import React, { Component } from 'react';
import { MenuButton } from '../../../components';


export default class Menu extends Component {

    render() {

        const { path } = this.props.match;

        return (

            <section id="main-content">
                <section class="wrapper">
                    <div class="row">
                        <div class="col-lg-12 main-chart">

                        <h1 style={{ textAlign: 'center' }}>Exécution</h1>

                            <div class="row mt">

                                <MenuButton route={`${path}/add`} icon={'plus-circle'} title={'AJOUTEZ UNE EXÉCUTION'} btn_title={'AJOUTER'} />
                                <MenuButton route={`${path}/details`} icon={'list'} title={'VOIR LES EXÉCUTIONS'} btn_title={'VOIR'} />
                            
                            </div>

                        </div>

                    </div>

                </section>

            </section>

        );
    }
}