import React,{Component} from 'react';
import {Colors} from '../../../utils';

export default class ContratsResilies extends Component {

    render() {
        return(
         
                  <div class="adv-table" style={{paddingTop:15}}>
                    <table cellpadding="0" cellspacing="0" border="0" class="display table table-striped table-hover" id="hidden-table-info">
                      <thead style={{backgroundColor:Colors.primary, color:Colors.white}}>
                        <tr>
                          <th>Nº</th>
                          <th>Numero du contrat</th>
                          <th class="hidden-phone">Intitule</th>
                          <th class="hidden-phone">Attributaire</th>
                          <th class="hidden-phone">Montant</th>
                          <th class="hidden-phone">Motif de resiliation</th>
                          <th class="hidden-phone">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="gradeX">
                          <td>Trident</td>
                          <td>Internet Explorer 4.0</td>
                          <td class="hidden-phone">Win 95+</td>
                          <td class="center hidden-phone">4</td>
                          <td class="center hidden-phone">X</td>
                          <td class="center hidden-phone">X</td>
                          <td class="center hidden-phone">X</td>
                        </tr>
                        <tr class="gradeC">
                          <td>Trident</td>
                          <td>Internet Explorer 5.0</td>
                          <td class="hidden-phone">Win 95+</td>
                          <td class="center hidden-phone">5</td>
                          <td class="center hidden-phone">C</td>
                          <td class="center hidden-phone">C</td>
                          <td class="center hidden-phone">C</td>
                        </tr>
                                              
                      </tbody>
                    </table>
                  </div>                           
              
        );
    }
}