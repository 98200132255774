import constants from '../constants';

export function getExecution() {
    return {
        type: constants.GET_EXECUTION      
    }
}

export function getExecutionSuccess(executions) {
    return {
        type: constants.GET_EXECUTION_SUCCESS,
        executions
    }
}

export function saveExecution(execution) {
    return {
        type: constants.SAVE_EXECUTION,
        execution
    }
}

export function openAvenantModal() {
    return {
        type: constants.OPEN_AVENANT_MODAL  
    }
}

export function closeAvenantModal() {
    return {
        type: constants.CLOSE_AVENANT_MODAL        
    }
}

export function openAddAvenantModal(executionid) {
    return {
        type: constants.OPEN_ADD_AVENANT_MODAL,
        executionid        
    }
}

export function closeAddAvenantModal() {
    return {
        type: constants.CLOSE_ADD_AVENANT_MODAL        
    }
}

export function openResilieModal(executionid) {
    return {
        type: constants.OPEN_RESILIE_MODAL,
        executionid        
    }
}

export function closeResilieModal() {
    return {
        type: constants.CLOSE_RESILIE_MODAL        
    }
}

export function openAddResilieModal(executionid) {
    return {
        type: constants.OPEN_ADD_RESILIE_MODAL,
        executionid        
    }
}

export function closeAddResilieModal() {
    return {
        type: constants.CLOSE_ADD_RESILIE_MODAL        
    }
}

export function saveAvenantExecution(avenant) {
    return {
        type: constants.SAVE_AVENANT_EXECUTION,
        avenant
    }
}

export function getAvenantExecution(executionid) {
    return {
        type: constants.GET_AVENANT_EXECUTION, 
        executionid                       
    }
}

export function getAvenantExecutionSuccess(avenants) {
    return {
        type: constants.GET_AVENANT_EXECUTION_SUCCESS,
        avenants
    }
}

export function saveResilie(resilie) {
    return {
        type: constants.SAVE_RESILIE,
        resilie
    }
}

export function getResilie(executionid) {
    return {
        type: constants.GET_RESILIE, 
        executionid                       
    }
}

export function getResilieSuccess(resilies) {
    return {
        type: constants.GET_RESILIE_SUCCESS,
        resilies
    }
}