import React, { useEffect } from 'react';
import { TableHead, GroupedButton, EmptyList, SearchBar, Loader } from '../../../../components';
import { connect } from 'react-redux';
import { closePreviewModal } from '../../../../components/actions';
import * as actions from '../../actions';

const ProceduresBailleurs = (props) => {

    useEffect(() => {
        props.dispatch(actions.getProceduresBailleurs());
        return () => {
            props.dispatch(closePreviewModal())
        }
    }, []);

    return (

        <div class="adv-table" style={{ paddingTop: 15 }}>

            {props.componentState.loader ? <Loader /> :

                <div>

                    {props.passationState.proceduresBailleurs.length !== 0 ?

                        <div>

                            <SearchBar />

                            <div style={{ overflowX: 'auto' }}>

                                <table cellpadding="0" cellspacing="0" border="0" class="display table table-striped table-hover" id="hidden-table-info">

                                    <TableHead data={['Nº', "BM", 'AFD', 'BAD/BDEAC', 'BEI', '', '']} />

                                    <tbody>

                                        {props.passationState.proceduresBailleurs.map((e, index) => (

                                            <tr key={e.id}>
                                                <td>{index + 1}</td>
                                                <td><GroupedButton pdf={e.bm} slice={true} name={e.bmtitle} /></td>
                                                <td><GroupedButton pdf={e.afd} slice={true} name={e.afdtitle} /></td>
                                                <td><GroupedButton pdf={e.badbdeac} slice={true} name={e.badbdeactitle} /></td>
                                                <td><GroupedButton pdf={e.bei} slice={true} name={e.beititle} /></td>
                                                <td><button className="btn btn-primary btn-sm" ><i class="fa fa-edit" ></i></button></td>
                                                <td><button className="btn btn-danger btn-sm" ><i class="fa fa-trash" ></i></button></td>

                                            </tr>
                                        ))}

                                    </tbody>

                                </table>
                            </div>
                        </div>
                        : <EmptyList />}
                </div>}

        </div>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    passationState: state.passationState,
    componentState: state.componentState
});

export default connect(mapStateToProps, mapDispatchToProps)(ProceduresBailleurs)