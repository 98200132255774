import constants from '../constants';

const initialState = {
    executions: [],
    avenantModal: false,
    addAvenantExecutionModal: false,
    resilieModal: false,
    addResilieModal: false,
    avenants: [],
    resilies: [],
    executionid: 0
}

const reducer = (state = initialState, action) => {

    let nextState

    switch (action.type) {

        case constants.GET_EXECUTION_SUCCESS:

            nextState = {
                ...state,
                executions: action.executions
            }

            return nextState || state;

        case constants.GET_AVENANT_EXECUTION_SUCCESS:

            nextState = {
                ...state,
                avenants: action.avenants,
                avenantModal: true,
                resilieModal: false,
                addAvenantExecutionModal: false,                
                addResilieModal: false,
                
            }

            return nextState || state;

        case constants.GET_RESILIE_SUCCESS:

            nextState = {
                ...state,
                resilies: action.resilies,
                resilieModal: true,
                avenantModal: false,
                addAvenantExecutionModal: false,                
                addResilieModal: false,
            }

            return nextState || state;

        case constants.OPEN_AVENANT_MODAL:

            nextState = {
                ...state,
                avenantModal: true,
                addAvenantExecutionModal: false,
                addResilieModal: false,
                resilieModal: false,
            }

            return nextState || state;

        case constants.CLOSE_AVENANT_MODAL:

            nextState = {
                ...state,
                avenantModal: false
            }

            return nextState || state;

        case constants.OPEN_ADD_AVENANT_MODAL:

            nextState = {
                ...state,
                executionid: action.executionid,
                addAvenantExecutionModal: true,
                avenantModal: false,
                addResilieModal: false,
                resilieModal: false,
            }

            return nextState || state;

        case constants.CLOSE_ADD_AVENANT_MODAL:

            nextState = {
                ...state,
                addAvenantExecutionModal: false
            }

            return nextState || state;

        case constants.OPEN_RESILIE_MODAL:

            nextState = {
                ...state,
                executionid: action.executionid,
                resilieModal: true,
                addResilieModal: false,
                addAvenantExecutionModal: false,
                avenantModal: false
            }

            return nextState || state;

        case constants.CLOSE_RESILIE_MODAL:

            nextState = {
                ...state,
                resilieModal: false
            }

            return nextState || state;

        case constants.OPEN_ADD_RESILIE_MODAL:

            nextState = {
                ...state,
                executionid: action.executionid,
                addResilieModal: true,
                resilieModal: false,
                addAvenantExecutionModal: false,
                avenantModal: false
            }

            return nextState || state;

        case constants.CLOSE_ADD_RESILIE_MODAL:

            nextState = {
                ...state,
                addResilieModal: false
            }

            return nextState || state;

        default:
            return state;

    }
}

export default reducer;