import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Menu from './Menu';
import Details from './Details';
import Form from '../../forms/reglesProcesures';

export default class ReglesProcedures extends Component {

    render() {

        const { path } = this.props.match;

        return (

            <Router>

                <Route path={`${path}`} exact component={Details} />
                <Route path={`${path}/details`} component={Details} />
                <Route path={`${path}/add`} component={Form} />

            </Router>

        );
    }
}