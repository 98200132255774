import React from 'react';
import { FileBase64 } from '.';
// import FileBase64 from 'react-file-base64';


const InputText = ({ title, type, ...other_props }) => {

    if (type === 'file') {
        return (
            <div class="form-group col-md-6">
            <label for={title}>{title}</label>            
            <FileBase64
            {...other_props} id={title} />
            </div>
        )
    } else {
        
        return (
    
            <div class="form-group col-md-6">
                <label for={title}>{title}</label>
                <input type={type} class="form-control" id={title} 
                placeholder={title} {...other_props} />
            </div>
    
        );
    }

}

export default InputText;