import React, { useState, useEffect } from 'react';
import { InputText } from '../../../../components';
import { Colors, otherUtils } from '../../../../utils';
import { connect } from 'react-redux';
import { saveProcedureBailleur } from '../../actions';
import { openNotificationModal, closeNotificationModal, closeSaveModal } from '../../../../components/actions';

const ProceduresBailleurs = (props) => {

    useEffect(() => {
        return () => {
            props.dispatch(closeNotificationModal())
            props.dispatch(closeSaveModal())
        }
    }, []);

    const [bm, setBm] = useState([]);
    const [bmTitle, setBmTitle] = useState('');
    const [afd, setAfd] = useState([]);
    const [afdTitle, setAfdTitle] = useState('');
    const [badbdeac, setBadbdeac] = useState([]);
    const [badbdeacTitle, setBadbdeacTitle] = useState('');
    const [bei, setBei] = useState([]);
    const [beiTitle, setBeiTitle] = useState('');


    const getbm = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setBm(files)
            setBmTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getafd = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setAfd(files)
            setAfdTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getbadbdeac = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setBadbdeac(files)
            setBadbdeacTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getbei = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setBei(files)
            setBeiTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }


    const handleSubmit = e => {

        e.preventDefault();
        if (bm !== [] && afd !== '' &&
            badbdeac !== [] && bei !== '') {

            let procedureBailleur = {
                bm: bm.base64, bmTitle,
                afd: afd.base64, afdTitle,
                badbdeac: badbdeac.base64, badbdeacTitle,
                bei: bei.base64, beiTitle
            }

            props.dispatch(saveProcedureBailleur(procedureBailleur))
        } else {
            props.dispatch(openNotificationModal('Veuillez remplir tout les champs !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    return (

        <div class="row mt">
            <div class="col-lg-12">

                <div class="content-panel" style={{ backgroundColor: '#f2f2f2' }}>
                    <div class="panel-body">

                        <form onSubmit={handleSubmit}>

                            <div class="form-row">
                                <InputText title={'BM'} type={'file'} onDone={getbm.bind(this)} />
                                <InputText title={'AFD'} type={'file'} onDone={getafd.bind(this)} />
                            </div>

                            <div class="form-row">
                                <InputText title={"BAD/BDEAC"} type={'file'} onDone={getbadbdeac.bind(this)} />
                                <InputText title={'BEI'} type={'file'} onDone={getbei.bind(this)} />
                            </div>

                            <button type="submit" class="btn form-control" style={{ backgroundColor: Colors.primary, color: Colors.white }}>Enregistrer</button>
                        </form>
                    </div>
                </div>

            </div>
        </div>

    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    componentState: state.componentState,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProceduresBailleurs)