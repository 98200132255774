import React from 'react';

const DropdownList = ({ title, data, ...otherProps }) => {

    return (

        <div class="form-group col-md-6">
            <label for={title} class="col-form-label">{title}</label>
            <select class="form-control"  {...otherProps}>
                {data.map(e => (
                    <option key={e.id} value={e.name}>
                        {e.name}
                    </option>
                ))}

            </select>

        </div>

    );

}
export default DropdownList;
