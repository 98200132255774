import React, { Component } from 'react';
import { BrowserRouter as Router, Route, NavLink, Switch } from 'react-router-dom';
import NotePresentationPpm from './NotePresentationPpm';
import PpmValide from './PpmValide';
import PpmMisaJour from './PpmMisaJour';
import { Colors } from '../../../../../utils';

export default class Ppm extends Component {


    render() {

        const { path } = this.props.match;

        return (

            <Router>

                <div style={{ paddingTop: 15 }}>
                    <ul class="nav nav-tabs" role="tablist" style={{ backgroundColor: Colors.primary }}>
                        <li role="presentation" class="active"><NavLink to={`${path}`}
                            activeStyle={{ background: Colors.white, color: Colors.primary, borderBottom: '2px solid #4ecdc4' }}>Note de présentation du PPM</NavLink></li>
                        <li role="presentation"><NavLink to={`${path}/ppmValide`} style={{ color: Colors.white }}
                            activeStyle={{ background: Colors.white, color: Colors.primary, borderBottom: '2px solid #4ecdc4' }}>PPM validé</NavLink></li>
                        <li role="presentation"><NavLink to={`${path}/ppmMisajour`} style={{ color: Colors.white }}
                            activeStyle={{ background: Colors.white, color: Colors.primary, borderBottom: '2px solid #4ecdc4' }}>PPM mis à jour</NavLink></li>
                    </ul>
                    <div class="tab-content">
                        <Switch>
                            <Route path={`${path}`} exact component={NotePresentationPpm} />
                            <Route path={`${path}/ppmValide`} component={PpmValide} />
                            <Route path={`${path}/ppmMisajour`} component={PpmMisaJour} />
                        </Switch>
                    </div>
                </div>

            </Router>

        );
    }
}