import React, { useEffect } from 'react';
import { closeAddResilieModal } from '../actions';
import { connect } from 'react-redux';
import { Colors } from '../../../utils';
import { InputText } from '../../../components';
import AddResilie from '../forms/AddResilie';


const AddResilieModal = (props) => {

    useEffect(() => {
        
        return ()=> {
            props.dispatch(closeAddResilieModal())
        }
    }, []);

    switch (props.executionState.addResilieModal) {
        case true:
            document.getElementById('openAddResilieModalBtn').click()
            break;

        default:
            break;
    }

    return (
        <div>
            <button id="openAddResilieModalBtn" data-toggle="modal" data-target="#addResilieModal" hidden></button>

            <div class="modal fade" id="addResilieModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h2 class="modal-title" id="exampleModalLabel">Enregistrement d'un résilié <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <h5 style={{ color: Colors.white }}>X</h5>
                            </button></h2>

                        </div>

                        <div class="modal-body">
                        <AddResilie />
                        </div>

                        <div class="modal-footer">
                            <button type="button" id='ww' class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    executionState: state.executionState,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddResilieModal)