import React, { useState, useEffect } from 'react';
import { InputText, TopButton, InputTextWithDate, PeriodSelector } from '../../../components';
import { Colors, otherUtils } from '../../../utils';
import { connect } from 'react-redux';
import { saveExecution } from '../actions';
import { closeNotificationModal, openNotificationModal, closeSaveModal } from '../../../components/actions';


const Form = (props) => {

    useEffect(() => {
        return () => {
            props.dispatch(closeNotificationModal())
            props.dispatch(closeSaveModal())
        }
    }, []);

    const [referencecontrat, setReferencecontrat] = useState('');
    const [attributaire, setAttributaire] = useState('');
    const [montant, setMontant] = useState('');
    const [notificationcontrat, setNotificationcontrat] = useState([]);
    const [notificationcontratTitle, setNotificationcontratTitle] = useState('');
    const [osdemarrage, setOsdemarrage] = useState([]);
    const [osdemarrageTitle, setOsdemarrageTitle] = useState('');
    const [delaisexecution, setDelaisexecution] = useState('');
    const [delaisexecutiontype, setDelaisexecutiontype] = useState('Jours');
    const [cautionnementdefinitif, setCautionnementdefinitif] = useState([]);
    const [cautionnementdefinitifTitle, setCautionnementdefinitifTitle] = useState('');
    const [assurance, setAssurance] = useState([]);
    const [assuranceTitle, setAssuranceTitle] = useState('');
    const [retenuegarantie, setRetenuegarantie] = useState([]);
    const [retenuegarantieTitle, setRetenuegarantieTitle] = useState('');
    const [contratenregistre, setContratenregistre] = useState([]);
    const [contratenregistreTitle, setContratenregistreTitle] = useState('');
    const [rapportexecution, setRapportexecution] = useState([]);
    const [rapportexecutionTitle, setRapportexecutionTitle] = useState('');
    const [facturesdecomptes, setFacturesdecomptes] = useState([]);
    const [facturesdecomptesTitle, setFacturesdecomptesTitle] = useState('');
    const [pvreceptionprovisoire, setPvreceptionprovisoire] = useState([]);
    const [pvreceptionprovisoireTitle, setPvreceptionprovisoireTitle] = useState('');
    const [pvreceptiondefinitive, setPvreceptiondefinitive] = useState([]);
    const [pvreceptiondefinitiveTitle, setPvreceptiondefinitiveTitle] = useState('');
    const [penalitesretard, setPenalitesretard] = useState('');

    const handleSelectChange = e => {
        switch (e.target.id) {
            case 'delaisexecutiontype':
                setDelaisexecutiontype(e.target.value)
                break;
        }
    }

    const handleInputChange = e => {
        switch (e.target.id) {

            case 'referencecontrat':
                setReferencecontrat(e.target.value)
                break;

            case 'attributaire':
                setAttributaire(e.target.value)
                break;

            case 'montant':
                setMontant(e.target.value)
                break;

            case 'delaisexecution':
                setDelaisexecution(e.target.value)
                break;

            case 'penalitesretard':
                setPenalitesretard(e.target.value)
                break;

            case 'notificationcontrattitle':
                setNotificationcontratTitle(e.target.value)
                break;

            case 'osdemarragetitle':
                setOsdemarrageTitle(e.target.value)
                break;

            case 'contratenregistretitle':
                setContratenregistreTitle(e.target.value)
                break;

            case 'facturesdecomptestitle':
                setFacturesdecomptesTitle(e.target.value)
                break;

            default:
                break;
        }

    }

    const getNotificationcontrat = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setNotificationcontrat(files)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getOsdemarrage = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setOsdemarrage(files)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getCautionnementdefinitif = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setCautionnementdefinitif(files)
            setCautionnementdefinitifTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getAssurance = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setAssurance(files)
            setAssuranceTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getRetenuegarantie = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setRetenuegarantie(files)
            setRetenuegarantieTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getContratenregistre = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setContratenregistre(files)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getRapportexecution = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setRapportexecution(files)
            setRapportexecutionTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getFacturesdecomptes = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setFacturesdecomptes(files)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getPvreceptionprovisoire = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setPvreceptionprovisoire(files)
            setPvreceptionprovisoireTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getPvreceptiondefinitive = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setPvreceptiondefinitive(files)
            setPvreceptiondefinitiveTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const handleSubmit = e => {

        e.preventDefault();

        if (referencecontrat !== '' && attributaire !== '' && montant !== '' &&
            notificationcontrat !== [] && osdemarrage !== [] && delaisexecution !== '' &&
            cautionnementdefinitif !== [] && assurance !== [] && retenuegarantie !== [] &&
            contratenregistre !== [] && rapportexecution !== [] && facturesdecomptes !== [] &&
            pvreceptionprovisoire !== [] && pvreceptiondefinitive !== [] && penalitesretard !== '') {

            let execution = {
                referencecontrat, attributaire, montant,
                notificationcontrat: notificationcontrat.base64, notificationcontratTitle,
                osdemarrage: osdemarrage.base64, osdemarrageTitle, delaisexecution, delaisexecutiontype,
                cautionnementdefinitif: cautionnementdefinitif.base64, cautionnementdefinitifTitle,
                assurance: assurance.base64, assuranceTitle, retenuegarantie: retenuegarantie.base64,
                retenuegarantieTitle, contratenregistre: contratenregistre.base64, contratenregistreTitle,
                rapportexecution: rapportexecution.base64, rapportexecutionTitle,
                facturesdecomptes: facturesdecomptes.base64, facturesdecomptesTitle,
                pvreceptionprovisoire: pvreceptionprovisoire.base64, pvreceptionprovisoireTitle,
                pvreceptiondefinitive: pvreceptiondefinitive.base64, pvreceptiondefinitiveTitle, penalitesretard
            }

            props.dispatch(saveExecution(execution))
        } else {
            props.dispatch(openNotificationModal('Veuillez remplir tout les champs !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    return (

        <section id="main-content">
            <section class="wrapper site-min-height">
                {/* <h3><TopButton route={'/home/execution'} /> Ajouter une Exécution</h3> */}
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <h3><i className='fa fa-plus'></i> Ajouter une Exécution</h3>
                    <h3><TopButton route='/home/execution/details' title='Liste des Exécutions' icon='list' /> </h3>
                </div>
                <div class="row mt">
                    <div class="col-lg-12">

                        <div class="content-panel" style={{ backgroundColor: '#f2f2f2' }}>
                            <div class="panel-body">
                                <form onSubmit={handleSubmit}>
                                    <div class="form-row">
                                        <InputText title={'Référence du contrat'} id="referencecontrat" onChange={handleInputChange} value={referencecontrat} />
                                    </div>

                                    <div class="form-row">
                                        <InputText title={"Attributaire"} id="attributaire" onChange={handleInputChange} value={attributaire} />
                                        <InputText title={'Montant'} id="montant" type={'number'} onChange={handleInputChange} value={montant} />
                                    </div>

                                    <div class="form-row">
                                        <InputTextWithDate title={'Notification du contrat'} id='notificationcontrattitle' onChange={handleInputChange} value={notificationcontratTitle} onDone={getNotificationcontrat.bind(this)} />
                                        <InputTextWithDate title={'OS de démarrage'} id='osdemarragetitle' onChange={handleInputChange} value={osdemarrageTitle} onDone={getOsdemarrage.bind(this)} />
                                    </div>

                                    <div class="form-row">
                                        <InputText title={'Cautionnement définitif'} type={'file'} onDone={getCautionnementdefinitif.bind(this)} />
                                        <PeriodSelector title={"Délais d'exécution"} id="delaisexecution" selectOnChange={handleSelectChange} onChange={handleInputChange} value={delaisexecution} selectId='delaisexecutiontype' selectValue={delaisexecutiontype} />
                                    </div>

                                    <div class="form-row">
                                        <InputText title={'Assurance'} type={'file'} onDone={getAssurance.bind(this)} />
                                        <InputText title={'Retenue de garantie'} type={'file'} onDone={getRetenuegarantie.bind(this)} />
                                    </div>

                                    <div class="form-row">
                                        <InputTextWithDate title={'Contrat enregistré'} id='contratenregistretitle' onChange={handleInputChange} value={contratenregistreTitle} onDone={getContratenregistre.bind(this)} />
                                        <InputText title={"Rapport d'exécution"} type={'file'} onDone={getRapportexecution.bind(this)} />
                                    </div>

                                    <div class="form-row">
                                        <InputTextWithDate title={'Factures / décomptes'} id='facturesdecomptestitle' onChange={handleInputChange} value={facturesdecomptesTitle} type={'number'} onDone={getFacturesdecomptes.bind(this)} />
                                        <InputText title={'PV de réception provisoir'} type={'file'} onDone={getPvreceptionprovisoire.bind(this)} />
                                    </div>

                                    <div class="form-row">
                                        <InputText title={'PV réception définitive'} type={'file'} onDone={getPvreceptiondefinitive.bind(this)} />
                                        <InputText title={'Pénalités de retard'} id="penalitesretard" onChange={handleInputChange} value={penalitesretard} />
                                    </div>

                                    <button type="submit" class="btn form-control" style={{ backgroundColor: Colors.primary, color: Colors.white }}>Enregistrer</button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </section>
    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    componentState: state.componentState,
});

export default connect(mapStateToProps, mapDispatchToProps)(Form)