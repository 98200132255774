import { takeLatest } from 'redux-saga/effects';
import * as workers from './executionWorker';
import constants from '../constants';

export function* watchGetExercice() {
    yield takeLatest(constants.GET_EXECUTION, workers.getExecutionWorker);
}

export function* watchSaveExercice() {
    yield takeLatest(constants.SAVE_EXECUTION, workers.saveExecutionWorker);
}

export function* watchSaveAvenant() {
    yield takeLatest(constants.SAVE_AVENANT_EXECUTION, workers.saveAvenantExecutionWorker);
}

export function* watchGetAvenant() {
    yield takeLatest(constants.GET_AVENANT_EXECUTION, workers.getAvenantExecutionWorker);
}

export function* watchSaveResilie() {
    yield takeLatest(constants.SAVE_RESILIE, workers.saveResilieWorker);
}

export function* watchGetResilie() {
    yield takeLatest(constants.GET_RESILIE, workers.getResilieWorker);
}
