import React from 'react';
import { Colors } from '../utils';

const BackButton = ({ title, icon, action, btn_style, ...other_props }) => {

    const styles = {
        btn: {
            backgroundColor: Colors.primary,
            color: Colors.white,
            marginRight: 10,
            ...btn_style
        }
    }

    return (
        <button className="btn" {...other_props}
            style={styles.btn}
            onClick={action} > <i class={`fa fa-${icon}`}></i>  {title || 'Title'}</button>
    );

}

export default BackButton;