import React, { useEffect } from 'react';
import { TableHead, GroupedButton, EmptyList, SearchBar, Loader } from '../../../../components';
import { connect } from 'react-redux';
import { closePreviewModal } from '../../../../components/actions';
import * as actions from '../../actions';
import { Colors } from '../../../../utils';

const Nationales = (props) => {

    useEffect(() => {
        props.dispatch(actions.getNationale());
        return () => {
            props.dispatch(closePreviewModal())
        }
    }, []);

    return (

        <div class="adv-table" style={{ paddingTop: 15 }}>

            {props.componentState.loader ? <Loader /> :

                <div>

                    {props.passationState.nationales.length !== 0 ?

                        <div>

                            <SearchBar />

                            <div style={{ overflowX: 'auto' }}>

                                <table cellpadding="0" cellspacing="0" border="0" class="display table table-striped table-hover" id="table">

                                    <TableHead data={['Nº', "Lois", 'Décrets', "Résolution du Conseil d'Administration (EDC)",
                                        'Arrêtés', 'Circulaires', 'DAO Types mis en forme (EDC)', '', '']} />

                                    <tbody>
                                        {props.passationState.nationales.map((e, index) => (

                                            <tr key={e.id}>
                                                <td>{index + 1}</td>
                                                <td><GroupedButton pdf={e.lois} slice={true} name={e.loistitle} /></td>
                                                <td><GroupedButton pdf={e.decrets} slice={true} name={e.decretstitle} /></td>
                                                <td><GroupedButton pdf={e.resolutionca} slice={true} name={e.resolutioncatitle} /></td>
                                                <td><GroupedButton pdf={e.arretes} slice={true} name={e.arretestitle} /></td>
                                                <td><GroupedButton pdf={e.circulaires} slice={true} name={e.circulairestitle} /></td>
                                                <td><GroupedButton pdf={e.daotypesmisenforme} slice={true} name={e.daotypesmisenformetitle} /></td>
                                                <td><button className="btn btn-primary btn-sm" ><i class="fa fa-edit" ></i></button></td>
                                                <td><button className="btn btn-danger btn-sm" ><i class="fa fa-trash" ></i></button></td>

                                            </tr>
                                        ))}

                                    </tbody>

                                </table> </div>
                        </div>

                        : <EmptyList />}

                </div>}

        </div>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    passationState: state.passationState,
    componentState: state.componentState
});

export default connect(mapStateToProps, mapDispatchToProps)(Nationales)