import React from 'react';
import { connect } from 'react-redux';
import { GroupedButton, TableHead, EmptyList } from '../../../components';


const Avenant = (props) => {

    return (

        <div class="adv-table" style={{ paddingTop: 15, overflowX: 'auto' }}>

            {props.executionState.avenants.length !== 0 ?

                <table cellpadding="0" cellspacing="0" border="0" class="display table table-striped table-hover" id="hidden-table-info">

                    <TableHead data={['Nº', "Référence de l'avenant", 'Objet', 'Titulaire', "Montant de l'avenant",
                        "Notification de l'avenant", "OS d'exécution", "Délais d'exécution", 'Avenant enregistré', '', '']} />

                    <tbody>
                        {props.executionState.avenants.map((e, index) => (
                            <tr key={e.id}>
                                <td>{index + 1}</td>
                                <td>{e.referenceavenant}</td>
                                <td>{e.objet}</td>
                                <td>{e.titulaire}</td>
                                <td>{e.montantavenant}</td>
                                <td><GroupedButton pdf={e.notificationavenant} slice={true} name={e.notificationavenanttitle} /></td>
                                <td><GroupedButton pdf={e.osexecution} slice={true} name={e.osexecutiontitle} /></td>
                                <td>{`${e.delaisexecution} ${e.delaisexecutiontype}`}</td>
                                <td><GroupedButton pdf={e.avenantenregistre} slice={true} name={e.avenantenregistretitle} /></td>
                                <td><button className="btn btn-primary btn-sm" ><i class="fa fa-edit" ></i></button></td>
                                <td><button className="btn btn-danger btn-sm" ><i class="fa fa-trash" ></i></button></td>

                            </tr>
                        ))}
                    </tbody>

                </table> : <EmptyList />}

        </div>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    executionState: state.executionState
});

export default connect(mapStateToProps, mapDispatchToProps)(Avenant)