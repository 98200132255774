import React, { useEffect } from 'react';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import { GroupedButton, TableHead, EmptyList } from '../../../../components';

const Recours = (props) => {
    
    return (

        <div class="adv-table" style={{ paddingTop: 15, overflowX: 'auto' }}>

            {props.passationState.recours.length !== 0 ?
        
                <table cellpadding="0" cellspacing="0" border="0" class="display table table-striped table-hover" id="hidden-table-info">
               
               <TableHead data={['Nº', 'Recours', '', '']} />
                    <tbody>
                    {props.passationState.recours.map((e, index) => (
                                <tr key={e.id}>
                                    <td>{index + 1}</td>                                    
                                    <td><GroupedButton pdf={e.recours} slice={true} name={e.recourstitle} /></td>
                                    <td><button className="btn btn-primary btn-sm" ><i class="fa fa-edit" ></i></button></td>
                                    <td><button className="btn btn-danger btn-sm" ><i class="fa fa-trash" ></i></button></td>

                                </tr>
                            ))}
                    </tbody>

                    </table> : <EmptyList /> }               

        </div>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    passationState: state.passationState,
    componentState: state.componentState
});

export default connect(mapStateToProps, mapDispatchToProps)(Recours)