import React from 'react';
import { Link } from 'react-router-dom';
import { styles } from '../style';

const SignIn = (props) => {

  return (

    <body style={styles.body}>
      <img style={styles.login_lima} src="/img/login-lima.png" alt="" />
      <div>
        <img style={styles.illustration_1} src="/img/illustration-1.png" alt="" />
        <img style={styles.illustration_2} src="/img/illustration-2.png" alt="" />
        <img style={styles.illustration_3} src="/img/illustration-3.png" alt="" />
        <img style={styles.illustration_4} src="/img/illustration-4.png" alt="" />
      </div>
      <img style={styles.login_shadow} src="/img/login-shadow.png" alt="" />
      <div style={styles.loginbox}>
        <header style={styles.header}>
          <img style={styles.header_img} src="/img/lock-icon-larger.png" alt="" />
          <h3>SE CONNECTER</h3>
        </header>
        <form>
          
            <input style={styles.input} type="text" required placeholder="Nom d'utilisateur" />
                   
            <input
              type="password"
              required
              style={styles.input}
              placeholder="Mot de passe"
            />
          
          <div style={styles.remember_me}><input type="checkbox" /> Se souvenir de moi</div>
          <div >
            <Link to={'/home'}>
              <input style={styles.button} type="submit" value="Connexion" />
            </Link>
          </div>
        </form>
      </div>

    </body>

  );
}


export default SignIn;