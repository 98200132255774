import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import Ppm from './ppm';
import ConsultationsLancees from './ConsultationsLancees';
import SuiviProcessusPassation from './SuiviProcessusPassation';
import GenerateurDetat from './GenerateurDetat';
import { Colors } from '../../../../utils';
import { TopButton, ExerciceYearPicker } from '../../../../components';
import { connect } from 'react-redux';


const Exercice = (props) => {

    const { path } = props.match;

    return (

        <section id="main-content">
            <section class="wrapper site-min-height">               
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <h3><i className='fa fa-plus'></i> Ajouter un Exercice</h3>
                        <ExerciceYearPicker />
                        <h3><TopButton route='/home/passation/exercice/details' title='Liste des Exercices' icon='list' /> </h3>
                    </div>
                <div class="row mt">
                    <div class="col-lg-12">

                        <div class="content-panel" >
                            <div class="panel-body">
                                <Router>

                                    <div>
                                        <ul class="nav nav-tabs" role="tablist" style={{ backgroundColor: Colors.primary }}>
                                            <li role="presentation" class="active"><NavLink to={`${path}`}
                                                activeStyle={{ background: Colors.white, color: Colors.primary, borderBottom: '2px solid #4ecdc4' }}>PPM</NavLink></li>
                                            <li role="presentation"><NavLink to={`${path}/ConsultationsLancees`} style={{ color: Colors.white }}
                                                activeStyle={{ background: Colors.white, color: Colors.primary, borderBottom: '2px solid #4ecdc4' }}>Consultations Lancées</NavLink></li>
                                            <li role="presentation"><NavLink to={`${path}/SuiviProcessusPassation`} style={{ color: Colors.white }}
                                                activeStyle={{ background: Colors.white, color: Colors.primary, borderBottom: '2px solid #4ecdc4' }}>Suivi du processus de la passation</NavLink></li>
                                            {/* <li role="presentation"><NavLink to={`${path}/GenerateurDetat`} style={{ color: Colors.white }}
                                                activeStyle={{ background: Colors.white, color: Colors.primary, borderBottom: '2px solid #4ecdc4' }}>Générateur D'état</NavLink></li> */}
                                        </ul>
                                        <div class="tab-content">
                                            <Switch>
                                                <Route path={`${path}`} exact component={Ppm} />
                                                <Route path={`${path}/ConsultationsLancees`} component={ConsultationsLancees} />
                                                <Route path={`${path}/SuiviProcessusPassation`} component={SuiviProcessusPassation} />
                                                {/* <Route path={`${path}/GenerateurDetat`} component={GenerateurDetat} /> */}
                                            </Switch>
                                        </div>
                                    </div>

                                </Router>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </section>

    );
}

const mapStateToProps = state => ({
    componentState: state.componentState,
});

export default connect(mapStateToProps)(Exercice)