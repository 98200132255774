import React, { useEffect } from 'react';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import { TableHead, EmptyList, SearchBar, Loader } from '../../../../components';
import { Colors } from '../../../../utils';
import { openConfirmationModal } from '../../../../components/actions';

const ConsultationsLancees = (props) => {

    useEffect(() => {
        props.dispatch(actions.getConsultationLancees(props.componentState.exerciceYear));
    }, []);

    return (

        <div>

            {props.componentState.loader ? <Loader /> :

                <div class="adv-table" style={{ paddingTop: 15 }}>

                    {props.passationState.consultationLancees.length !== 0 ?

                        <div>

                            <SearchBar />

                            <div style={{ overflowX: 'auto' }}>

                                <table cellpadding="0" cellspacing="0" border="0" class="display table table-striped table-hover" id="hidden-table-info">

                                    <TableHead data={['Nº', 'Intitulé', "Type d'appel d'offres", 'Localisation du projet',
                                        'Montant prévisionnel', 'Source de financement', 'Imputation budgétaire', 'Validation du dossier (CIPM)',
                                        'ANO Bailleur', 'Publication JDM', 'Remise des offres', 'Ouverture des plis', 'Additif',
                                        "Délais d'exécution", '', '']} />

                                    <tbody>
                                        {props.passationState.consultationLancees.map((e, index) => (
                                            <tr key={e.id}>
                                                <td>{index + 1}</td>
                                                <td>{e.intituleprojet}</td>
                                                <td>{e.typeappeloffre}</td>
                                                <td>{e.localisationprojet}</td>
                                                <td>{e.montantprevisionel}</td>
                                                <td>{e.sourcefinancement}</td>
                                                <td>{e.imputationbudgetaire}</td>
                                                <td>{e.validationdossiercipm}</td>
                                                <td>{e.anobailleur}</td>
                                                <td>{e.oublicationjdm}</td>
                                                <td>{e.remiseoffres}</td>
                                                <td>{e.overturesplis}</td>
                                                <td>{e.additif}</td>
                                                <td>{`${e.delaisexecution} ${e.delaisexecutiontype}`}</td>
                                                <td><button className="btn btn-primary btn-sm" ><i class="fa fa-edit" ></i></button></td>
                                                <td><button className="btn btn-danger btn-sm"><i class="fa fa-trash" ></i></button></td>

                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        : <EmptyList />}
                </div>}
        </div>
        // onClick={()=>props.dispatch(openConfirmationModal('Voulez-vous vraiment supprimer cet élément ?',
        // 'trash', Colors.firebrick, actions.deleteConsultationLancee(e.id)))}
    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    passationState: state.passationState,
    componentState: state.componentState
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationsLancees)