import { call, put } from 'redux-saga/effects';
import * as apis from '../apis';
import * as actions from '../actions';
import * as componentActions from '../../../components/actions';
import { Colors } from '../../../utils';

export function* getAuditWorker() {

  try {

    yield put(componentActions.showLoader());

    const audit = yield call(apis.getAudit);

    if (audit.status != 200) {      
      yield put(componentActions.openNotificationModal('Une erreur est survenu !',
        'exclamation-triangle', Colors.firebrick, false));
    } else {
      console.log(audit.data)
      yield put(actions.getAuditSuccess(audit.data));
      
    }

  } catch (error) {
    console.log(error)
  } finally {
    yield put(componentActions.hideLoader());
  }
}

export function* saveAuditWorker(payload) {

  try {

    yield put(componentActions.openSaveModal());

    let result = yield call(apis.saveAudit, payload.audit);

    if (result.status != 201) {
      yield put(componentActions.openNotificationModal('Une erreur est survenu !', 
      'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(componentActions.openNotificationModal('Enregistré avec success !', 
      'check-circle', Colors.primary, false));
    }

  } catch (error) {
    console.log(error)
  } 
}

export function* deleteAuditWorker(payload) {

  try {

    yield put(componentActions.closeConfirmationModal());
    document.getElementById('closeConfirmationModal').click()

    let result = yield call(apis.deleteAudit, payload.id);

    if (result.status != 200) {
      yield put(componentActions.openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(actions.getAudit());
      yield put(componentActions.openNotificationModal('Supprimé avec success !', 'check-circle', Colors.primary, false));
    }

  } catch (error) {
    console.log(error)
  }
}
