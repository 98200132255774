import React, { useState, useEffect } from 'react';
import { InputText, InputTextWithDate } from '../../../../components';
import { Colors, otherUtils } from '../../../../utils';
import { connect } from 'react-redux';
import { saveSuiviProcessusPassation } from '../../actions';
import { openNotificationModal, closeNotificationModal, closeSaveModal } from '../../../../components/actions';


const SuiviProcessusPassation = (props) => {

    useEffect(() => {
        return () => {
            props.dispatch(closeNotificationModal())
            props.dispatch(closeSaveModal())
        }
    }, []);

    const [intituleprojet, setIntituleprojet] = useState([]);
    const [intituleprojetTitle, setIntituleprojetTitle] = useState('');
    const [sourcefinancement, setSourcefinancement] = useState('');
    const [imputationbudgetaire, setImputationbudgetaire] = useState([]);
    const [imputationbudgetaireTitle, setImputationbudgetaireTitle] = useState('');
    const [saisineprevisionellecipm, setSaisineprevisionellecipm] = useState('');
    const [saisinecipm, setSaisinecipm] = useState([]);
    const [saisinecipmTitle, setSaisinecipmTitle] = useState('');
    const [examendossier, setExamendossier] = useState('');
    const [validationdossier, setValidationdossier] = useState([]);
    const [validationdossierTitle, setValidationdossierTitle] = useState('');
    const [anobailleur1, setAnobailleur1] = useState([]);
    const [anobailleur1Title, setAnobailleur1Title] = useState('');
    const [publicationjdm, setPublicationjdm] = useState([]);
    const [publicationjdmTitle, setPublicationjdmTitle] = useState('');
    const [additif, setAdditif] = useState([]);
    const [additifTitle, setAdditifTitle] = useState('');
    const [ouverturesoffresadministratifs, setOuverturesoffresadministratifs] = useState([]);
    const [ouverturesoffresadministratifsTitle, setOuverturesoffresadministratifsTitle] = useState('');
    const [validationrapportevaluation, setValidationrapportevaluation] = useState([]);
    const [validationrapportevaluationTitle, setValidationrapportevaluationTitle] = useState('');
    const [anobailleur2, setAnobailleur2] = useState([]);
    const [anobailleur2Title, setAnobailleur2Title] = useState('');
    const [ouverturesoffresfinanciere, setOuverturesoffresfinanciere] = useState([]);
    const [ouverturesoffresfinanciereTitle, setOuverturesoffresfinanciereTitle] = useState('');
    const [infructueux, setInfructueux] = useState([]);
    const [infructueuxTitle, setInfructueuxTitle] = useState('');
    const [propositionsattributionscipm, setPropositionsattributionscipm] = useState([]);
    const [propositionsattributionscipmTitle, setPropositionsattributionscipmTitle] = useState('');
    const [anobailleur3, setAnobailleur3] = useState([]);
    const [anobailleur3Title, setAnobailleur3Title] = useState('');
    const [publicationdecisioncommuniquer, setPublicationdecisioncommuniquer] = useState([]);
    const [publicationdecisioncommuniquerTitle, setPublicationdecisioncommuniquerTitle] = useState('');
    const [notificationattribution, setNotificationattribution] = useState([]);
    const [notificationattributionTitle, setNotificationattributionTitle] = useState('');
    const [titulaire, setTitulaire] = useState('');
    const [montantttccfa, setMontantttccfa] = useState('');
    const [souscriptionprojetmarche, setSouscriptionprojetmarche] = useState('');
    const [saisinecipmexamenprojet, setSaisinecipmexamenprojet] = useState([]);
    const [saisinecipmexamenprojetTitle, setSaisinecipmexamenprojetTitle] = useState('');
    const [examenprojetmarche, setExamenprojetmarche] = useState('');
    const [validation, setValidation] = useState([]);
    const [validationTitle, setValidationTitle] = useState('');
    const [anobailleur4, setAnobailleur4] = useState([]);
    const [anobailleur4Title, setAnobailleur4Title] = useState('');
    const [signaturemarche, setSignaturemarche] = useState('');


    const handleInputChange = e => {
        switch (e.target.id) {

            case 'sourcefinancement':
                setSourcefinancement(e.target.value)
                break;
            case 'saisineprevisionellecipm':
                setSaisineprevisionellecipm(e.target.value)
                break;
            case 'saisinecipmtitle':
                setSaisinecipmTitle(e.target.value)
                break;
            case 'examendossier':
                setExamendossier(e.target.value)
                break;
            case 'titulaire':
                setTitulaire(e.target.value)
                break;
            case 'montantttccfa':
                setMontantttccfa(e.target.value)
                break;
            case 'souscriptionprojetmarche':
                setSouscriptionprojetmarche(e.target.value)
                break;
            case 'examenprojetmarche':
                setExamenprojetmarche(e.target.value)
                break;
            case 'signaturemarche':
                setSignaturemarche(e.target.value)
                break;
            case 'validationdossiertitle':
                setValidationdossierTitle(e.target.value)
                break;
            case 'ouverturesoffresadministratifstitle':
                setOuverturesoffresadministratifsTitle(e.target.value)
                break;
            case 'ouverturesoffresfinancieretitle':
                setOuverturesoffresfinanciereTitle(e.target.value)
                break;
            case 'propositionsattributionscipmtitle':
                setPropositionsattributionscipmTitle(e.target.value)
                break;
            case 'saisinecipmexamenprojettitle':
                setSaisinecipmexamenprojetTitle(e.target.value)
                break;
            case 'validationtitle':
                setValidationTitle(e.target.value)
                break;

            default:
                break;
        }

    }

    const getIntituleprojet = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setIntituleprojet(files)
            setIntituleprojetTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getImputationbudgetaire = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setImputationbudgetaire(files)
            setImputationbudgetaireTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getValidationdossier = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setValidationdossier(files)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getAnobailleur1 = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setAnobailleur1(files)
            setAnobailleur1Title(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getPublicationjdm = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setPublicationjdm(files)
            setPublicationjdmTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getAdditif = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setAdditif(files)
            setAdditifTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getOuverturesoffresadministratifs = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setOuverturesoffresadministratifs(files)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getValidationrapportevaluation = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setValidationrapportevaluation(files)
            setValidationrapportevaluationTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getAnobailleur2 = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setAnobailleur2(files)
            setAnobailleur2Title(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getOuverturesoffresfinanciere = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setOuverturesoffresfinanciere(files)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getInfructueux = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setInfructueux(files)
            setInfructueuxTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getSaisinecipm = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setSaisinecipm(files)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getPropositionsattributionscipm = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setPropositionsattributionscipm(files)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getAnobailleur3 = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setAnobailleur3(files)
            setAnobailleur3Title(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getPublicationdecisioncommuniquer = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setPublicationdecisioncommuniquer(files)
            setPublicationdecisioncommuniquerTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getNotificationattribution = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setNotificationattribution(files)
            setNotificationattributionTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getSaisinecipmexamenprojet = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setSaisinecipmexamenprojet(files)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getValidation = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setValidation(files)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getAnobailleur4 = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setAnobailleur4(files)
            setAnobailleur4Title(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const handleSubmit = e => {

        e.preventDefault();
        if (intituleprojet !== [] && sourcefinancement !== '' &&
            imputationbudgetaire !== [] && saisineprevisionellecipm !== '' &&
            saisinecipm !== [] && examendossier !== '' &&
            validationdossier !== [] && anobailleur1 !== [] &&
            publicationjdm !== [] &&
            ouverturesoffresadministratifs !== [] && validationrapportevaluation !== [] &&
            anobailleur2 !== [] && ouverturesoffresfinanciere !== [] &&
            propositionsattributionscipm !== [] &&
            anobailleur3 !== [] && publicationdecisioncommuniquer !== [] &&
            notificationattribution !== [] && titulaire !== '' &&
            montantttccfa !== '' && souscriptionprojetmarche !== '' &&
            saisinecipmexamenprojet !== [] && examenprojetmarche !== '' &&
            validation !== [] && anobailleur4 !== [] &&
            signaturemarche !== '') {

            let suiviProcessusPassation = {
                intituleprojet: intituleprojet.base64, intituleprojetTitle,
                sourcefinancement,
                imputationbudgetaire: imputationbudgetaire.base64, imputationbudgetaireTitle,
                saisineprevisionellecipm,
                saisinecipm: saisinecipm.base64, saisinecipmTitle, examendossier,
                validationdossier: validationdossier.base64, validationdossierTitle,
                anobailleur1: anobailleur1.base64, anobailleur1Title,
                publicationjdm: publicationjdm.base64, publicationjdmTitle,
                additif: additif.base64, additifTitle,
                ouverturesoffresadministratifs: ouverturesoffresadministratifs.base64, ouverturesoffresadministratifsTitle,
                validationrapportevaluation: validationrapportevaluation.base64, validationrapportevaluationTitle,
                anobailleur2: anobailleur2.base64, anobailleur2Title,
                ouverturesoffresfinanciere: ouverturesoffresfinanciere.base64, ouverturesoffresfinanciereTitle,
                infructueux: infructueux.base64, infructueuxTitle,
                propositionsattributionscipm: propositionsattributionscipm.base64, propositionsattributionscipmTitle,
                anobailleur3: anobailleur3.base64, anobailleur3Title,
                publicationdecisioncommuniquer: publicationdecisioncommuniquer.base64, publicationdecisioncommuniquerTitle,
                notificationattribution: notificationattribution.base64, notificationattributionTitle,
                titulaire,
                montantttccfa, souscriptionprojetmarche,
                saisinecipmexamenprojet: saisinecipmexamenprojet.base64, saisinecipmexamenprojetTitle,
                examenprojetmarche,
                validation: validation.base64, validationTitle,
                anobailleur4: anobailleur4.base64, anobailleur4Title,
                signaturemarche, exercice: props.componentState.exerciceYear
            }

            props.dispatch(saveSuiviProcessusPassation(suiviProcessusPassation))

        } else {
            props.dispatch(openNotificationModal('Veuillez remplir tout les champs !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    return (

        <div class="row mt">
            <div class="col-lg-12">

                <div class="content-panel" style={{ backgroundColor: '#f2f2f2' }}>
                    <div class="panel-body">

                        <form onSubmit={handleSubmit}>
                            <div class="form-row">
                                <InputText title={'Intitule du projet'} type={'file'} onDone={getIntituleprojet.bind(this)} />
                            </div>

                            <div class="form-row">
                                <InputText title={"Source de financement"} id="sourcefinancement" onChange={handleInputChange} value={sourcefinancement} />
                                <InputText title={'Imputation budgétaire'} type={'file'} onDone={getImputationbudgetaire.bind(this)} />
                            </div>

                            <div class="form-row">
                                <InputText title={'Saisine prévisionnelle de la CIPM'} type={'date'} id="saisineprevisionellecipm" onChange={handleInputChange} value={saisineprevisionellecipm} />
                                {/* <InputTextWithDate title={'Saisine prévisionnelle de la CIPM'} id='saisineprevisionellecipmtitle' onChange={handleInputChange} value={saisineprevisionelleTitle} onDone={getSaisineprevisionellecipm.bind(this)} /> */}
                                <InputTextWithDate title={'Saisine de la CIPM'} id='saisinecipmtitle' onChange={handleInputChange} value={saisinecipmTitle} onDone={getSaisinecipm.bind(this)} />
                            </div>

                            <div class="form-row">
                                <InputText title={'Examen du dossier'} id="examendossier" type={'date'} onChange={handleInputChange} value={examendossier} />
                                {/* <InputText title={'Validation du dossier'} type={'file'} onDone={getValidationdossier.bind(this)} /> */}
                                <InputTextWithDate title={'Validation du dossier'} id='validationdossiertitle' onChange={handleInputChange} value={validationdossierTitle} onDone={getValidationdossier.bind(this)} />
                            </div>

                            <div class="form-row">
                                <InputText title={'ANO Bailleur'} type={'file'} onDone={getAnobailleur1.bind(this)} />
                                <InputText title={'Publication JDM'} type={'file'} onDone={getPublicationjdm.bind(this)} />
                            </div>

                            <div class="form-row">
                                <InputText title={'Additif'} type={'file'} onDone={getAdditif.bind(this)} />
                                {/* <InputText title={'Ouvertures des offres administratives & techniques'} type={'file'} onDone={getOuverturesoffresadministratifs.bind(this)} /> */}
                                <InputTextWithDate title={'Ouvertures des offres administratives & techniques'} id='ouverturesoffresadministratifstitle' onChange={handleInputChange} value={ouverturesoffresadministratifsTitle} onDone={getOuverturesoffresadministratifs.bind(this)} />
                            </div>

                            <div class="form-row">
                                <InputText title={"Validation du rapport d'évaluation des offres admin..."} type={'file'} onDone={getValidationrapportevaluation.bind(this)} />
                                <InputText title={'ANO Bailleurs'} type={'file'} onDone={getAnobailleur2.bind(this)} />
                            </div>

                            <div class="form-row">
                                <InputTextWithDate title={'Ouvertures des offres financières'} id='ouverturesoffresfinancieretitle' onChange={handleInputChange} value={ouverturesoffresfinanciereTitle} onDone={getOuverturesoffresfinanciere.bind(this)} />
                                {/* <InputText title={"Ouvertures des offres financières"} type={'file'} onDone={getOuverturesoffresfinanciere.bind(this)} /> */}
                                <InputText title={'Infructueux'} type={'file'} onDone={getInfructueux.bind(this)} />
                            </div>

                            <div class="form-row">
                                <InputTextWithDate title={"Proposition d'attribution CIPM"} id='propositionsattributionscipmtitle' onChange={handleInputChange} value={propositionsattributionscipmTitle} onDone={getPropositionsattributionscipm.bind(this)} />
                                {/* <InputText title={"Proposition d'attribution CIPM"} type={'file'} onDone={getPropositionsattributionscipm.bind(this)} /> */}
                                <InputText title={'ANO Bailleurs'} type={'file'} onDone={getAnobailleur3.bind(this)} />
                            </div>

                            <div class="form-row">
                                <InputText title={"Publication décision et communiqué d'attribution"} type={'file'} onDone={getPublicationdecisioncommuniquer.bind(this)} />
                                <InputText title={'Notification attribution'} type={'file'} onDone={getNotificationattribution.bind(this)} />
                            </div>

                            <div class="form-row">
                                <InputText title={"Titulaire"} id="titulaire" onChange={handleInputChange} value={titulaire} />
                                <InputText title={'Montant TTC en FCFA'} type={'number'} id="montantttccfa" onChange={handleInputChange} value={montantttccfa} />
                            </div>

                            <div class="form-row">
                                <InputText title={"Souscription du projet de marché"} type={'date'} id="souscriptionprojetmarche" onChange={handleInputChange} value={souscriptionprojetmarche} />
                                {/* <InputText title={'Saisine CIPM pour examen projet de marché'} type={'file'} onDone={getSaisinecipmexamenprojet.bind(this)} /> */}
                                <InputTextWithDate title={"Saisine CIPM pour examen projet de marché"} id='saisinecipmexamenprojettitle' onChange={handleInputChange} value={saisinecipmexamenprojetTitle} onDone={getSaisinecipmexamenprojet.bind(this)} />
                            </div>

                            <div class="form-row">
                                <InputText title={"Examen projet de marché"} type={'date'} id="examenprojetmarche" onChange={handleInputChange} value={examenprojetmarche} />
                                {/* <InputText title={'Validation'} type={'file'} onDone={getValidation.bind(this)} /> */}
                                <InputTextWithDate title={"Validation"} id='validationtitle' onChange={handleInputChange} value={validationTitle} onDone={getValidation.bind(this)} />
                            </div>

                            <div class="form-row">
                                <InputText title={"ANO Bailleurs"} type={'file'} onDone={getAnobailleur4.bind(this)} />
                                <InputText title={'Signature du marché'} type={'date'} id="signaturemarche" onChange={handleInputChange} value={signaturemarche} />
                            </div>

                            <button type="submit" class="btn form-control" style={{ backgroundColor: Colors.primary, color: Colors.white }}>Enregistrer</button>
                        </form>
                    </div>
                </div>

            </div>
        </div>

    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    componentState: state.componentState,
});

export default connect(mapStateToProps, mapDispatchToProps)(SuiviProcessusPassation)