import React from 'react';

const PeriodSelector = ({ title = 'Title', selectId, selectValue, selectOnChange, ...other_props }) => {

    const styles = {
        container: {
            borderStyle: 'solid',
            borderRadius: 5,
            borderWidth: 1,
            borderColor: '#c2c2c2',
            backgroundColor: '#fff',
            paddingTop: 2,
            paddingBottom: 2,
            marginRight: 2,
            marginLeft: 2
        },
        input: {
            backgroundColor: 'transparent',
            borderStyle: 'none',
            padding: 3
        },
        select: {
            padding: 2,
            backgroundColor: '#f2f2f2'
        }
    }

    return (

        <div class="form-group col-md-6">
            <label for={title} class="col-form-label">{title}</label>
            <div className='row'
                style={styles.container}>
                <input class="col-md-8" type='number' id={title} style={styles.input}
                    placeholder={title} {...other_props} />

                <select class="col-md-4" style={styles.select} id={selectId} value={selectValue} onChange={selectOnChange}>
                    <option>Jours</option>
                    <option>Mois</option>
                    <option>Année(s)</option>
                </select>
            </div>

        </div>
    );
}

export default PeriodSelector;