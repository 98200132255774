import React from 'react';
import { NavLink } from 'react-router-dom';
import { Colors } from '../utils';

const SidebarDropdownButton = ({ heading, headingIcon, title1, title2, route1, route2,
      ...other_props }) => {

    return (

        <li className="sub-menu">
            <a href="javascript:;">
                <i className={`fa fa-${headingIcon}`}></i>
            <span>{heading}</span>
            </a>
            <ul className="sub">
                <li><NavLink to={`/${route1}`} {...other_props}
                    activeStyle={{ background: Colors.primary, color: Colors.white }} >
                    <i className={`fa fa-arrow-right`}></i>
                    <span>{title1 || 'Title 1'} </span>
                </NavLink></li>
                <li><NavLink to={`/${route2}`} {...other_props}
                    activeStyle={{ background: Colors.primary, color: Colors.white }} >
                    <i className={`fa fa-arrow-right`}></i>
                    <span>{title2 || 'Title'} </span>
                </NavLink></li>
            </ul>
        </li>

    );

}

export default SidebarDropdownButton;