import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Colors } from '../../../utils';
import { getAudit, deleteAudit } from '../actions';
import { TopButton, EmptyList, PpmDisplay, TableHead, SearchBar, GroupedButton, Loader } from '../../../components';
import { closePreviewModal } from '../../../components/actions';


const Details = (props) => {

    useEffect(() => {
        props.dispatch(getAudit());
        return () => {
            props.dispatch(closePreviewModal());
        }
    }, []);
    
    return (

        <section id="main-content">
            <section class="wrapper site-min-height">
               
                <div style={{ width: '100%', display:'flex', justifyContent:'space-between' }}>                
                <h3><i className='fa fa-list'></i> Liste des Audits</h3>
                <h3><TopButton route='/home/audit/add' title='Ajouter un Audit' icon='plus' /> </h3>                              
                </div>
               
                <div class="row mt">
                    <div class="col-lg-12">

                        <div class="content-panel" style={{ backgroundColor: '#f2f2f2', padding: 20 }}>
                            <div class="panel-body">

                                <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                                    <h4>Veuillez choisir le type de rapport</h4>
                                    <select className='btn' style={{ width: 200, backgroundColor: Colors.primary, color: Colors.white }}>
                                        <option> Interne </option>
                                        <option> Externe </option>
                                    </select>
                                </div>

                                    {props.componentState.loader ? <Loader /> : 

                                <div style={{ paddingTop: 15 }}>
                                    
                                    {props.auditState.audits.length !== 0 ?
                                       
                                        <div>
                                            <SearchBar />

                                            <div style={{ overflowX: 'auto' }}>

                                            <table cellpadding="0" cellspacing="0" border="0" class="display table table-striped table-hover" id="hidden-table-info">

                                                <TableHead data={['Nº', 'Type de rapport', 'Audit', '', '']} />

                                                <tbody>
                                                    {props.auditState.audits.map((e, index) => (
                                                        <tr key={e.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{e.type}</td>
                                                            <td><GroupedButton pdf={e.audit} slice={true} name={e.audittitle} /></td>
                                                            <td><button className="btn btn-primary btn-sm" ><i class="fa fa-edit" ></i></button></td>
                                                            <td><button className="btn btn-danger btn-sm"><i class="fa fa-trash" ></i></button></td>

                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                            </div>
                                        </div> : <EmptyList /> } 
                                </div> }

                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </section>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    auditState: state.auditState,
    componentState: state.componentState
});

export default connect(mapStateToProps, mapDispatchToProps)(Details)