import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Screens from './screens';
import SignIn from './screens/signin/view/SignIn';


class App extends React.Component {

  componentDidMount() {
    this.loadScript()
  }

  componentDidUpdate() {
    this.loadScript()
  }

  loadScript() {
    let script = document.createElement('script');
    script.src = '/lib/common-scripts.js';
    document.body.appendChild(script);
  }

  //   useEffect(() => {
  //     let script = document.createElement('script');
  //     script.src = '/lib/common-scripts.js';
  //     document.body.appendChild(script);
  // }, []);
  render() {
    return (
      <section id="container">
        <Router>

          <Route path='/' exact component={SignIn} />
          <Route path='/signin' exact component={SignIn} />
          <Route path='/home' component={Screens} />

        </Router>
      </section>
    );
  }
}
export default App;
