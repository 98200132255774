import React, { useEffect, useState } from 'react';
import { saveAvenant, openAddAvenantModal, closeAddAvenantModal, closeAvenantModal } from '../actions';
import { connect } from 'react-redux';
import { Colors } from '../../../utils';
import { InputText } from '../../../components';
import { openNotificationModal } from '../../../components/actions';
import AddAvenant from '../forms/exercice/AddAvenant';


const AddAvenantModal = (props) => {

    useEffect(() => {

    }, []);

    const [designationavenant, setDesignationavenant] = useState('');
    const [objet, setObjet] = useState('');
    const [titulaire, setTitulaire] = useState('');
    const [montant, setMontant] = useState('');
    const [notejustificative, setNotejustificative] = useState([]);
    const [notejustificativeTitle, setNotejustificativetitle] = useState('');
    const [anobailleurs, setAnobailleurs] = useState([]);
    const [anobailleursTitle, setAnobailleurstitle] = useState('');
    const [souscription, setSouscription] = useState('');
    const [saisinecipm, setSaisinecipm] = useState([]);
    const [saisinecipmTitle, setSaisinecipmtitle] = useState('');
    const [examenprojetavenant, setExamenprojetavenant] = useState('');
    const [validation, setValidation] = useState([]);
    const [validationTitle, setValidationtitle] = useState('');
    const [signature, setSignature] = useState('');
    const [suivipassationid, setSuivipassationid] = useState(1);

    const handleInputChange = e => {

        switch (e.target.id) {
            case 'designationavenant':
                setDesignationavenant(e.target.value)
                break;

            case 'objet':
                setObjet(e.target.value)
                break;

            case 'titulaire':
                setTitulaire(e.target.value)
                break;

            case 'montant':
                setMontant(e.target.value)
                break;

            case 'souscription':
                setSouscription(e.target.value)
                break;

            case 'examenprojetavenant':
                setExamenprojetavenant(e.target.value)
                break;

            case 'signature':
                setSignature(e.target.value)
                break;

            default:
                break;
        }

    }

    const getNotejustificative = (files) => {
        setNotejustificative(files)
        setNotejustificativetitle(files.name)
    }

    const getAnobailleurs = (files) => {
        setAnobailleurs(files)
        setAnobailleurstitle(files.name)
    }

    const getSaisinecipm = (files) => {
        setSaisinecipm(files)
        setSaisinecipmtitle(files.name)
    }

    const getValidation = (files) => {
        setValidation(files)
        setValidationtitle(files.name)
    }

    switch (props.passationState.addAvenantModal) {
        case true:
            
                document.getElementById('openAddAvenantModalBtn').click()
             
            break;

        default:
            break;
    }

    const handleSubmit = e => {

        e.preventDefault();
        if (designationavenant !== '' && objet !== '' &&
            titulaire !== '' && montant !== '' &&
            notejustificative !== [] && notejustificativeTitle !== '' &&
            anobailleurs !== [] && anobailleursTitle !== '' &&
            souscription !== '' && saisinecipm !== [] &&
            saisinecipmTitle !== '' && examenprojetavenant !== '' &&
            validation !== [] && validationTitle !== '' &&
            signature !== '') {

            let avenant = {
                designationavenant, objet, titulaire, montant,
                notejustificative: notejustificative.base64, notejustificativeTitle,
                anobailleurs: anobailleurs.base64, anobailleursTitle, souscription,
                saisinecipm: saisinecipm.base64, saisinecipmTitle, examenprojetavenant,
                validation: validation.base64, validationTitle,
                signature, suivipassationid

            }

            props.dispatch(saveAvenant(avenant))

        } else {
            props.dispatch(openNotificationModal('Veuillez remplir tout les champs !', 'exclamation-triangle', Colors.firebrick, false))
        }

    }

    return (
        <div>
            <button id="openAddAvenantModalBtn" data-toggle="modal" data-target="#addAvenantModal" hidden></button>

            <div class="modal fade" id="addAvenantModal" data-backdrop="static" >
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h2 class="modal-title" id="exampleModalLabel">Enregistrement d'un Avenant <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <h5 style={{ color: Colors.white }}>X</h5>
                            </button></h2>

                        </div>

                        <div class="modal-body">

                            <AddAvenant />

                        </div>

                        <div class="modal-footer">
                            <button type="button" id='closeModalBtn' class="btn btn-secondary" data-dismiss="modal">FERMER</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    passationState: state.passationState,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAvenantModal)