import React from 'react';
import Moment from 'react-moment';
import { Colors } from '../utils';
import { connect } from 'react-redux';
import {deleteUser} from '../screens/utilisateurs/actions';

const Table = (props) => {

    const removeUser =(userid)=> {        
        props.dispatch(deleteUser(userid))
    }

    return (
        
        <div class="adv-table" style={{ paddingTop: 15 }}>
            <table cellpadding="0" cellspacing="0" border="0" class="display table table-striped table-hover" id="hidden-table-info">
                <thead style={{ backgroundColor: Colors.primary, color: Colors.white }}>
                    <tr>
                        {props.thead.map(e => (
                            <th>{e}</th>))}
                    </tr>
                </thead>
                <tbody>
                    {props.tbody.map((e,index) => (
                        <tr key={e.id}>
                            <td>{index + 1}</td>
                            <td>{e.name}</td>
                            <td>{e.surname}</td>
                            <td>{e.postname}</td>
                            <td>{e.departmentname}</td>
                            <td><Moment unix date={e.registereddate} format="D/MM/YYYY" /></td>
                            <td>
                                <button className="btn" style={{ marginRight: 10, backgroundColor: '#217e4d', color: '#fff' }}><i class="fa fa-list" ></i></button>
                                <button className="btn btn-primary" style={{ marginRight: 10 }}><i class="fa fa-edit" ></i></button>
                                <button className="btn btn-danger" onClick={()=>removeUser(e.id)}><i class="fa fa-trash" ></i></button>
                            </td>

                        </tr>
                    ))}

                </tbody>
            </table>
        </div>

    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

export default connect(mapDispatchToProps)(Table);