import { call, put } from 'redux-saga/effects';
import * as apis from '../apis';
import * as actions from '../actions';
import { openNotificationModal, closeConfirmationModal, showLoader, hideLoader, openSaveModal } from '../../../components/actions';
import { Colors } from '../../../utils';

export function* getPpmWorker(payload) {

  try {

    yield put(showLoader());
  
    const ppm = yield call(apis.getPpm,payload.category,payload.exercice);
   
   if (ppm.status != 200) {
    yield put(hideLoader());
    yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
   } else {         
     yield put(actions.getPpmSuccess(ppm.data));
     yield put(hideLoader());       
   }
      
  } catch (error) {
    console.log(error)
  }
}

export function* savePpmWorker(payload) {

  try {

    yield put(openSaveModal());

    let result = yield call(apis.savePpm, payload.ppm);

    if (result.status != 201) {
      yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(openNotificationModal('Enregistré avec success !', 'check-circle', Colors.primary, false));
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getConsultationLanceesWorker(payload) {

  try {

    yield put(showLoader());
  
    const consultationLancees = yield call(apis.getConsultationLancees, payload.exercice);
   
   if (consultationLancees.status != 200) {
    yield put(hideLoader());
    yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
   } else {
     
     yield put(actions.getConsultationLanceesSuccess(consultationLancees.data));       
     yield put(hideLoader());
   }
      
  } catch (error) {
    console.log(error)
  }
}

export function* saveConsultationLanceeWorker(payload) {

  try {

    yield put(openSaveModal());

    let result = yield call(apis.saveConsultationLancee, payload.consultationLancee);

    if (result.status != 201) {
      yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(openNotificationModal('Enregistré avec success !', 'check-circle', Colors.primary, true));       
            
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getSuiviProcessusPassationWorker(payload) {

  try {

    yield put(showLoader());
  
    const result = yield call(apis.getSuiviProcessusPassation, payload.exercice);
   
   if (result.status != 200) {
    yield put(hideLoader());
    yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
   } else {
     yield put(actions.getSuiviProcessusPassationSuccess(result.data)); 
     yield put(hideLoader());      
   }
      
  } catch (error) {
    console.log(error)
  }
}

export function* saveSuiviProcessusPassationWorker(payload) {

  try {

    yield put(openSaveModal());

    let result = yield call(apis.saveSuiviProcessusPassation, payload.suiviProcessusPassation);

    if (result.status != 201) {
      yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(openNotificationModal('Enregistré avec success !', 'check-circle', Colors.primary, true));
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getAnoBailleursWorker() {

  try {
  
    const result = yield call(apis.getAnoBailleurs);
   
   if (result.status != 200) {
    yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
   } else {
     yield put(actions.getAnoBailleursSuccess(result.data));       
   }
      
  } catch (error) {
    console.log(error)
  }
}

export function* saveGenerateurEtatWorker(payload) {

  try {

    let result = yield call(apis.saveGenerateurEtat, payload.generateurEtat);

    if (result.status != 201) {
      yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(openNotificationModal('Enregistré avec success !', 'check-circle', Colors.primary, true));            
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getGenerateurEtatWorker(payload) {

  try {
  
    const result = yield call(apis.getGenerateurEtat,payload.exercice);
   
   if (result.status != 200) {     
     yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
   } else {
     yield put(actions.getGenerateurEtatSuccess(result.data));       
   }
      
  } catch (error) {
    console.log(error)
  }
}

export function* saveNationaleWorker(payload) {

  try {

    yield put(openSaveModal());

    let result = yield call(apis.saveNationale, payload.nationale);

    if (result.status != 201) {
      yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(openNotificationModal('Enregistré avec success !', 'check-circle', Colors.primary, true));            
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getNationaleWorker() {

  try {

    yield put(showLoader());
  
    const result = yield call(apis.getNationales);
   
   if (result.status != 200) { 
    yield put(hideLoader());    
     yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
   } else {
     yield put(actions.getNationaleSuccess(result.data)); 
     yield put(hideLoader());      
   }
      
  } catch (error) {
    console.log(error)
  }
}

export function* saveProcedureBailleurWorker(payload) {

  try {

    yield put(openSaveModal());

    let result = yield call(apis.saveProcedureBailleur, payload.procedureBailleur);

    if (result.status != 201) {
      yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(openNotificationModal('Enregistré avec success !', 'check-circle', Colors.primary, true));            
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getProcedureBailleurWorker() {

  try {

    yield put(showLoader());
  
    const result = yield call(apis.getProceduresBailleurs);
   
   if (result.status != 200) {  
    yield put(hideLoader());   
     yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
   } else {
     yield put(actions.getProcedureBailleurSuccess(result.data));  
     yield put(hideLoader());     
   }
      
  } catch (error) {
    console.log(error)
  }
}

export function* saveAvenantsuivipassationWorker(payload) {

  try {

    let result = yield call(apis.saveAvenant, payload.avenant);

    if (result.status != 201) {
      yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(openNotificationModal('Enregistré avec success !', 'check-circle', Colors.primary, true));            
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getAvenantsuivipassationWorker(payload) {

  try {
  
    const result = yield call(apis.getAvenant,payload.suivipassationid);
   
   if (result.status != 200) {     
     yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
   } else {
     yield put(actions.getAvenantSuccess(result.data));       
   }
      
  } catch (error) {
    console.log(error)
  }
}

export function* saveRecoursWorker(payload) {

  try {

    let result = yield call(apis.saveRecours, payload.recours);

    if (result.status != 201) {
      yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(openNotificationModal('Enregistré avec success !', 'check-circle', Colors.primary, true));            
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getRecoursWorker(payload) {

  try {
  
    const result = yield call(apis.getRecours,payload.suivipassationid);
   
   if (result.status != 200) {     
     yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
   } else {
     yield put(actions.getRecoursSuccess(result.data));       
   }
      
  } catch (error) {
    console.log(error)
  }
}

export function* deletePpmWorker(payload) {

  try {

    yield put(closeConfirmationModal());
    document.getElementById('closeConfirmationModal').click()
   
    let result = yield call(apis.deletePpm, payload.id);

    if (result.status != 200) {
      yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(actions.getPpm());
      yield put(openNotificationModal('Supprimé avec success !', 'check-circle', Colors.primary, false));
    }

  } catch (error) {
    console.log(error)
  }
}

export function* deleteConsultationLanceeWorker(payload) {

  try {
alert(payload.id)
    // yield put(closeConfirmationModal());
    // document.getElementById('closeConfirmationModal').click()
   
    // let result = yield call(apis.deleteConsultationLancee, payload.id);

    // if (result.status != 200) {
    //   yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick));
    // } else {
    //   yield put(actions.getConsultationLancees());
    //   yield put(openNotificationModal('Supprimé avec success !', 'check-circle', Colors.primary));
    // }

  } catch (error) {
    console.log(error)
  }
}

export function* deleteSuiviProcessusPassationWorker(payload) {

  try {

    yield put(closeConfirmationModal());
    document.getElementById('closeConfirmationModal').click()
   
    let result = yield call(apis.deleteSuiviProcessusPassation, payload.id);

    if (result.status != 200) {
      yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(actions.getSuiviProcessusPassation());
      yield put(openNotificationModal('Supprimé avec success !', 'check-circle', Colors.primary, false));
    }

  } catch (error) {
    console.log(error)
  }
}

export function* deleteGenerateurEtatWorker(payload) {

  try {

    yield put(closeConfirmationModal());
    document.getElementById('closeConfirmationModal').click()
   
    let result = yield call(apis.deleteGenerateurEtat, payload.id);

    if (result.status != 200) {
      yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(actions.getGenerateurEtat());
      yield put(openNotificationModal('Supprimé avec success !', 'check-circle', Colors.primary, false));
    }

  } catch (error) {
    console.log(error)
  }
}