import React from 'react';
import { NavLink } from 'react-router-dom';
import { Colors } from '../utils';

const SidebarButton = ({ title, route, icon, ...other_props }) => {

    return (

        <li> <NavLink to={`/${route}`} {...other_props}
        activeStyle={{ background: Colors.primary, color: Colors.white }} >
            <i className={`fa fa-${icon}`}></i>
            <span>{title || 'Title'} </span>
        </NavLink>
        </li>

    );

}

export default SidebarButton;