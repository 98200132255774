import React, { useEffect } from 'react';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import { GroupedButton, TableHead, EmptyList } from '../../../../components';

const Avenant = (props) => {
    
    return (

        <div class="adv-table" style={{ paddingTop: 15, overflowX: 'auto' }}>

            {props.passationState.avenants.length !== 0 ?
        
                <table cellpadding="0" cellspacing="0" border="0" class="display table table-striped table-hover" id="hidden-table-info">
               
                    <TableHead data={['Nº', "Désignation de l'avenant", 'Objet', 'Titulaire', 'Montant',
                        'Note justificative', 'ANO Bailleurs', 'Souscription', 'Saisine CIPM',
                        "Examen projet d'avenant", 'Validation', 'Signature', '', '' ]} />
                    <tbody>
                    {props.passationState.avenants.map((e, index) => (
                                <tr key={e.id}>
                                    <td>{index + 1}</td>                                    
                                    <td>{e.designationavenant}</td>
                                    <td>{e.objet}</td>
                                    <td>{e.titulaire}</td>
                                    <td>{e.montant}</td>
                                    <td><GroupedButton pdf={e.notejustificative} slice={true} name={e.notejustificativetitle} /></td>                                    
                                    <td><GroupedButton pdf={e.anobailleurs} slice={true} name={e.anobailleurstitle} /></td>                                    
                                    <td>{e.souscription}</td>
                                    <td><GroupedButton pdf={e.saisinecipm} slice={true} name={e.saisinecipmtitle} /></td>                                    
                                    <td>{e.examenprojetavenant}</td>
                                    <td><GroupedButton pdf={e.validation} slice={true} name={e.validationtitle} /></td>                                    
                                    <td>{e.signature}</td>
                                    <td><button className="btn btn-primary btn-sm" ><i class="fa fa-edit" ></i></button></td>
                                    <td><button className="btn btn-danger btn-sm" ><i class="fa fa-trash" ></i></button></td>

                                </tr>
                            ))}
                    </tbody>

                    </table> : <EmptyList /> }               

        </div>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    passationState: state.passationState,
    componentState: state.componentState
});

export default connect(mapStateToProps, mapDispatchToProps)(Avenant)