import React, { Component } from 'react';
import { ExerciceYearPicker } from '../../../../components';


export default class ExerciceMenu extends Component {

    render() {

        const { path } = this.props.match;

        return (

            <section id="main-content">
                <section class="wrapper">
                    <div class="row">
                        <div class="col-lg-12 main-chart">

                        <h1 style={{ textAlign: 'center' }}>Exercice</h1>

                            <div class="row mt">

                                <ExerciceYearPicker route={`${path}/add`} title={'AJOUTEZ UN EXERCICE'} btn_title={'Ajouter'} />
                                <ExerciceYearPicker route={`${path}/details`} title={'VOIR LES EXERCICE'} btn_title={'Voir'} />
                            
                            </div>

                        </div>

                    </div>

                </section>

            </section>

        );
    }
}