import React, { useEffect } from 'react';
import { Button, DownloadPdf } from '.';
import { openPreviewModal, closeNotificationModal, openConfirmationModal } from './actions';
import { Colors } from '../utils';

const PpmDisplay = ({ data, deletePpm, title = 'Name', dispatch }) => {

    useEffect(() => {

        return () => {
            dispatch(closeNotificationModal());
        }
    }, []);

    return (

        <div class="row mt">

            <div class="col-md-12 col-sm-12 mb">

                <div class="white-panel pn">

                    <div class="chart mt">

                        <div>
                            <img src="/img/pdf.png" width="100" alt='Pdf' />
                            <h5>{title}</h5>

                            <div>
                                <Button title={'Aperçu'} icon={'eye'} action={() => dispatch(openPreviewModal(data))} />
                                <Button title={'Télécharger'} icon={'download'} action={() => DownloadPdf(data, title)} />
                                {/* <Button title={'Supprimer'} btn_style={{ backgroundColor: Colors.firebrick, }} icon={'trash'}
                                    action={() => dispatch(openConfirmationModal('Voulez-vous vraiment supprimer cet élément ?',
                                        'trash', Colors.firebrick, deletePpm))} /> */}
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>

    );
}

export default PpmDisplay;