import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import { Provider } from 'react-redux';
import configureStore from './utils/reduxStore';


const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={null}>
            <App />
        </Suspense>
    </Provider>, document.getElementById('root'));

serviceWorker.unregister();