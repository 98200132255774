const DownloadPdf = ( pdf, name ) => {
  
        const linkSource = pdf;
        const downloadLink = document.createElement("a");
        const fileName = name;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
}

export default DownloadPdf;