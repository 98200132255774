import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getPpm, deletePpm } from '../../../actions';
import { closePreviewModal } from '../../../../../components/actions';
import { PpmDisplay, EmptyList, Loader } from '../../../../../components';

const PpmValide = (props) => {

    useEffect(() => {
        props.dispatch(getPpm('ppmvalide', props.componentState.exerciceYear));
        return () => props.dispatch(closePreviewModal())
    }, []);

    let { ppms } = props.passationState;

    return (

        <div class="adv-table" style={{ paddingTop: 15 }}>
            {props.componentState.loader ? <Loader /> :

                <div>

                    {
                        ppms.length !== 0 ?

                            <PpmDisplay data={ppms[0].base64} title={ppms[0].name} deletePpm={deletePpm(ppms[0].id)} dispatch={props.dispatch} /> : <EmptyList />
                    }

                </div>}
        </div>

    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    passationState: state.passationState,
    componentState: state.componentState
});

export default connect(mapStateToProps, mapDispatchToProps)(PpmValide)