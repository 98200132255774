import React from 'react';
import { Colors } from '../utils';
import DownloadPdf from './DownloadPdf';
import { openPreviewModal } from './actions';
import { connect } from 'react-redux';

const GroupedButton = (props) => {

    const styles = {
        download_btn: {
            backgroundColor: Colors.primary,
            color: Colors.white,
            padding: '4px 10px',
            fontSize: 14,
            textAlign: 'center',
            whiteSpace: 'nowrap',
            border: '1px solid transparent',
            borderLeftColor: Colors.lavender
        },
        sub_menu: {
            cursor: 'pointer'
        }
    }
    const dd =()=> {
        const linkSource = props.pdf;
        const downloadLink = document.createElement("a");
        
        downloadLink.href = linkSource;
        downloadLink.target = '_blank';
        
        downloadLink.click();
    }
    // props.dispatch(openPreviewModal(props.pdf))
    return (
        <div class="btn-group">
            {props.slice ? <button type="button" disabled style={styles.download_btn} >{String(props.name).slice(0,-4)}</button> : 
            <button type="button" disabled style={styles.download_btn} >{props.name}</button>}
            <button type="button" style={styles.download_btn} data-toggle="dropdown">
                <span class="caret"></span>
                <span class="sr-only">Toggle Dropdown</span>
            </button>
            <ul class="dropdown-menu" role="menu">
                <li><a style={styles.sub_menu} onClick={() => props.dispatch(openPreviewModal(props.pdf))}>
                    <i class="fa fa-eye"></i> Aperçu</a></li>
                <li class="divider"></li>
                <li><a style={styles.sub_menu} onClick={() => DownloadPdf(props.pdf, props.name)}>
                    <i class="fa fa-download"></i> Télécharger</a></li>
            </ul>
        </div>
    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    componentState: state.componentState,
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupedButton)