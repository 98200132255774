import React, { useEffect } from 'react';
import { closeAddAvenantModal, closeAvenantModal } from '../actions';
import { connect } from 'react-redux';
import Avenant from '../view/exercice/Avenant';
import { Colors } from '../../../utils';


const AvenantModal = (props) => {

    useEffect(() => {
        return()=> {
            
            // props.dispatch(closeAvenantModal())
            // props.dispatch(closeAddAvenantModal())
        }
    }, []);

    switch (props.passationState.avenantModal) {
        case true:
            document.getElementById('openModalBtn').click()
            break;

        default:
            break;
    }

    return (
        <div>
            <button id="openModalBtn" data-toggle="modal" data-target="#exampleModal" hidden></button>

            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h2 class="modal-title" id="exampleModalLabel">Avenant <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <h5 style={{ color: Colors.white }}>X</h5>
                            </button></h2>

                        </div>

                        <div class="modal-body">
                            <Avenant />
                        </div>

                        <div class="modal-footer">
                            <button type="button" id='closeModalBtn' class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    passationState: state.passationState,
});

export default connect(mapStateToProps, mapDispatchToProps)(AvenantModal)