import React, { useState, useEffect } from 'react';
import { Button, TopButton, FileBase64, Loader } from '../../../components';
import { Colors, otherUtils } from '../../../utils';
import { connect } from 'react-redux';
import { saveAudit } from '../actions';
import { closeNotificationModal, openNotificationModal, openPreviewModal, closePreviewModal, closeSaveModal } from '../../../components/actions';


const Form = (props) => {

    useEffect(() => {
        return () => {
            props.dispatch(closeNotificationModal())
            props.dispatch(closePreviewModal())
            props.dispatch(closeSaveModal())

        }
    }, []);

    const [audit, setAudit] = useState([]);
    const [auditTitle, setAuditTitle] = useState('');
    const [type, setType] = useState('interne');
    const [selected, setSelected] = useState(false);


    const getAudit = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setAudit(files)
            setAuditTitle(files.name)
            setSelected(true)
        } else {                       
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    let aud = {
        audit: audit.base64, auditTitle, type
    }

    const save = () => {

        if (audit !== []) {
            props.dispatch(saveAudit(aud))
            setSelected(false)
        } else {
            props.dispatch(openNotificationModal('Veuillez remplir tout les champs !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const change = () => {
        setAudit([]);
        setAuditTitle('')
        setSelected(false)
    }

    const renderSelected = () => {
        return (
            <div>
                <img src="/img/pdf.png" width="100" alt='Pdf' />
                <h5>{auditTitle}</h5>
                <div>
                    <Button title={'Changer'} icon={'exchange-alt'} action={() => change()} />
                    <Button title={'Aperçu'} icon={'eye'} action={() => props.dispatch(openPreviewModal(audit.base64))} />
                    {/* <Button title={'Aperçu'} icon={'eye'} action={() => props.dispatch(openPreviewModal(audit.base64.split(',')[1]))} /> */}
                    <Button title={'Enregistrer'} icon={'save'} action={() => save()} />
                </div>

            </div>
        )
    }

    const renderSelect = () => {
        return (
            <div>
                <img src="/img/add_pdf.png" width="100" alt='Add Pdf' />

                <h5><button className="btn" style={{ backgroundColor: Colors.primary, color: Colors.white }}>
                    <FileBase64
                        onDone={getAudit.bind(this)} /> </button></h5>
            </div>
        )
    }

    const handleInputChange = e => {
        setType(e.target.value)
    }

    return (

        <section id="main-content">
            <section class="wrapper site-min-height">

                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <h3><i className='fa fa-plus'></i> Ajouter un Audit</h3>
                    <h3><TopButton route='/home/audit/details' title='Liste des Audits' icon='list' /> </h3>
                </div>
                <div class="row mt">
                    <div class="col-md-12 col-sm-12 mb" style={{ backgroundColor: '#f2f2f2', padding: 20 }}>

                        <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                            <h4>Veuillez choisir le type de rapport</h4>
                            <select value={type} onChange={handleInputChange} className='btn' style={{ width: 200, backgroundColor: Colors.primary, color: Colors.white }}>
                                <option> Interne </option>
                                <option> Externe </option>
                            </select>
                        </div>
                        <div class="chart mt">

                            {selected ? renderSelected() : renderSelect()}

                        </div>

                    </div>
                </div>
            </section>
        </section>
    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    componentState: state.componentState,
});

export default connect(mapStateToProps, mapDispatchToProps)(Form)