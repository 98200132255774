import constants from '../constants';

export function getAudit() {
    return {
        type: constants.GET_AUDIT      
    }
}

export function getAuditSuccess(audits) {
    return {
        type: constants.GET_AUDIT_SUCCESS,
        audits
    }
}

export function saveAudit(audit) {
    return {
        type: constants.SAVE_AUDIT,
        audit
    }
}

export function deleteAudit(id) {
    return {
        type: constants.DELETE_AUDIT,
        id
    }
}