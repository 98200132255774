import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ExerciceMenu from './ExerciceMenu';
import ExerciceDetails from './ExerciceDetails';
import ExerciceForm from '../../forms/exercice';


export default class Exercice extends Component {


    render() {

        const { path } = this.props.match;

        return (

            <Router>

                <Route path={`${path}`} exact component={ExerciceDetails} />
                <Route path={`${path}/details`} component={ExerciceDetails} />
                <Route path={`${path}/add`} component={ExerciceForm} />

            </Router>


        );
    }
}