import constants from '../constants';

export function getPpm(category, exercice) {
    return {
        type: constants.GET_PPM,
        category,
        exercice        
    }
}

export function getPpmSuccess(ppms) {
    return {
        type: constants.GET_PPM_SUCCESS,
        ppms
    }
}

export function savePpm(ppm) {
    return {
        type: constants.SAVE_PPM,
        ppm
    }
}

export function getConsultationLancees(exercice) {
    return {
        type: constants.GET_CONSULTATION_LANCEES,
        exercice        
    }
}

export function getConsultationLanceesSuccess(consultationLancees) {
    return {
        type: constants.GET_CONSULTATION_LANCEES_SUCCESS,
        consultationLancees
    }
}

export function saveConsultationLancee(consultationLancee) {
    return {
        type: constants.SAVE_CONSULTATION_LANCEE,
        consultationLancee
    }
}

export function getSuiviProcessusPassation(exercice) {
    return {
        type: constants.GET_SUIVI_PROCESSUS_PASSATION,
        exercice        
    }
}

export function getSuiviProcessusPassationSuccess(suiviProcessusPassation) {
    return {
        type: constants.GET_SUIVI_PROCESSUS_PASSATION_SUCCESS,
        suiviProcessusPassation
    }
}

export function saveSuiviProcessusPassation(suiviProcessusPassation) {
    return {
        type: constants.SAVE_SUIVI_PROCESSUS_PASSATION,
        suiviProcessusPassation
    }
}

export function getAnoBailleurs() {
    return {
        type: constants.GET_ANO_BAILLEURS                
    }
}

export function getAnoBailleursSuccess(anobailleurs) {
    return {
        type: constants.GET_ANO_BAILLEURS_SUCCESS,
        anobailleurs
    }
}

export function saveGenerateurEtat(generateurEtat) {
    return {
        type: constants.SAVE_GENERATEUR_ETAT,
        generateurEtat
    }
}

export function getGenerateurEtat(exercice) {
    return {
        type: constants.GET_GENERATEUR_ETAT,
        exercice                
    }
}

export function getGenerateurEtatSuccess(generateurEtats) {
    return {
        type: constants.GET_GENERATEUR_ETAT_SUCCESS,
        generateurEtats
    }
}

export function openAvenantModal(suivipassationid) {
    return {
        type: constants.OPEN_AVENANT_MODAL,
        suivipassationid        
    }
}

export function closeAvenantModal() {
    return {
        type: constants.CLOSE_AVENANT_MODAL        
    }
}

export function openAddAvenantModal(suivipassationid) {
    return {
        type: constants.OPEN_ADD_AVENANT_MODAL,
        suivipassationid        
    }
}

export function closeAddAvenantModal() {
    return {
        type: constants.CLOSE_ADD_AVENANT_MODAL        
    }
}

export function saveNationale(nationale) {
    return {
        type: constants.SAVE_NATIONALE,
        nationale
    }
}

export function getNationale() {
    return {
        type: constants.GET_NATIONALES,                        
    }
}

export function getNationaleSuccess(nationales) {
    return {
        type: constants.GET_NATIONALES_SUCCESS,
        nationales
    }
}

export function saveProcedureBailleur(procedureBailleur) {
    return {
        type: constants.SAVE_PROCEDURE_BAILLEUR,
        procedureBailleur
    }
}

export function getProceduresBailleurs() {
    return {
        type: constants.GET_PROCEDURE_BAILLEUR,                        
    }
}

export function getProcedureBailleurSuccess(proceduresBailleurs) {
    return {
        type: constants.GET_PROCEDURE_BAILLEUR_SUCCESS,
        proceduresBailleurs
    }
}

export function deletePpm(id) {
    return {
        type: constants.DELETE_PPM,
        id
    }
}

export function deleteConsultationLancee(id) {
    return {
        type: constants.DELETE_CONSULTATION_LANCEE,
        id
    }
}

export function deleteSuiviProcessusPassation(id) {
    return {
        type: constants.DELETE_SUIVI_PROCESSUS_PASSATION,
        id
    }
}

export function deleteGenerateurEtat(id) {
    return {
        type: constants.DELETE_GENERATEUR_ETAT,
        id
    }
}

export function saveAvenant(avenant) {
    return {
        type: constants.SAVE_AVENANT,
        avenant
    }
}

export function getAvenant(suivipassationid) {
    return {
        type: constants.GET_AVENANT, 
        suivipassationid                       
    }
}

export function getAvenantSuccess(avenants) {
    return {
        type: constants.GET_AVENANT_SUCCESS,
        avenants
    }
}

export function saveRecours(recours) {
    return {
        type: constants.SAVE_RECOURS,
        recours
    }
}

export function getRecours(suivipassationid) {
    return {
        type: constants.GET_RECOURS, 
        suivipassationid                       
    }
}

export function getRecoursSuccess(recours) {
    return {
        type: constants.GET_RECOURS_SUCCESS,
        recours
    }
}

export function openAddRecoursModal(suivipassationid) {
    return {
        type: constants.OPEN_ADD_RECOURS_MODAL,
        suivipassationid        
    }
}

export function closeAddRecoursModal() {
    return {
        type: constants.CLOSE_ADD_RECOURS_MODAL        
    }
}

export function closeRecoursModal() {
    return {
        type: constants.CLOSE_RECOURS_MODAL        
    }
}