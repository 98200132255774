import React from 'react';
import { connect } from 'react-redux';
import { Loader } from '.';

const SaveModal = (props) => {

    switch (props.componentState.saveModal) {
        case true:
            document.getElementById('saveModal').click()
            break;

        default:
            break;
    }
       
    return (
        <div>
            <button id="saveModal" data-toggle="modal" data-target="#sModal" hidden></button>            

            <div class="modal fade" id="sModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">

                        <div class="modal-body centered">
                        <Loader save={true} />                           
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    componentState: state.componentState,
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveModal)