import axios from 'axios';
import SERVER from '../../../utils/Server';

export const getExecution = () => {

  return axios.get(SERVER.ADDRESS + 'exercice/execution/'
  ).then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}

export const saveExecution = (execution) => {

  return axios.post(SERVER.ADDRESS + 'exercice/execution/register', execution).then((response) => {
    return response
  }).catch((response) => {
    return response
  });
}

export const saveAvenant = (avenant) => {

  return axios.post(SERVER.ADDRESS + 'exercice/avenantexecution/register', avenant).then((response) => {
    return response
  }).catch((response) => {
    return response
  });
}

export const getAvenant = (executionid) => {

  return axios.get(SERVER.ADDRESS + 'exercice/avenantexecution/'+executionid).then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}

export const saveResilie = (avenant) => {

  return axios.post(SERVER.ADDRESS + 'exercice/resilieexecution/register', avenant).then((response) => {
    return response
  }).catch((response) => {
    return response
  });
}

export const getResilie = (executionid) => {

  return axios.get(SERVER.ADDRESS + 'exercice/resilieexecution/'+executionid).then((response) => {
    return response
  }).catch((response) => {
    return response
  });

}