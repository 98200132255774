import constants from '../constants';

export function openPreviewModal(pdf) {
    return {
        type: constants.OPEN_PREVIEW_MODAL,
        pdf
    }
}

export function closePreviewModal() {
    return {
        type: constants.CLOSE_PREVIEW_MODAL
    }
}

export function openNotificationModal(message, icon, color, reload) {
    
    return {
        type: constants.OPEN_NOTIFICATION_MODAL,
        message,
        icon,
        color,
        reload
    }
}

export function closeNotificationModal() {
    return {
        type: constants.CLOSE_NOTIFICATION_MODAL
    }
}

export function setExerciceYear(year) {
    return {
        type: constants.SET_EXERCICE_YEAR,
        year
    }
}

export function openConfirmationModal(message, icon, color, deleteAction) {
    return {
        type: constants.OPEN_CONFIRMATION_MODAL,
        message,
        icon,
        color,
        deleteAction
    }
}

export function closeConfirmationModal() {
    return {
        type: constants.CLOSE_CONFIRMATION_MODAL
    }
}

export function showLoader() {
    return {
        type: constants.SHOW_LOADER
    }
}

export function hideLoader() {
    return {
        type: constants.HIDE_LOADER
    }
}

export function openSaveModal() {
    return {
        type: constants.OPEN_SAVE_MODAL
    }
}

export function closeSaveModal() {
    return {
        type: constants.CLOSE_SAVE_MODAL
    }
}