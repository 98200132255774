import { takeLatest } from 'redux-saga/effects';
import * as workers from './auditWorker';
import constants from '../constants';

export function* watchGetAudit() {
    yield takeLatest(constants.GET_AUDIT, workers.getAuditWorker);
}

export function* watchSaveAudit() {
    yield takeLatest(constants.SAVE_AUDIT, workers.saveAuditWorker);
}

export function* watchDeleteAudit() {
    yield takeLatest(constants.DELETE_AUDIT, workers.deleteAuditWorker);
}
