import React, { useEffect } from 'react';
import { Colors } from '../utils';
import { connect } from 'react-redux';
import { setExerciceYear } from './actions';
import { getConsultationLancees, getSuiviProcessusPassation, getPpm } from '../screens/passation/actions';

const ExerciceYearPicker = (props) => {

    let year = (new Date()).getFullYear();

    let exerciceYears = Array.from(new Array(3), (val, index) => year - index);
    
    const handleSelectChange = e => {
        props.dispatch(setExerciceYear(e.target.value));
        props.dispatch(getPpm('notepresentation',e.target.value));
        props.dispatch(getPpm('ppmvalide',e.target.value));
        props.dispatch(getConsultationLancees(e.target.value));
        props.dispatch(getSuiviProcessusPassation(e.target.value));
    }

    useEffect(() => {
        props.dispatch(setExerciceYear(year));
    }, []);

    const styles = {
        yearPicker: {
            paddingLeft: 50,
            paddingRight: 50,
            marginTop: 20,
            color: Colors.white,
            backgroundColor: Colors.darkslategray
        },
        button: {
            backgroundColor: Colors.darkslategray,
            marginTop: 20,
            marginLeft: 10,
            color: Colors.white,
        }
    }

    return (
        <div>

            <select className="btn" style={styles.yearPicker} onChange={handleSelectChange}>
                {
                    exerciceYears.map((year, index) => {
                        return <option key={index} value={year}>{year}</option>
                    })
                }
            </select>

            {props.enableSearch ? <button className='btn' style={styles.button}><i className='fa fa-search'></i></button> : null}

        </div>


    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    componentState: state.componentState,
});

export default connect(mapStateToProps, mapDispatchToProps)(ExerciceYearPicker)