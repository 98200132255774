import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import Sidebar from '../components/Sidebar';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Dashboard from './dashboard/view/Dashboard';
import Execution from './execution/view';
import Exercice from './passation/view/exercice';
import Parametres from './parametres/view/Parametres';
import RapportDePerformance from './rapportPerformance/view/RapportDePerformance';
import Audit from './audit/view';
import Sanction from './sanction/view/Sanction';
import { Utilisateurs } from './utilisateurs';
import Privileges from './privileges/view/Privileges';
import ReglesProcedures from './passation/view/reglesProcesures';
import { PdfViewer, NotificationModal, ConfirmationModal, SaveModal } from '../components';


export default class Screens extends Component {

    componentDidMount() {
        document.getElementById('body').style.backgroundColor = "#fff"
    }

    render() {
        return (
            <section id="container">
                <Router>

                    <NavBar />
                    <Sidebar />

                    <Route path='/home' exact component={Dashboard} />
                    <Route path='/home/audit' component={Audit} />
                    <Route path='/home/passation/exercice' component={Exercice} />
                    <Route path='/home/passation/reglesProcedures' component={ReglesProcedures} />
                    <Route path='/home/execution' component={Execution} />
                    <Route path='/home/sanction/contratsResilies' component={Sanction} />
                    <Route path='/home/rapportPerformance' component={RapportDePerformance} />
                    <Route path='/home/utilisateurs' component={Utilisateurs} />
                    <Route path='/home/privileges' component={Privileges} />
                    <Route path='/home/parametres' component={Parametres} />

                <PdfViewer />
                <NotificationModal />
                <ConfirmationModal />
                <SaveModal />
                
                </Router>
            </section>
        );
    }
}