import React from 'react';
import { FileBase64 } from '.';


const InputTextWithDate = ({ title = 'Title', type='date', ...other_props }) => {

        return (

            <div class="form-group col-md-6">
                <label for={title}>{title}</label>
                <div className='row'>
                    <div className='col-md-6'>
                        <input type={type} className='form-control' id={title}
                           placeholder={title}  {...other_props} />

                    </div>
                    <div className='col-md-6'>                        
                            <FileBase64 id={title} {...other_props} />
                    </div>
                </div>
            </div>

        );
    }


export default InputTextWithDate;