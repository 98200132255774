import React, { useEffect, useState } from 'react';
import { Colors } from '../../../../../utils';
import FileBase64 from 'react-file-base64';
import { savePpm } from '../../../actions';
import { connect } from 'react-redux';
import { Button } from '../../../../../components';
import { openPreviewModal, closePreviewModal, closeNotificationModal, closeSaveModal } from '../../../../../components/actions';

const PpmValide = (props) => {

  const [files, setFiles] = useState([]);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    
    return () => {
      props.dispatch(closePreviewModal())
      props.dispatch(closeNotificationModal())
      props.dispatch(closeSaveModal())
    }
  }, []);

  const change =()=> {
    setFiles([])
    setSelected(false)   
  }

  const getFiles =(files)=> {
    setFiles(files)
    setSelected(true)  
  }

  const save =()=> {

    let ppm = {
      base64: String(files.base64),
      name: files.name,
      size: files.size,
      type: files.type,
      category: 'ppmvalide',
      exercice: props.componentState.exerciceYear
    }
    props.dispatch(closePreviewModal())
    props.dispatch(savePpm(ppm));
    setSelected(false)
  }

  const renderSelected =()=> {
    return (
      <div>
        <img src="/img/pdf.png" width="100" alt='Pdf' />
        <h5>{files.name}</h5>
        <div>
          <Button title={'Changer'} icon={'exchange-alt'} action={() => change()} />
          <Button title={'Aperçu'} icon={'eye'} action={() => props.dispatch(openPreviewModal(files.base64))} />
          <Button title={'Enregistrer'} icon={'save'} action={() => save()} />
        </div>
      </div>
    )
  }

  const renderSelect =()=> {
    return (
      <div>
        <img src="/img/add_pdf.png" width="100" alt='Add Pdf' />

        <h5><button className="btn" style={{ backgroundColor: Colors.primary, color: Colors.white }}>
          <FileBase64
            onDone={getFiles.bind(this)} /> </button></h5>
      </div>
    )
  }
  
    return (

      <div class="adv-table" style={{ paddingTop: 15 }}>
        <div class="row mt">

          <div class="col-md-12 col-sm-12 mb">

            <div class="white-panel pn" style={{ backgroundColor: '#f2f2f2' }}>

              <div class="chart mt">

                {selected ? renderSelected() : renderSelect()}

              </div>

            </div>
          </div>

        </div>

      </div>

    );
  }


const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => { dispatch(action) }
  }
}

const mapStateToProps = state => ({
  componentState: state.componentState,
});

export default connect(mapStateToProps, mapDispatchToProps)(PpmValide)