import React, { useState, useEffect } from 'react';
import { InputText } from '../../../../components';
import { Colors, otherUtils } from '../../../../utils';
import { connect } from 'react-redux';
import { saveNationale } from '../../actions';
import { openNotificationModal, closeNotificationModal, closeSaveModal } from '../../../../components/actions';

const Nationales = (props) => {

    useEffect(() => {
        return () => {
            props.dispatch(closeNotificationModal())
            props.dispatch(closeSaveModal())
        }
    }, []);

    const [lois, setLois] = useState([]);
    const [loisTitle, setLoisTitle] = useState('');
    const [decrets, setDecrets] = useState([]);
    const [decretsTitle, setDecretsTitle] = useState([]);
    const [resolutionca, setResolutionca] = useState([]);
    const [resolutioncaTitle, setResolutioncaTitle] = useState('');
    const [arretes, setArretes] = useState([]);
    const [arretesTitle, setArretesTitle] = useState('');
    const [circulaires, setCirculaires] = useState([]);
    const [circulairesTitle, setCirculairesTitle] = useState('');
    const [daotypesmisenforme, setDaotypesmisenforme] = useState([]);
    const [daotypesmisenformeTitle, setDaotypesmisenformeTitle] = useState('');


    const getLois = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setLois(files)
            setLoisTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getDecrets = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setDecrets(files)
            setDecretsTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getresolutionca = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setResolutionca(files)
            setResolutioncaTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getArretes = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setArretes(files)
            setArretesTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getCirculaires = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setCirculaires(files)
            setCirculairesTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    const getDaotypesmisenforme = (files) => {

        if (Number(String(files.size).slice(0, -3)) < otherUtils.FILE_SIZE) {
            setDaotypesmisenforme(files)
            setDaotypesmisenformeTitle(files.name)
        } else {
            props.dispatch(openNotificationModal('Le fichier dépasse 10 Mo, veuillez sélectionner un fichier moins volumineux !', 'exclamation-triangle', Colors.firebrick))
        }

    }


    const handleSubmit = e => {

        e.preventDefault();
        if (lois !== [] && decrets !== '' &&
            resolutionca !== [] && arretes !== '' &&
            circulaires !== [] && daotypesmisenforme !== '') {

            let nationale = {
                lois: lois.base64, loisTitle,
                decrets: decrets.base64, decretsTitle,
                resolutionca: resolutionca.base64, resolutioncaTitle,
                arretes: arretes.base64, arretesTitle,
                circulaires: circulaires.base64, circulairesTitle,
                daotypesmisenforme: daotypesmisenforme.base64, daotypesmisenformeTitle
            }

            props.dispatch(saveNationale(nationale))
        } else {
            props.dispatch(openNotificationModal('Veuillez remplir tout les champs !', 'exclamation-triangle', Colors.firebrick))
        }

    }

    return (

        <div class="row mt">
            <div class="col-lg-12">

                <div class="content-panel" style={{ backgroundColor: '#f2f2f2' }}>
                    <div class="panel-body">

                        <form onSubmit={handleSubmit}>

                            <div class="form-row">
                                <InputText title={'Lois'} type={'file'} onDone={getLois.bind(this)} />
                                <InputText title={'Décrets'} type={'file'} onDone={getDecrets.bind(this)} />
                            </div>

                            <div class="form-row">
                                <InputText title={"Résolution du conseil d'administration (EDC)"} type={'file'} onDone={getresolutionca.bind(this)} />
                                <InputText title={'Arrêtés'} type={'file'} onDone={getArretes.bind(this)} />
                            </div>

                            <div class="form-row">
                                <InputText title={'Circulaires'} type={'file'} onDone={getCirculaires.bind(this)} />
                                <InputText title={'DAO Types mis en forme'} type={'file'} onDone={getDaotypesmisenforme.bind(this)} />
                            </div>

                            <button type="submit" class="btn form-control" style={{ backgroundColor: Colors.primary, color: Colors.white }}>Enregistrer</button>
                        </form>
                    </div>
                </div>

            </div>
        </div>

    );

}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

const mapStateToProps = state => ({
    componentState: state.componentState,
});

export default connect(mapStateToProps, mapDispatchToProps)(Nationales)