import React, { Component } from 'react';

export default class Privileges extends Component {

      render() {
            return (

                  <section id="main-content">
                        <section class="wrapper site-min-height">
                              <h3><i class="fa fa-universal-access"></i> privilèges</h3>
                              <div class="row mt">
                                    <div class="col-lg-12">

                                          <div class="content-panel" style={{backgroundColor:'#f2f2f2'}}>
                                                <div class="panel-body">
                                                <h4>Cette page est indisponible pour l'instant</h4>
                                                      {/* <table id="tt" class="table table-striped table-hover">
                                                            <thead>
                                                                  <tr>
                                                                        <th>Nº</th>
                                                                        <th>Nom du privilèges</th>
                                                                        <th>Directeur</th>
                                                                        <th>Sous-Directeur</th>
                                                                        <th>Secretaire</th>
                                                                        <th>Staff</th>

                                                                  </tr>
                                                            </thead>
                                                            <tbody>
                                                                  <tr>
                                                                        <td></td>
                                                                        <td>PPM</td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" /></td>
                                                                        <td><input type="checkbox" /></td>
                                                                        <td><input type="checkbox" /></td>

                                                                  </tr>
                                                                  <tr>
                                                                        <td></td>
                                                                        <td>Consultation lancées</td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" checked /></td>

                                                                  </tr>
                                                                  <tr>
                                                                        <td></td>
                                                                        <td>Suivi Du Processus De La Passation</td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" /></td>
                                                                        <td><input type="checkbox" /></td>
                                                                        <td><input type="checkbox" /></td>

                                                                  </tr>
                                                                  <tr>
                                                                        <td></td>
                                                                        <td>Generateur D'etat</td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" checked /></td>

                                                                  </tr>
                                                                  <tr>
                                                                        <td></td>
                                                                        <td>Regles &amp; Procedures</td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" checked /></td>

                                                                  </tr>
                                                                  <tr>
                                                                        <td></td>
                                                                        <td>Execution</td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" checked /></td>

                                                                  </tr>
                                                                  <tr>
                                                                        <td></td>
                                                                        <td>Audit</td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" /></td>
                                                                        <td><input type="checkbox" /></td>

                                                                  </tr>
                                                                  <tr>
                                                                        <td></td>
                                                                        <td>Sanction</td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" /></td>
                                                                        <td><input type="checkbox" /></td>

                                                                  </tr>
                                                                  <tr>
                                                                        <td></td>
                                                                        <td>Rapport de performance</td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" checked /></td>
                                                                        <td><input type="checkbox" /></td>

                                                                  </tr>

                                                            </tbody>
                                                      </table> */}

                                                </div>
                                          </div>

                                    </div>
                              </div>
                        </section>

                  </section>

            );
      }
}