import React,{Component} from 'react';
import {Colors} from '../../../utils';

export default class EntreprisesSuspendus extends Component {

    render() {
        return(
           
            <div class="adv-table" style={{paddingTop:15}}>
                    <table cellpadding="0" cellspacing="0" border="0" class="display table table-striped table-hover" id="hidden-table-info">
                      <thead style={{backgroundColor:Colors.primary, color:Colors.white}}>
                        <tr>
                          <th>Nº</th>
                          <th>Nom de l'entreprise</th>
                          <th class="hidden-phone">Motif de suspension</th>
                          <th class="hidden-phone">Date de suspension</th>                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="gradeX">
                          <td>Trident</td>
                          <td>Internet Explorer 4.0</td>
                          <td class="hidden-phone">Win 95+</td>
                          <td class="center hidden-phone">4</td>
                         
                        </tr>
                        <tr class="gradeC">
                          <td>Trident</td>
                          <td>Internet Explorer 5.0</td>
                          <td class="hidden-phone">Win 95+</td>
                          <td class="center hidden-phone">5</td>
                         
                        </tr>
                       
                       
                      </tbody>
                    </table>
                  </div>
    
        );
    }
}