import { call, put } from 'redux-saga/effects';
import * as apis from '../apis';
import * as actions from '../actions';
import { openNotificationModal, showLoader, hideLoader, openSaveModal } from '../../../components/actions';
import { Colors } from '../../../utils';

export function* getExecutionWorker(payload) {

  try {

    yield put(showLoader());

    const execution = yield call(apis.getExecution);

    if (execution.status != 200) {
      yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(actions.getExecutionSuccess(execution.data));
    }

  } catch (error) {
    console.log(error)
  } finally {
    yield put(hideLoader());
  }
}

export function* saveExecutionWorker(payload) {

  try {

    yield put(openSaveModal());

    let result = yield call(apis.saveExecution, payload.execution);

    if (result.status != 201) {
      yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(openNotificationModal('Enregistré avec success !', 'check-circle', Colors.primary, true));
    }

  } catch (error) {
    console.log(error)
  }
}

export function* saveAvenantExecutionWorker(payload) {

  try {

    let result = yield call(apis.saveAvenant, payload.avenant);

    if (result.status != 201) {
      yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(openNotificationModal('Enregistré avec success !', 'check-circle', Colors.primary, true));
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getAvenantExecutionWorker(payload) {

  try {

    const result = yield call(apis.getAvenant, payload.executionid);

    if (result.status != 200) {
      yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(actions.getAvenantExecutionSuccess(result.data));
    }

  } catch (error) {
    console.log(error)
  }
}

export function* saveResilieWorker(payload) {

  try {

    let result = yield call(apis.saveResilie, payload.resilie);

    if (result.status != 201) {
      yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(openNotificationModal('Enregistré avec success !', 'check-circle', Colors.primary, true));
    }

  } catch (error) {
    console.log(error)
  }
}

export function* getResilieWorker(payload) {

  try {

    const result = yield call(apis.getResilie, payload.executionid);

    if (result.status != 200) {
      yield put(openNotificationModal('Une erreur est survenu !', 'exclamation-triangle', Colors.firebrick, false));
    } else {
      yield put(actions.getResilieSuccess(result.data));
    }

  } catch (error) {
    console.log(error)
  }
}
