import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
// import { utilisateurReducer } from '../screens/utilisateurs';
import { passationReducer } from '../screens/passation';
import { executionReducer } from '../screens/execution';
import { auditReducer } from '../screens/audit';
import { componentReducer } from '../components';


const rootReducer = combineReducers({
    // utilisateurState: utilisateurReducer,
    passationState: passationReducer,
    componentState: componentReducer,
    executionState: executionReducer,
    auditState: auditReducer,
});

const store = () => {

    const sagaMiddleWare = createSagaMiddleware();

    return {
        ...createStore(rootReducer, applyMiddleware(sagaMiddleWare)),
        runSaga: sagaMiddleWare.run(rootSaga)
    }

}

export default store;