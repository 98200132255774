import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import ContratsResilies from './ContratsResilies';
import EntreprisesSuspendus from './EntreprisesSuspendus';
import {Colors} from '../../../utils';

export default class Sanction extends Component {

    render() {

        const { path } = this.props.match;

        return (

            <section id="main-content">
                <section class="wrapper site-min-height">
                    <h3><i class="fa fa-exclamation-triangle"></i> Sanction / Contrats Resilies</h3>
                    <div class="row mt">
                        <div class="col-lg-12">

                            <div class="content-panel" style={{backgroundColor:'#f2f2f2'}}>
                                <div class="panel-body">
                                <h4>Cette page est indisponible pour l'instant</h4>
                                    {/* <Router>
                                                                                    
                                            <div>
                                                <ul class="nav nav-tabs" role="tablist" style={{backgroundColor:Colors.primary}}>
                                                    <li role="presentation" class="active"><NavLink to={`${path}`}
                                                        activeStyle={{ background: Colors.white, color: Colors.primary, borderBottom: '2px solid #4ecdc4' }}>Contrats Resilies</NavLink></li>
                                                    <li role="presentation"><NavLink to={`${path}/entreprisesSuspendus`} style={{color:Colors.white}}
                                                        activeStyle={{ background: Colors.white, color: Colors.primary, borderBottom: '2px solid #4ecdc4' }}>Entreprises Suspendues</NavLink></li>
                                                </ul>
                                                <div class="tab-content">
                                                    <Switch>
                                                        <Route path={`${path}`} exact component={ContratsResilies} />
                                                        <Route path={`${path}/entreprisesSuspendus`} component={EntreprisesSuspendus} />
                                                    </Switch>
                                                </div>
                                            </div>
                                        
                                    </Router> */}
                                </div>
                            </div>


                        </div>
                    </div>
                </section>

            </section>

        );
    }
}